import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import "./dashboard-view.scss";
import { Context } from "../../../Context";
import { getDocumentsUserThunk } from "../../../dashboard/redux-state/thunks/get-document-user";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { updateDocumentList } from "../../../dashboard/redux-state/reducers/dashboard-reducer";
import { DashboardDocumentsUserRequestModel } from "../../../dashboard/redux-state/models/dashboard-documents-user-request-model";
import { CategoryService } from "../../../commons/services/category-service";
import { DocumentStatusService } from "../../../commons/services/document-status-service";
import { SubCategoryService } from "../../../commons/services/subcategory-service";
import { DocumentTypeService } from "../../../commons/services/document-type-service";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Modal, Pagination, TextField } from "@mui/material";
import { DashboardService } from "../../../dashboard/services/dashboard-service";
import fileDownload from "js-file-download";
import axios from "axios";
import { GLOBALS } from "../../../utils/Globals";
import { Recipient, SignDocumentRequestModel } from '../../../commons/redux-state/models/sign-document-request-model';
import { Navigate, useNavigate } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import { getDocumentPreviewThunk } from "../../../commons/redux-state/thunks/get-document-template-preview";
import DashboardAdviceModalComponent from "../../../dashboard/redux-state/components/dashboard-advice-modal-component/dashboard-advice-modal-component";

export default function DashboardView() {
  /* Variables de colores */
  const blue = "#004571";

  const { dashboardDocumentsUserRequestModel } = useAppSelector((state) => state.dashboardReducer);
  const { status } = useAppSelector((state) => state.dashboardReducer.dashboardDocumentsUserModelResponseData);
  const { dashboardDocumentsUserModelResponseData } = useAppSelector((state) => state.dashboardReducer);
  const { userModelResponseData } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();
  const { isAuth: token, userLogged } = useContext(Context);
  const [categories, SetCategories] = useState<any>(<></>);
  const [subcategories, setSubCategories] = useState<any>(<></>);
  const [documentStatus, setDocumentStatus] = useState<any>(<></>);
  const [documentType, setDocumentType] = useState<any>(<></>);
  const [categorySelect, setCategorySelect] = useState("");
  const [categorySelectValue, setCategorySelectValue] = useState("");
  const [subcategorySelect, setSubCategorySelect] = useState("");
  const [subcategorySelectValue, setSubCategorySelectValue] = useState("");
  const [statusSelect, setStatusSelect] = useState("");
  const [statusSelectValue, setStatusSelectValue] = useState("");
  const [documentTypeSelect, setDocumentTypeSelect] = useState("");
  const [documentTypeSelectValue, setDocumentTypeSelectValue] = useState("");
  const [messageAdviceModal, setMessageAdviceModal] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSignatureOpen, setModalSignatureOpen] = useState(false);
  const [documentIdSelected, setDocumentIdSelected] = useState(0);
  const [numberSignatures, setNumberSignatures] = useState(0);
  const [statusSignLoad, setStatusSignLoad] = useState("Ir a firmar");
  const [page, setPage] = useState(1);
  const pageRef = useRef(page);
  const [redirectTo, setRedirectTo]: any = useState(null);
  const [downloadMessage, setDownloadMessage]: any = useState("null");
  const navigate = useNavigate();
  const [showDashboardAdviceModal, setShowDashboardAdviceModal] = useState<boolean>(false);

  //Clouser para actualizar valor de la pagina
  const updatePage = (newValue: number) => {
    pageRef.current = newValue;
    setPage(newValue);
  };

  //Accion para informar al usuario el estado de su plan
  const userPlanInformation = () => {
    sessionStorage.setItem('planAdviceModal', 'on');
    const planDate = userModelResponseData.userResponseModel?.data.nextBillingDate!;
    const planActive = userModelResponseData.userResponseModel?.data.planActive;
    const currentDate:any = new Date();
    const nextBillingDate:any = new Date(planDate);
    const diff:any = nextBillingDate - currentDate ;
    const diffInDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    if(Math.abs(diffInDays) <= 2){
      setMessageAdviceModal("Tu plan está próximo a vencer, recuerda renovarlo")
      setShowDashboardAdviceModal(true)
    }

    if(!planActive){
      setMessageAdviceModal("Tu plan se encuentra vencido, recuerda renovarlo")
      setShowDashboardAdviceModal(true)
    }
  }

  //Acciones del paginador
  const handleClickPage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    updatePage(value);
    
    if (statusSelect !== "") {
      submit(null, "Equals", statusSelectValue, "documentStatusName", pageRef.current);
    } else if (categorySelect !== "") {
      submit(null, "Equals", categorySelectValue, "categoryName", pageRef.current);
    } else if (subcategorySelect !== "") {
      submit(null, "Equals", subcategorySelectValue, "subCategoryName", pageRef.current);
    } else if (documentTypeSelect !== "") {
      submit(null, "Equals", documentTypeSelectValue, "documentTemplateName", pageRef.current);
    } else {
      submit(null, "Contains", "", "", pageRef.current);
    }
  };

  //setea el valor del select estado y envia parametros al filtro de busqueda
  const handleSelectStatus = (event: any) => {

    const filter = event.target[event.target.selectedIndex].label === 'Estado' ? "" : event.target[event.target.selectedIndex].label
    const operator = filter === 'Estado' ? "Equals" : "Contains" 
    setStatusSelect(event.target.value as string);
    setCategorySelect("");
    setSubCategorySelect("");
    setDocumentTypeSelect("");
    setPage(1);
    setStatusSelectValue(event.target[event.target.selectedIndex].label);
    submit(null, operator, filter, "documentStatusName");
  };

  //setea el valor del select subcategorias y envia parametros al filtro de busqueda
  const handleSelectSubCategories = (event: any) => {
    const filter = event.target[event.target.selectedIndex].label === 'SubCategoria' ? "" : event.target[event.target.selectedIndex].label
    const operator = filter === 'SubCategoria' ? "Equals" : "Contains"
    setSubCategorySelect(event.target.value as string);
    setCategorySelect("");
    setDocumentTypeSelect("");
    setStatusSelect("");
    setPage(1);
    setSubCategorySelectValue(event.target[event.target.selectedIndex].label);
    submit(null, operator, filter, "subCategoryName");
  };

  //setea el valor del select tipo de documento y envia parametros al filtro de busqueda
  const handleSelectDocumentType = (event: any) => {
    const filter = event.target[event.target.selectedIndex].label === 'Tipo' ? "" : event.target[event.target.selectedIndex].label
    const operator = filter === 'Tipo' ? "Equals" : "Contains"
    setDocumentTypeSelect(event.target.value as string);
    setCategorySelect("");
    setSubCategorySelect("");
    setStatusSelect("");
    setPage(1);
    setDocumentTypeSelectValue(event.target[event.target.selectedIndex].label);
    submit(null, operator, filter, "documentTemplateName");
  };

  //setea el valor del select categorias y envia parametros al filtro de busqueda
  const handleSelectCategories = async (event: any) => {
    const filter = event.target[event.target.selectedIndex].label === 'Categoria' ? "" : event.target[event.target.selectedIndex].label
    const operator = filter === 'Categoria' ? "Equals" : "Contains"
    setCategorySelect(event.target.value as string);
    setSubCategorySelect("");
    setDocumentTypeSelect("");
    setStatusSelect("");
    setPage(1);
    setCategorySelectValue(event.target[event.target.selectedIndex].label);
    submit(null, operator, filter, "categoryName");
    try {
      const subCategoriesData =
        await SubCategoryService.getSubCategoryByCategoryId(
          event.target[event.target.selectedIndex].id,
          token
        );
      setSubCategories(
        Object(subCategoriesData?.data).map((u: any) => {
          return (
            <option key={uuidv4()} id={u.subCategoryId} value={u.name}>
              {u.name}
            </option>
          );
        })
      );
    } catch (error) { }
  };

  //Metodo que descarga un documento
  const downloadDocument = async (
    documentId: number,
    documentName: string,
    hasSignature: boolean,
    signatureStatusName: string
  ) => {
    try {
      if(hasSignature){
        const documentSignObject = await DashboardService.getDocumentSign(documentId, token)
        if (documentSignObject?.header.reponseCode === 406) {
          setDownloadMessage(documentSignObject?.header.message)
          handleOpen()
        } else {
          const url = documentSignObject!.data.documentSign;
          axios.get(url, { responseType: "blob", })
            .then((res) => {
              fileDownload(res.data, `${documentName}.pdf`);
            });
        }
      }else{
        const documentSign = await DashboardService.getGenerateAndSavePDF(documentId, token)
        if (documentSign?.header.reponseCode === 406) {
          setDownloadMessage(documentSign?.header.message)
          handleOpen()
        } else {
          const url = documentSign!.data;
          axios.get(url, { responseType: "blob", })
            .then((res) => {
              fileDownload(res.data, `${documentName}.pdf`);
            });
        }
      }


    } catch (error) { }
  };


  //Redirecciona a la previsualizacion del documento para ser editado
  const editDocument = (documentId: number) => {
    dispatch(getDocumentPreviewThunk({ id: documentId, token: token }))
    setRedirectTo({
      pathname: `${GLOBALS.menu.template.link}/preview`,
      search: `?documentId=${documentId}`,
      state: { succes: true },
    })
  }

  //Obtiene y setea la cantidad de firmantes y los convierte en array de inputs 
  const getRecipients = () => {
    const numberOfRecipients = numberSignatures
    const elementsArray: any = []
    for (let index = 0; index < numberOfRecipients; index++) {
      const elementSignature = (
      <div key={uuidv4()} className="row">
        <div key={uuidv4()} className="col-6">
          <TextField
            id={`name`}
            key={uuidv4()}
            className="text"
            label={`Name  #${index}`}
            variant="outlined"
            required={true}
            placeholder={"Name"}
          />
        </div>
        <div key={uuidv4()} className="col-6">
          <TextField
            id={`email${index}`}
            key={uuidv4()}
            className="text"
            label={`Email #${index}`}
            variant="outlined"
            required={true}
            placeholder="Email"
          />
        </div>
        <br/>
      </div>  )
      elementsArray.push(elementSignature)
    }
    return elementsArray
  }

  //accion del formulario de firmantes
  const handleSubmit = (props: any) => {
    props.preventDefault();
    urlSignDocument(props, documentIdSelected)
  }

  //Obtiene URL Y redirecciona a Docusign para firmar el documento
  const urlSignDocument = async (props: any, documentId: number) => {
    setStatusSignLoad("Cargando...")
    let arrayNames: string[] = []
    let arrayEmails: string[] = []
    Object.values(props.target).map((htmlNode: any) => {
      if (htmlNode.id !== undefined) {
        if (htmlNode.id !== "") {
          if (htmlNode.id === 'name') {
            arrayNames.push(htmlNode.value)
          } else {
            arrayEmails.push(htmlNode.value)
          }
        }
      }
    });

    const recipientsArray: Recipient[] = arrayNames.map((arrayNameElement: string, index) => {
      const recipientObject: Recipient = {
        name:arrayNames[index] ,
        email: arrayEmails[index],    
      }
      return recipientObject
    })

    const SignDocumentRequestObject: SignDocumentRequestModel = {
      documentId: documentId,
      recipients: recipientsArray,
    };

    const signDocumentResponse = await DashboardService.getUrlSignDocument(SignDocumentRequestObject, token);
    if(signDocumentResponse?.data !== null){
      const urlSignDocumentRedirect = signDocumentResponse?.data.returnUrl; 
      window.open(urlSignDocumentRedirect, '_blank', 'noopener,noreferrer');
    }else{
      setMessageAdviceModal(signDocumentResponse?.header.message)
      setModalSignatureOpen(false)
      setShowDashboardAdviceModal(true)
    }
    setStatusSignLoad("Ir a firmar")
  };

  useEffect(() => {
    
    dispatch(getDocumentsUserThunk(token));
    
    submit(null);
    fetchData();
  }, []);

  useEffect(()=>{
    const planAdviceModal = sessionStorage.getItem('planAdviceModal');
    if(planAdviceModal !== 'off'){
      if(userModelResponseData.userResponseModel) userPlanInformation();
    }
    
  },[userModelResponseData.userResponseModel])

   useEffect(() => {
    dispatch(getDocumentsUserThunk(token));
  }, [dashboardDocumentsUserRequestModel]);
 
  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  /**
   * Metodo que se ejecuta luego del primer render
   * Obtiene y mapea todas las categorias y las subcategorias desde el API, asi como el estado y el tipo de documento
   * Asigna los resultados a los Hooks 'SetCategories' y 'SetSubCategories' respectivamente
   */
  const fetchData = async () => {

    try {
      const [categoryList, documentStatusList, documentType] =
        await Promise.all([
          CategoryService.getCategories(token),
          DocumentStatusService.getAllDocumentStatus(token),
          DocumentTypeService.getAllDocumentTypeStatus(token),
        ]);

      SetCategories(
        Object(categoryList?.data).map((u: any) => {
          return (
            <option key={uuidv4()} id={u.categoryId} value={u.name}>
              {u.name}
            </option>
          );
        })
      );

      setDocumentStatus(
        Object(documentStatusList?.data).map((u: any) => {
          return (
            <option key={uuidv4()} value={u.documentStatusId}>
              {u.documentStatusName}
            </option>
          );
        })
      );

      setDocumentType(
        Object(documentType?.data).map((u: any) => {
          return (
            <option key={uuidv4()} value={u.documentTemplateId}>
              {u.name}
            </option>
          );
        })
      );
    } catch (error: any) { }
  };

  //envia objeto al thunk para actualizar el estado con los nuevos filtros
  const submit = (
    e: any,
    operator = "Contains",
    value = "",
    propertyName = "documentName",
    pageNumber = 1
  ) => {
    const valueFilter = e ? e.target.value : value;
    if (e !== null) {
      setCategorySelect("");
      setSubCategorySelect("");
      setDocumentTypeSelect("");
      setStatusSelect("");
    }

    const filterRequest: DashboardDocumentsUserRequestModel = {
      pageNumber: pageNumber,
      pageSize: 10,
      sortProperty: "CreationDate",
      sortType: "des",
      filters: [
        {
          operator: operator,
          propertyName: propertyName,
          value: valueFilter,
        },
      ],
      userId: Number(userLogged.profile.sub),
    };
    dispatch(updateDocumentList(filterRequest));
  };

  //Retorna un elemento 'button' dependiento del estado
  const button = (
    documentStatusName: string,
    documentId: number,
    documentName: string,
    hasSiganture: boolean,
    signatureStatusName: string,
    numberSignatures: number
  ) => {
    if (documentStatusName === "Finalizado") {
      if (hasSiganture && signatureStatusName === 'No firmado') {
        return <button onClick={() => handleClickOpenModalSiganture(documentId, numberSignatures)} className="btnType download">Firma pendiente</button>
      }
      else {
        return <button onClick={() => downloadDocument(documentId, documentName, hasSiganture, signatureStatusName)} className="btnType download">Descargar</button>
      }
    } else
      if (documentStatusName === "En progreso") {
        return <button onClick={() => editDocument(documentId)} className="btnType edit">Editar</button>;
      } else
        if (documentStatusName === "Aprobado") {
          if (userLogged.profile.role === GLOBALS.roles.naturalPerson.roleName && userModelResponseData.userResponseModel?.data.planDays !== null) {
            return <button className="btnType pay">Pagar</button>;
          } else {
            return <button onClick={() => downloadDocument(documentId, documentName, hasSiganture, signatureStatusName)} className="btnType download">Descargar</button>
              ;
          }
        }

  };

  //Abre ventana modal campos
  const handleOpen = () => {
    setModalOpen(true);
  };

  //cierra ventana modal campos
  const handleClose = () => {
    setModalOpen(false);
    setModalSignatureOpen(false);
  };

  //Setea en el hoook documentIdSelected el id del documento seleccionado y abre la modal para los firmantes
  const handleClickOpenModalSiganture = (documentId: number, numberSignatures: number) => {
    if(userModelResponseData.userResponseModel?.data.planActive){
      setDocumentIdSelected(documentId)
      setNumberSignatures(numberSignatures)
      setModalSignatureOpen(true)}
      else{
        setDownloadMessage("El usuario no tiene un plan.")
        handleOpen()
      }
  };

  //Retorna iconos de estado segun estado del documento
  const iconsForState = (documentStatusName: string) => {
    if (documentStatusName === "En progreso") {
      return <div className="circle inProgress"></div>;
    } else if (documentStatusName === "Aprobado") {
      return (
        <>
          <div className="circle approved"></div>
          <div className="circle approved"></div>
        </>
      );
    } else if (documentStatusName === "Finalizado") {
      return (
        <>
          <div className="circle finished"></div>
          <div className="circle finished"></div>
          <div className="circle finished"></div>
        </>
      );
    } else {
      return <></>;
    }
  };

  //funcion que recorre el objeto lista de documentos y retorna un objeto JSX para renderizar
  const tableRegisters = () => {
    const data = dashboardDocumentsUserModelResponseData.dashboardDocumentsUserResponseModel?.data.list;
    let rowsTable: JSX.Element[] = [];
    if (data !== undefined) {
      rowsTable = data.map((row) =>
        <TableRow
          key={uuidv4()}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell align="center"># {row.documentId}</TableCell>
          <TableCell align="center">{row.documentName}</TableCell>
          <TableCell align="center">{row.documentTemplateName}</TableCell>
          <TableCell align="center">
            <div className="state d-md-flex">
              <span>{row.documentStatusName}</span>
              {iconsForState(row.documentStatusName)}
            </div>
          </TableCell>
          <TableCell align="center">
            {button(
              row.documentStatusName,
              row.documentId,
              row.documentName,
              row.hasSignature,
              row.signatureStatusName,
              row.numberSignatures,
            )}
          </TableCell>
        </TableRow>
      );
    }
    return rowsTable.length > 0 ? (rowsTable) : (
      <tr>
        <td colSpan={4}>
          <div>No se encontraron resultados para la busqueda</div>
        </td>
      </tr>
    );
  };

  //Paginador

  return (
    <div className="dashboard mt-50">
      <div className="container">
        <div className="row justify-content-end mb-50">
          <div className="col-12 col-md d-md-flex">
            <Button onClick={() => navigate("/")} className="boton myDocument">
              <p>Mis documentos</p>
              <div className="circle">
                <span className="icon-icon-library-2"></span>
              </div>
            </Button>
            <Button onClick={() => navigate("/new-document")} className="boton nwDocument">
              <p>Nuevo documento</p>
              <div className="circle">
                <span className="icon-new-document"></span>
              </div>
            </Button>
          </div>
        </div>
        <h3 className="title blue">Documentos recientes</h3>
        <div className="filters">
          <div className="buscador">
            <input placeholder="Buscar" onChange={(e: any) => { submit(e); }} className="icon-icon-search" type="text"></input>
          </div>
          <div className="campos">
            <div className="field">
              <select onChange={handleSelectStatus} value={statusSelect}>
                <option value="">Estado</option>
                {documentStatus}
              </select>
              <span className="icon-arrow-down"></span>
            </div>
            <div className="field">
              <select onChange={handleSelectCategories} value={categorySelect}>
                <option value="">Categoria</option>
                {categories}
              </select>
              <span className="icon-arrow-down"></span>
            </div>
            <div className="field">
              <select onChange={handleSelectSubCategories} value={subcategorySelect}>
                <option value="">SubCategoria</option>
                {subcategories}
              </select>
              <span className="icon-arrow-down"></span>
            </div>
            <div className="field">
              <select value={documentTypeSelect} onChange={handleSelectDocumentType}>
                <option value="">Tipo</option>
                {documentType}
              </select>
              <span className="icon-arrow-down"></span>
            </div>
          </div>
        </div>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper} className="mt-30">
            <Table
              sx={{ minWidth: 650 }}
              className=" table"
              aria-label="simple"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">N° Documento</TableCell>
                  <TableCell align="center">Titulo</TableCell>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {status === AsyncStatusEnum.loading && (
                  <tr>
                    <td colSpan={4}>
                      <div className="spinner"></div>
                    </td>
                  </tr>
                )}
                {status === AsyncStatusEnum.completed && tableRegisters()}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Pagination
            onChange={handleClickPage}
            page={page}
            count={dashboardDocumentsUserModelResponseData.dashboardDocumentsUserResponseModel?.data.totalPages}
            color="primary"
            size="large"
          />
          <div>
            {`Total resultados ${dashboardDocumentsUserModelResponseData.dashboardDocumentsUserResponseModel?.data.totalItems}`}
          </div>
        </Paper>

        {showDashboardAdviceModal && <DashboardAdviceModalComponent message={messageAdviceModal} setShowDashboardAdviceModal={setShowDashboardAdviceModal}/>}

        <Dialog
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth={"sm"}
          fullWidth={true}>
          <DialogTitle id="alert-dialog-title" sx={{ background: blue, color: "white" }}>
            Atención!
          </DialogTitle>
          <DialogContent>
            <div className="m-t-20">
              <span>
                {downloadMessage}
              </span>
              <DialogActions>
                <div className="delete-paragraph-modal__button-bar">
                  <button className="delete-paragraph-modal__back-button" onClick={handleClose}>Aceptar</button>
               </div>
                
              </DialogActions>
          </div>
        </DialogContent>
      </Dialog>


      <Dialog
        open={modalSignatureOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth={"sm"}
        fullWidth={true}>
        <DialogTitle id="alert-dialog-title" sx={{ background: blue, color: "white" }}>
          Firmantes
        </DialogTitle>
        <DialogContent>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <h2 ></h2>
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {getRecipients()}
                </div>
              </div>
              <DialogActions>
                <div className="delete-paragraph-modal__button-bar">
                  <button className="delete-paragraph-modal__back-button" onClick={handleClose}>Cancelar</button>
                  <button className="delete-paragraph-modal__forward-button" type="submit">{statusSignLoad}</button>
                </div>
              </DialogActions>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
    </div >
  );
}
