import React, { useReducer } from "react";
import FormElementComponent from "./FormElementComponent";
import formRenderReducer from "../../reducers/formRender";
import { actionsConstants } from "../../constants/actions-constants";

function ListElements(props) {
  const { valueMap, setValue } = props;
  return (
    <FormElementComponent
      recoveryValue={props.recoveryValue}
      element={props.element}
      setValue={setValue}
      valueMap={valueMap}
    />
  );
}

export default function FormManagement(props) {
  const [state, dispatch] = useReducer(formRenderReducer, props.valueInit);

  const inputsValuesArray = localStorage.getItem('inputArrayValues');
  const stringToObj = JSON.parse(inputsValuesArray);
  const foundObject = stringToObj.find(object => object.id === `${props.element.clauseId}-${props.element.position}`);
  const setValue = (id, value) =>
    dispatch({ type: actionsConstants.ON_CHANGE_RENDER, id, value });

  return (
    <ListElements
      recoveryValue= {foundObject ? foundObject.value : undefined}
      element={props.element}
      valueMap={state.valueMap}
      setValue={setValue}
    />
  );
}
