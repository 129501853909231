import React, { FC } from "react";
import { Dialog, DialogTitle } from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import { Paragraph, SecondLevelSectionModel } from "../../redux-state/models/first-level-section-model";
import "./delete-second-level-paragraph-modal-component.scss";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { deleteSecondLevelParagraph } from "../../redux-state/reducers/document-build-reducer";

interface DeleteSecondLevelParagraphProps {
    paragraph: Paragraph | SecondLevelSectionModel;
    setShowDeleteParagraph: any;
}

const DeleteSecondLevelParagraphModalComponent:FC<DeleteSecondLevelParagraphProps> = ({paragraph, setShowDeleteParagraph}) => {

    /* Variables de colores */
    const blue = "#004571";
    const purple = "#82389A";

    const dispatch = useAppDispatch();

    const handleCancelButtonClick = () => {
        setShowDeleteParagraph(false);
    }

    const handleDeleteButtonClick = () => {
        dispatch(deleteSecondLevelParagraph({
            firstLevelParentPositionId: paragraph.firstLevelParentPositionId,
            secondLevelParentPositionId: Number(paragraph.secondLevelParentPositionId),
            selfPositionId: paragraph.selfPositionId
        }))
    }

    return (
     <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
         <DialogTitle sx={{background: blue, color: "white"}}>Atención!</DialogTitle>

         <div className="delete-second-level-paragraph-modal">

            <div className="delete-second-level-paragraph-modal__message">
                <WarningAmberIcon sx={{fontSize: "70px", color: "#f0ad4e"}}/>
                <p>Está seguro que desea eliminar este párrafo y todo su contenido?</p>
            </div>

            <div className="delete-second-level-paragraph-modal__button-bar">
                <button className="delete-second-level-paragraph-modal__back-button" onClick={handleCancelButtonClick}>Cancelar</button>
                <button className="delete-second-level-paragraph-modal__forward-button" onClick={handleDeleteButtonClick}>Eliminar</button>
            </div>

         </div>
     </Dialog>
    )
}

export default DeleteSecondLevelParagraphModalComponent;