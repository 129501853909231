import { createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentTemplateService } from "../../../commons/services/document-template-service";
import { updateDocumentTemplateSections } from "../reducers/natural-person-reducer";

interface payload {
  id: number,
  token: string,
}

//thunk para obtener el document template inicial
export const getDocumentTemplateThunk = createAsyncThunk(
  "natural-person/get-document-template-thunk",
  async (payload:payload, { rejectWithValue, dispatch }) => {
    const documentTemplate = await DocumentTemplateService.getDocumentTemplateInitial(payload.id, payload.token);
    if (documentTemplate){
      const sectionsResults = DocumentTemplateService.getSectionsArrays(documentTemplate);
       dispatch(updateDocumentTemplateSections(sectionsResults))
      return documentTemplate;
    }else return rejectWithValue(null);
  }
);
