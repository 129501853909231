import { Dialog, DialogTitle, TextField } from "@mui/material";
import React, { FC, useState } from "react";
import "./active-advice-modal-component.scss"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from "../../../redux/hooks";
import { activeUserDashboardAdminThunk } from "../../redux-state/thunks/active-user-dashboard-admin-thunk";
import { DashboardAdminUserStatusRequestModel } from "../../redux-state/models/dashboard-admin-user-status-request-model";

interface ActiveAdviceProps {
    setShowActiveUserModal:any;
    userId: number;
    token:string;
    setShowSuccesAceptModal:any;
}

//Implementa modal para activar usuario
const ActiveAdviceModalComponent: FC<ActiveAdviceProps> = ({setShowActiveUserModal, userId, token, setShowSuccesAceptModal}) => {
    
    const dispatch = useAppDispatch();
    
    const [value, setValue] = useState<Dayjs | null>(null);

    const handleCancelButtonClick = () => {
        setShowActiveUserModal(false)
    }

    const handleSaveButtonClick = () => {
        const nextBilling:DashboardAdminUserStatusRequestModel = {
            nextBillingDate:dayjs(value).toDate(),
            userId:userId,
        };
        
        dispatch(activeUserDashboardAdminThunk({nextBillingDate:nextBilling, token:token}))
        setShowActiveUserModal(false)
        setShowSuccesAceptModal(true)
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle sx={{ background: "#D3A237", color: "white" }}>Activar usuario</DialogTitle>

            <div className="active-advice-modal">
                <div className="active-advice-modal__message">
                    <p>Seleccionar fecha de caducidad</p>
                    <LocalizationProvider adapterLocale={"es"} className="calendar" dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label={"Fecha de caducidad"}
                            inputFormat="YYYY/MM/DD"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} style={{ width: 200, background: "white" }} />}
                        />
                    </LocalizationProvider>
                </div>

                <div className="active-advice-modal__button-bar">
                    <button onClick={handleCancelButtonClick} className="active-advice-modal__back-button" >Cancelar</button>
                    <button onClick={handleSaveButtonClick} className="active-advice-modal__forward-button">Guardar</button>
                </div>

            </div>
        </Dialog>
    )

}

export default ActiveAdviceModalComponent