import { DocumentTemplateResponseModel, Section } from "../redux-state/models/document-template-response-model";
import { CurrentDocumentPreviewModel, Clause } from "../redux-state/models/current-document-preview-request-model";
import { CurrentDocumentPreviewResponseModel } from "../redux-state/models/current-document-preview-response-model";
import { CurrentDocumentPreviewEditRequestModel } from "../../preview-document/models/current-document-preview-edit-request-model";
import { CurrentDocumentPreviewEditResponseModel } from "../../preview-document/models/current-document-preview-edit-response-model";
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";

//crea objeto con secciones, subsecciones y clausulas para ser guardado
export class CurrentDocumentPreviewService {
  public static generateDocumentPreview(form: any, documentSections: Section[]): null {
    let sections: any = [];
    const validNodes = Object.values(form).map((htmlNode: any) => {
      if (htmlNode.value === ""){htmlNode.value = "________"} ;
      if (htmlNode.value === undefined) return;

      const elementValues = htmlNode.id.split("-");
      const elementProps = {
        value: htmlNode.value,
        clauseId: elementValues[0],
        position: elementValues[1],
      };
      return elementProps;
    });

    const filteredValidNodes = validNodes.filter((htmlNode) => htmlNode !== undefined);
    //Recorre el documento inicial, para asociar los campos con las clausulas, por medio de la posicion
    Object.values(documentSections).map((u: any, index) => {
      let arraySubsection: any = [];
      let arrayClause: any = [];
      if (
        Object.entries(u.section).length > 0 &&
        Object.entries(u.clause).length === 0
      ) {
        arraySubsection = Object.values(u.section).map((u: any) => {
          let arrayClause = Object.values(u.clause).map((u: any) => {
            let fieldList = Object.values(u.clauseXField).map((u: any) => {
  
              let fields: any = {
                fieldId: u.fieldId,
                FieldValue: CurrentDocumentPreviewService.searchValue(
                  u.clauseId,
                  u.position,
                  filteredValidNodes
                ),
                typeFieldId: u.field.typeField.typeFieldId,
                label: u.label,
                dropdownOptions: u.field.valueField !== null ? u.field.valueField.map((fieldElement:any) => {return fieldElement.valueFieldText}) : [],
            
              };
              return fields;
            });

            let clause: any = {
              clauseText: u.clauseText,
              fieldList: fieldList,
            };
            return clause;
          });

          let subsectionsList: any = {
            clause: arrayClause,
            sectionTitle: u.sectionTitle,
          };
          return subsectionsList;
        });
      } else if (
        Object.entries(u.section).length > 0 &&
        Object.entries(u.clause).length > 0
      ) {
        arraySubsection = Object.values(u.section).map((u: any) => {
          let arrayClause = Object.values(u.clause).map((u: any) => {
            let fieldList = Object.values(u.clauseXField).map((u: any) => {

              let fields: any = {
                fieldId: u.fieldId,
                FieldValue: CurrentDocumentPreviewService.searchValue(
                  u.clauseId,
                  u.position,
                  filteredValidNodes
                ),
                label: u.label,
                dropdownOptions: u.field.valueField !== null ? u.field.valueField.map((fieldElement:any) => {return fieldElement.valueFieldText}) : [],
                typeFieldId: u.field.typeField.typeFieldId,
              };
              return fields;
            });

            let clause: any = {
              clauseText: u.clauseText,
              fieldList: fieldList,
            };
            return clause;
          });

          let subsectionsList: any = {
            clause: arrayClause,
            sectionTitle: u.sectionTitle,
          };
          return subsectionsList;
        });

        arrayClause = Object.values(u.clause).map((u: any) => {
          let fieldList = Object.values(u.clauseXField).map((u: any) => {

            let fields: any = {
              fieldId: u.fieldId,
              FieldValue: CurrentDocumentPreviewService.searchValue(
                u.clauseId,
                u.position,
                filteredValidNodes
              ),
              label: u.label,
              typeFieldId: u.field.typeField.typeFieldId,
              dropdownOptions: u.field.valueField !== null ? u.field.valueField.map((fieldElement:any) => {return fieldElement.valueFieldText}) : [],
            
            };
            return fields;
          });

          let clause: any = {
            clauseText: u.clauseText,
            fieldList: fieldList,
          };
          return clause;
        });
      } else {
        arrayClause = Object.values(u.clause).map((u: any) => {
          let fieldList = Object.values(u.clauseXField).map((u: any) => {
            let fields: any = {
              fieldId: u.fieldId,
              FieldValue: CurrentDocumentPreviewService.searchValue(
                u.clauseId,
                u.position,
                filteredValidNodes
              ),
              label: u.label,
              typeFieldId: u.field.typeField.typeFieldId,
              dropdownOptions: u.field.valueField !== null ? u.field.valueField.map((fieldElement:any) => {return fieldElement.valueFieldText}) : [],
            };
            return fields;
          });

          let clause: any = {
            clauseText: u.clauseText,
            fieldList: fieldList,
          };
          return clause;
        });
      }

      let arraySections: any = {
        sectionTitle: u.sectionTitle,
        clause: arrayClause,
        section: arraySubsection,
      };

      sections.push(arraySections);
    });

    return sections;
  }

  /**
* Metodo para crear un nuevo documento
* @param id {Id del document a consultar}
* @param token {token de acceso del usuario}
* @returns JSON que incluye el id del documento guardado.
*/
  public static async getPreviewDocumentTemplate(documentPreview: CurrentDocumentPreviewModel, token: string): Promise<CurrentDocumentPreviewResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}Document/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(documentPreview)
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const data: CurrentDocumentPreviewResponseModel = await response.json();
      return data;
    } catch (error) {
      return null
    }
  }

  //busca un elemento por el id de la clausula y la posicion, devuelve el valor del elemento
  private static searchValue(clauseId: number, position: number, nodos: any) {

    const values = Object.values(nodos).map((element: any) => {
      let ValueField: any = "";
      Number(element.clauseId) === clauseId &&
        Number(element.position) === position
        ? (ValueField = element.value)
        : (ValueField = "");
      return ValueField;
    });

    if (!values.some((x) => x !== "")) return;
    const filterArray = values.find((value) => value !== "");
    return filterArray;
  }

  /**
* Metodo para editar un documento desde la prwvisualizacion
* @param id {Id del document a consultar}
* @param token {token de acceso del usuario}
* @returns JSON que incluye el id del documento guardado.
*/
  public static async getPreviewEditDocumentTemplate(documentEdiPreview: CurrentDocumentPreviewEditRequestModel, token: string): Promise<CurrentDocumentPreviewEditResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}Document/edit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(documentEdiPreview)
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const data: CurrentDocumentPreviewEditResponseModel = await response.json();
      return data;
    } catch (error) {
      return null
    }
  }

  //Metodo para transformar un objeto de previsualizacion a un objeto para editar la previsualizacion      
  public static generateDocumentPreviewEdit(currentDocumentPreview: CurrentDocumentPreviewResponseModel) {
    const documentPreviewEditObjRequest: CurrentDocumentPreviewEditRequestModel = {
      documentId: currentDocumentPreview.data.documentId,
      userId: currentDocumentPreview.data.userId,
      documentStatusId: 3,
      documentName: currentDocumentPreview.data.documentName,
      documentTemplateId: currentDocumentPreview.data.documentTemplateId,
      section: currentDocumentPreview.data.section,
      documentStructureChange: currentDocumentPreview.data.documentStructureChange!,
    }
    return documentPreviewEditObjRequest
  }
}
