import { Dialog, DialogTitle } from "@mui/material";
import React, { FC } from "react";
import "./dashboard-advice-modal-component.scss"

interface DashboardAdviceModalProps {
    message:string;
    setShowDashboardAdviceModal:any
}

//Implementa modal para informar usuario plan
const DashboardAdviceModalComponent: FC<DashboardAdviceModalProps> = ({message, setShowDashboardAdviceModal}) => {

    const handleAceptButtonClick = () => {
        sessionStorage.setItem('planAdviceModal', 'off');
        setShowDashboardAdviceModal(false)
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle sx={{ background: "#D3A237", color: "white" }}>Información</DialogTitle>

            <div className="dashboard-advice-modal">
                <div className="dashboard-advice-modal__message">
                    <p>{message}</p>
                </div>

                <div className="dashboard-advice-modal__button-bar">
                    <button onClick={handleAceptButtonClick} className="dashboard-advice-modal__back-button" >Aceptar</button>
                </div>

            </div>
        </Dialog>
    )

}

export default DashboardAdviceModalComponent