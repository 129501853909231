import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParagraphBuildTypeEnum } from "../../enums/paragraph-build-type-enum";
import { SelectedFormEnum } from "../../enums/selected-form-enum";
import { ParagraphBuildInitialStateModel } from "../models/paragraph-build-initial-state-model";

export const naturalPersonInitialState: ParagraphBuildInitialStateModel = {
    previewChildren: [],
    selectedForm: SelectedFormEnum.idle,
    buildType: ParagraphBuildTypeEnum.creation
};

export const paragraphBuildReducer = createSlice({
  name: "paragraph-build-reducer",
  initialState: naturalPersonInitialState ,
  reducers: {
    feedPreviewChildren:(state, action: PayloadAction<any[]>) => {
      state.previewChildren = action.payload; 
    },
    updatePreviewChildren:(state, action: PayloadAction<any>) => {
      state.previewChildren = [...state.previewChildren, action.payload];
    },
    resetPreviewChildren:(state) => {
      state.previewChildren = [];
    },
    updateSelectedForm: (state, action: PayloadAction<SelectedFormEnum>) => {
      state.selectedForm = action.payload;
    },
    updateBuildType: (state, action: PayloadAction<ParagraphBuildTypeEnum>) => {
      state.buildType = action.payload;
    },
  }
});

export const {
  updatePreviewChildren,
  updateSelectedForm,
  resetPreviewChildren, 
  feedPreviewChildren, 
  updateBuildType 
} = paragraphBuildReducer.actions;
export default paragraphBuildReducer.reducer;
