export enum ElementTypeEnum {
  paragraph = "paragraph",
  lineBreak = "linebreak",
  boldText = "bold-text",
  textField = "text-field",
  textBox = "text-box",
  dateField = "date-field",
  selectField = "select-field",
  signature = "signature",
}
