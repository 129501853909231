import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import { UserService } from "../../services/user-service";

interface payload {
    id: number,
    token: string,
  }

export const getUserInfoThunk = createAsyncThunk(
  "user/get-user-info-thunk",
  async (payload:payload, { rejectWithValue, dispatch, getState }) => {
    const documentsUserResponse = await UserService.getUserInfo(payload.id, payload.token);
    if (documentsUserResponse){
      return documentsUserResponse;
    }else return rejectWithValue(null); 
  }
);
