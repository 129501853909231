import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { DocumentTemplateResponseModel, Section } from "../../../commons/redux-state/models/document-template-response-model";
import { NaturalPersonInitialStateModel } from "../models/natural-person-initial-state-model";
import { getDocumentTemplateThunk } from "../thunks/get-document-template";

export const naturalPersonInitialState: NaturalPersonInitialStateModel = {
  documentTemplateData: {
    status: AsyncStatusEnum.idle,
    documentTemplate: null,
    error: {
      error: false,
      message: "",
    },
  },
  documentSections:[]
};

 //extraReducer solo para asincrono y/o sincronos - reducers solo sincronos 
export const naturalPersonReducer = createSlice({
  name: "natural-person-reducer",
  initialState: naturalPersonInitialState ,
  reducers: {
    updateDocumentTemplateSections:(state, action: PayloadAction<Section[]>) =>{
      state.documentSections = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getDocumentTemplateThunk.fulfilled, (state, action:PayloadAction<DocumentTemplateResponseModel>) => {
      state.documentTemplateData.status = AsyncStatusEnum.completed;
      state.documentTemplateData.documentTemplate = action.payload;
      state.documentTemplateData.error = {
        error: false,
        message: '',
      };
    })
    .addCase(getDocumentTemplateThunk.pending, (state) => {
      state.documentTemplateData.status = AsyncStatusEnum.loading;
    })
    .addCase(getDocumentTemplateThunk.rejected, (state, action) => {
      state.documentTemplateData.documentTemplate = naturalPersonInitialState.documentTemplateData.documentTemplate;
      state.documentTemplateData.status = AsyncStatusEnum.rejected;
      state.documentTemplateData.error = {
        error: true,
        message: 'Error rejected',
      };
    })
  },
});

export const { updateDocumentTemplateSections } = naturalPersonReducer.actions;
export default naturalPersonReducer.reducer;
