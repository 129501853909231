import { Dialog, DialogTitle } from "@mui/material";
import React, { FC } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { disabledUserDashboardAdminThunk } from "../../redux-state/thunks/disabled-user-dashboard-admin-thunk";
import "./desactive-warnig-modal-component.scss"

interface DesactiveWargningProps {
    setShowDesactiveModal:any;
    setShowSuccesDeclineModal:any;
    userId: number;
    userName:string;
    token:string;
}

//Implementa modal para Desactivar usuario
const DesactiveWarningModalComponent: FC<DesactiveWargningProps> = ({setShowDesactiveModal, setShowSuccesDeclineModal, userId, userName, token}) => {
    
    const dispatch = useAppDispatch();
    
    const handleCancelButtonClick = () => {
        setShowDesactiveModal(false)
    }

    const handleSaveButtonClick = async () => {
        dispatch(disabledUserDashboardAdminThunk({id:userId,token:token}));
        setShowDesactiveModal(false);
        setShowSuccesDeclineModal(true)
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle sx={{ background: "#D3A237", color: "white" }}>Desactivar usuario</DialogTitle>

            <div className="desactive-wargning-modal">
                <div className="desactive-wargning-modal__message">
                    <p>Estás seguro de que quieres desactivar el plan?</p>
                </div>

                <div className="desactive-wargning-modal__button-bar">
                    <button onClick={handleCancelButtonClick} className="desactive-wargning-modal__back-button" >Cancelar</button>
                    <button onClick={handleSaveButtonClick} className="desactive-wargning-modal__forward-button">Aceptar</button>
                </div>

            </div>
        </Dialog>
    )

}

export default DesactiveWarningModalComponent