import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { UserInitialStateModel } from "../models/user-initial-state-model";
import { UserResponseModel } from "../models/user-response-model";
import { getUserInfoThunk } from "../thunk/get-user-info";

export const userInitialState: UserInitialStateModel = {
    userModelResponseData: {
        status: AsyncStatusEnum.idle,
        userResponseModel: null,
        error: {
            error: false,
            message: "",
          },
      }
 };

  //dashboard reducer, para mostrar documentos para el usuario
export const userReducer = createSlice({
    name: "user-reducer",
    initialState: userInitialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getUserInfoThunk.pending, (state) => {
        state.userModelResponseData.status = AsyncStatusEnum.loading;
      })
      .addCase(getUserInfoThunk.rejected, (state, action) => {
        state.userModelResponseData.status = AsyncStatusEnum.rejected;
        state.userModelResponseData.error = {
          error: true,
          message: 'Error rejected',
        };
        state.userModelResponseData.userResponseModel = userInitialState.userModelResponseData.userResponseModel
      })
       .addCase(getUserInfoThunk.fulfilled, (state, action:PayloadAction<UserResponseModel>) => {
        state.userModelResponseData.status = AsyncStatusEnum.completed;
        state.userModelResponseData.error = {
          error: false,
          message: '',
        };
        state.userModelResponseData.userResponseModel = action.payload;
      }) 
    },
  });

  export default userReducer.reducer;
  