import { ChildTypeEnum } from "../../lawyer/enums/child-type-enum";
import { FirstLevelSectionModel, Paragraph, SecondLevelSectionModel, Title } from '../../lawyer/redux-state/models/first-level-section-model';
import { ParagraphBuildService } from "../../lawyer/services/paragraph-build-service";
import { Content, DocumentTemplateAdapterToEditResponseModel, Child, ElementModel } from "../redux-state/models/document-template-adapter-to-edit-response-model";
import { ElementTypeEnum } from "../../lawyer/enums/element-type-enum";
import { DatePickerFormModel, DropdownFormModel, TextBoxFormModel, TextFieldFormModel } from "../../lawyer/redux-state/models/text-form-model";
import { ParentTypeEnum } from "../../lawyer/enums/parent-type-enum";
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";

export class DocumentTemplateAdapterService {

  /**
   * Metodo para obtener plantillas completas con secciones, clausulas y campos, transformados a la estrcutura base para la edicion de las mismas
   * @param documentTemplateId {Id de la plantillas}
   * @param token {token de acceso del usuario}
   * @returns Plantilla completa con todas sus secciones, clausulas y campos
   */

  static async getDocumentTemplateToEdit(documentTemplateId: number, token: string): Promise<DocumentTemplateAdapterToEditResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}DocumentTemplate/GetDocumentTemplateAdapter/${documentTemplateId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const documentTemplateToEdit: DocumentTemplateAdapterToEditResponseModel = await response.json();
      return documentTemplateToEdit;
    } catch (error) {
      return null
    }
  }

  //Transforma una plantilla para diligenciar en una plantilla para editar
  static getDocumentTemplateTransformedToEdit(documentTemplateToEdit: DocumentTemplateAdapterToEditResponseModel) {
    const firstLevelSectionsArray: Array<FirstLevelSectionModel> = [];
    documentTemplateToEdit.data.content.map((firstLevelSectionElement: Content, index) => {
      const title: Title = {
        titleText: firstLevelSectionElement.title.titleText,
        tooltipText: firstLevelSectionElement.title.tooltipText || "",
        tooltip: firstLevelSectionElement.title.tooltip || false
      }

      const firstLevelSectionElementData: FirstLevelSectionModel = {
        positionid: firstLevelSectionElement.positionId,
        title: title,
        children: this.childrens(firstLevelSectionElement.children)
      }

      return firstLevelSectionsArray.push(firstLevelSectionElementData)
    }
    )

    return firstLevelSectionsArray
  }

  //Genera arreglo con elementos JSX del content
  static generateElementsContentArray(elementsContentArray: ElementModel[]) {

    const paragraphArray: Array<any> = [];

    elementsContentArray.map((contentElement: ElementModel) => {
      let elementJSX: any
      const innerText = contentElement.innerText === null ? "" : contentElement.innerText
      
      switch (contentElement.elementType) {
        case ElementTypeEnum.boldText:
          elementJSX = ParagraphBuildService.getBoldText(innerText);
          break;
        case ElementTypeEnum.dateField:
          const dateFieldProperties: DatePickerFormModel = {
            label: contentElement.label! || "",
            tooltip: contentElement.tooltip! || false,
            tooltipText: contentElement.tooltipText! || "",
            required: contentElement.required! || false,
          }
          elementJSX = ParagraphBuildService.getDateInput(dateFieldProperties);
          break;
        case ElementTypeEnum.lineBreak:
          elementJSX = ParagraphBuildService.getVerticalHtmlSpace();
          break;
        case ElementTypeEnum.paragraph:
          elementJSX = ParagraphBuildService.getText(innerText);
          break;
        case ElementTypeEnum.selectField:
          const dropdownProperties: DropdownFormModel = {
            label: contentElement.label! || "",
            tooltip: contentElement.tooltip! || false,
            tooltipText: contentElement.tooltipText! || "",
            dropdownOptions: contentElement.dropdownOptions! || [],
            required: contentElement.required! || false,
          }
          elementJSX = ParagraphBuildService.getDropdownInput(dropdownProperties);
          break;
        case ElementTypeEnum.textBox:
          const textBoxProperties: TextBoxFormModel = {
            label: contentElement.label! || "",
            tooltip: contentElement.tooltip! || false,
            tooltipText: contentElement.tooltipText! || "",
            required: contentElement.required! || false,
            defaultValue: contentElement.defaultValue! || "",
          }
          elementJSX = ParagraphBuildService.getTextBoxInput(textBoxProperties);
          break;
        case ElementTypeEnum.textField:
          const textFieldProperties: TextFieldFormModel = {
            label: contentElement.label! || "",
            tooltip: contentElement.tooltip! || false,
            tooltipText: contentElement.tooltipText! || "",
            required: contentElement.required! || false,
            defaultValue: contentElement.defaultValue! || "",
          }
          elementJSX = ParagraphBuildService.getTextFieldInput(textFieldProperties);
          break;
        case ElementTypeEnum.signature:
          elementJSX = ParagraphBuildService.getSignature(contentElement.label!);
          break;
        default:
          elementJSX = ParagraphBuildService.getText(innerText);
          break;
      }

      return paragraphArray.push(elementJSX);
    }
    )
    return paragraphArray
  }

  //genera elementos subtitulos de un documento
  static generateSecondLevelParagraph(subtitleContent: Child["subtitleContent"]):Paragraph[]{
    const secondLevelParagraphArray: any[] = subtitleContent!.map((paragraphElement) => {
      const paragraph: Paragraph = {
        firstLevelParentPositionId: paragraphElement.firstLevelParentPositionId,
        secondLevelParentPositionId: paragraphElement.secondLevelParentPositionId!,
        selfPositionId: paragraphElement.selfPositionId,
        parentType: paragraphElement.parentType,
        childType: ChildTypeEnum.paragraph,
        content: this.generateElementsContentArray(paragraphElement.paragraphContent!)
      }
      return paragraph
    })
    return secondLevelParagraphArray
  }
  
  //genera elementos hijos de un documento (parrafos o subtitulos)
  static childrens(childrens: Child[]) {
    const childrenArray:Array<Paragraph | SecondLevelSectionModel> = childrens.map((childrenElement: Child) => {
      switch (childrenElement.childType) {
        case ChildTypeEnum.paragraph:
          const paragraph: Paragraph = {
            firstLevelParentPositionId: childrenElement.firstLevelParentPositionId,
            secondLevelParentPositionId: childrenElement.secondLevelParentPositionId!,
            selfPositionId: childrenElement.selfPositionId,
            parentType: childrenElement.parentType,
            childType: ChildTypeEnum.paragraph,
            content: this.generateElementsContentArray(childrenElement.paragraphContent!)
          }
          return paragraph
        case ChildTypeEnum.subtitle:

          const title: Title = {
            titleText: childrenElement.title?.titleText!,
            tooltipText: childrenElement.title?.tooltipText === null ? "" : childrenElement.title?.tooltipText!,
            tooltip: childrenElement.title?.tooltip || false
          }

          const secondLevel: SecondLevelSectionModel = {
            firstLevelParentPositionId: childrenElement.firstLevelParentPositionId,
            secondLevelParentPositionId: childrenElement.secondLevelParentPositionId!,
            selfPositionId: childrenElement.selfPositionId,
            parentType: ParentTypeEnum.title,
            childType: ChildTypeEnum.subtitle,
            title: title,
            content:this.generateSecondLevelParagraph(childrenElement.subtitleContent),
          }
         return secondLevel
      }
    });

    return childrenArray
  }

}




