import { Dialog, DialogTitle } from "@mui/material";

import React, { FC } from "react";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getuserListDashboardAdminThunk } from "../../redux-state/thunks/get-user-list-dashboard-admin-thunk";
import "./succes-active-advice-modal-component.scss"

interface SuccesActiveAdviceProps {
    messageRequestText: string;
    setSshowSuccesAceptModal: any;
    token:string;
}

//Implementa modal de informacion del estado del usuario
const SuccesActiveAdviceModal: FC<SuccesActiveAdviceProps> = ({ messageRequestText, setSshowSuccesAceptModal, token }) => {

    const { statusUser: statusUserActive} = useAppSelector((state) => state.activeUserDashboardAdminReducer.dashboardAdminUserStatusModelResponseData);

    const handleAceptButtonClick = async () => {
        setSshowSuccesAceptModal(false)
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle sx={{ background: "#D3A237", color: "white" }}>Información</DialogTitle>

            <div className="succes-active-modal">
                <div className="succes-active-modal__message">
                    {
                        statusUserActive === AsyncStatusEnum.loading && (<div className="spinner"></div>)
                    }
                    {statusUserActive === AsyncStatusEnum.completed  && messageRequestText}
                </div>

                <div className="succes-active-modal__button-bar">
                    <button onClick={handleAceptButtonClick} className="succes-active-modal__forward-button">Aceptar</button>
                </div>

            </div>
        </Dialog>
    )
}

export default SuccesActiveAdviceModal