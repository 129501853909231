export const GLOBALS = {
  menu: {
    dashboard: {
      link: "/",
      title: "Dashboard",
      icon: "",
    },
    template: {
      link: "/new-document",
      title: "Template",
      icon: "",
    },
    termnsconditions: {
      link: "/termnsandconditions",
      title: "Termns and Conditions",
      icon: "",
    },
    dataprocesing: {
      link: "/dataprocessingpolicy",
      title: "Data Processing Policy",
      icon: "",
    },
    editTemplateLawyer: {
      link: "/edit-document", 
      title: "Edit Document Template",
      icon: "",
    },
    dynamicParagraphBuild: {
      link: "/paragraph-build",
      title: "build dynamic document",
      icon: "",
    },
    dashboardAdmin: {
      link: "/dashboard-admin",
      title: "Dashboard Admin",
      icon: "",
    },
  },
  roles: {
    lawyer: {
      id: "L",
      roleName : "Lawyer",
      name: "Lawyer",
    },
    naturalPerson: {
      id: "U",
      roleName : "Natural Person",
      name: "Natural person",
    },
    admin: {
      id: "A",
      roleName : "Admin",
      name: "Administrator",
    },
  },
  fieldTypes:{
    select: {
      name: "Select",
    },
    textarea: {
      name: "TextArea",
    },
    datetime: {
      name: "DateTime",
    },
    inputtext: {
      name: "InputText",
    },
  },
  textResources : 
  {
    greeting:
    {
      es: "Hola",
      en: "Hi"
    }
  }
};
