import React, { useContext, useState, useEffect, useRef, FC } from "react";
import { Layout } from "../../common/Layout";
import { Context } from "../../../Context";
import { useLocation, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { GLOBALS } from "../../../utils/Globals";
import FormManagement from "../Form/FormManagement";
import { getDocumentTemplateThunk } from '../../../natural-person/redux-state/thunks/get-document-template'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AsyncStatusEnum } from '../../../commons/enums/async-status-enum';
import { CurrentDocumentPreviewService } from '../../../commons/services/current-document-preview-service';
import { updateCurrentDocumentPreview } from "../../../commons/redux-state/reducers/common-reducer";
import { getDocumentTemplatePreviewThunk } from "../../../commons/redux-state/thunks/get-document-template-preview";
import EditIcon from '@mui/icons-material/Edit';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import "./document-template-creation-view.scss";
import EditTemplateModalComponent from "../../../lawyer/components/edit-template-modal-component/edit-template-modal-component";
import FooterComponent from "../../common/footer-component/footer-component";
import { cleanTemplateInformation } from "../../../template-view/redux-state/template-view-reducer";
import { getDocumentTemplateAdapterToEditThunk } from "../../../document-template-edit/redux-state/thunks/get-document-template-adapter-to-edit";
import FinalDocumentWarningModalComponent from '../../../lawyer/components/final-document-warning-modal-component/final-document-warning-modal-component';
import { SortingService } from '../../../commons/services/sorting-service';
import { Clause, FirstLevelChild, Section } from "../../../commons/redux-state/models/document-template-response-model";
import { Dialog } from "@mui/material";
import { ValuesElementModel } from "../../../commons/redux-state/models/values-elements-model";

/**
 * Inicializa valueMap, para manejo de estados
 * @returns valueMap
 */

function init() {
  return { valueMap: {} };
}

//vista principal para crear y editar un documento
export default function DocumentTemplateCreationView() {
  
  const { documentSections } = useAppSelector(state => state.naturalPersonReducer);
  const { status:statusDocumentResponse } = useAppSelector(state => state.commonReducer.documentTemplatePreviewResponseData);
  const { templateInformation } = useAppSelector(state => state.templateInformationReducer.templateViewData);
  const firstLevelSections = useAppSelector(state => state.documentBuildReducer.firstLevelSections);
  const { status } = useAppSelector(state => state.naturalPersonReducer.documentTemplateData);
  const dispatch = useAppDispatch();

  const useQueryParameters = () => new URLSearchParams(useLocation().search); /*Tomar parametros URL*/
  const documentTemplateId = useQueryParameters().get("documentTemplateId") ?? "";
  const documentTemplateName = templateInformation?.data.templateName || "";
  const { isAuth: token, userLogged } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [redirectTo, setRedirectTo]: any = useState(null);
  const [formProps, setFormProps]: any = useState(null);
  const [showEDitTemplateModal, setShowEDitTemplateModal] = useState<boolean>(false);
  const [showFinalDocumentModal, setShowFinalDocumentModal] = useState<boolean>(false);
  let pruebaInput = "";

  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  const isLawyer: boolean = userLogged.profile.role !== GLOBALS.roles.lawyer.roleName ? true : false
  let arraySections: any = [];
  let arraySubsections: any = [];
  const valuesElementsArray: Array<ValuesElementModel> = []

  useEffect(() => {
    fetchData();
    setShowFinalDocumentModal(true)
    
  }, []);

  useEffect(()=>{setInputsArray()},[documentSections])

  //Crea un arreglo con todos identificadores de los campos para acceder al valor escrito
  const setInputsArray = () => {
    Object.values(documentSections).map((sectionElement: Section, index) => {
      
      if(sectionElement.section!.length > 0 && sectionElement.clause!.length > 0){
        
          sectionElement.clause?.map((clauseElement: Clause) => {
            clauseElement.clauseXField.map((element) => {
              const elementPropsObject: ValuesElementModel = {
                id: `${element.clauseId}-${element.position}`,
                value: "",
              }

              valuesElementsArray.push(elementPropsObject)
            })
          })
        
          sectionElement.section?.map((subSectionElement: Section) => {

            subSectionElement.clause?.map((clauseElement: Clause) => {
              clauseElement.clauseXField.map((element) => {
                const elementPropsObject: ValuesElementModel = {
                  id: `${element.clauseId}-${element.position}`,
                  value: "",
                }
               
                valuesElementsArray.push(elementPropsObject)
              })
            })

          })

        }else if(sectionElement.section!.length === 0 && sectionElement.clause!.length > 0){
          
          sectionElement.clause?.map((clauseElement: Clause) => {
            clauseElement.clauseXField.map((element) => {
              const elementPropsObject: ValuesElementModel = {
                id: `${element.clauseId}-${element.position}`,
                value: "",
              }
             
              valuesElementsArray.push(elementPropsObject)
            })
          })

        }else{

          sectionElement.section?.map((subSectionElement: Section) => {

            subSectionElement.clause?.map((clauseElement: Clause) => {
              clauseElement.clauseXField.map((element) => {
                const elementPropsObject: ValuesElementModel = {
                  id: `${element.clauseId}-${element.position}`,
                  value: "",
                }
               
                valuesElementsArray.push(elementPropsObject)
              })
            })

          })

        }
      });
      localStorage.setItem('inputArrayValues', JSON.stringify(valuesElementsArray))
  }

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  //Crea objeto con valores de campos para crear la plantilla como borrador
  const handleSketchButtonClick = (props:any) => {
    const sections: any = CurrentDocumentPreviewService.generateDocumentPreview(formRef.current, documentSections);
    dispatch(updateCurrentDocumentPreview({
        userId: userLogged.profile.sub,
        documentStatusId: 1,
        documentName: documentTemplateName,
        documentTemplateId: templateInformation?.data.documentTemplateId!,
        section: sections,
        documentStructureChange: sessionStorage.getItem('estructureChangeFlag') === 'false' ? false : true,
      })
    );
    dispatch(getDocumentTemplatePreviewThunk(token))
  }

  //Crea objeto con valores de campos para crear la plantilla
  const handleSubmit = async (props: any) => {
    props.preventDefault();
    setFormProps(props);
    const sections: any = CurrentDocumentPreviewService.generateDocumentPreview(props.target, documentSections)
      dispatch(updateCurrentDocumentPreview({
        userId: userLogged.profile.sub,
        documentStatusId: 1,
        documentName: documentTemplateName,
        documentTemplateId: templateInformation?.data.documentTemplateId!,
        section: sections,
        documentStructureChange: sessionStorage.getItem('estructureChangeFlag') === 'false' ? false : true,
      })
    );
    dispatch(getDocumentTemplatePreviewThunk(token))
    navigate(`${GLOBALS.menu.template.link}/preview`)
  };

  //Guarda la plantilla en el reducer para su edición en la ruta(/edit-document) 
  //y abre el modal de advertencia que indica que los datos diligenciados se reiniciarán
  const handleChangePage = () => {
    firstLevelSections.length === 0 && dispatch(getDocumentTemplateAdapterToEditThunk({ id: templateInformation?.data.documentTemplateId!, token: token }))
    setShowEDitTemplateModal(true);
  }

  /**
   * Metodo que se ejecuta luego del primer render
   * Obtiene un Objeto Plantilla con todos sus secciones/clausulas/campos
   * @returns Hook con 'Array' Secciones del documento
   */
  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (documentTemplateId !== "") {
        dispatch(getDocumentTemplateThunk({ id: Number(documentTemplateId), token: token }))
      }
    } catch (ex: any) {
      setError(ex.message);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Convierte un Array de secciones en un elemento JSX que incluye dichas secciones y todas sus clausulas/campos
   * @returns elemento JSX
   */
  const DocumentTemplateBody = () => {
    const [sectionsCount, setSectionsCount] = useState(1);
    if (arraySections.length === 0 || arraySections === undefined) {
      Object.values(documentSections).forEach((element: any) => { arraySections.push(true); });
    }
    const handleClickSections = (index: number, e: any) => {

      arraySections[index] = !arraySections[index];
      if (arraySections[index] === true) {
        e.target.className = "icon-icon-minus";
      } else {
        e.target.className = "icon-icon-plus";
      }
      
      setSectionsCount((sectionsCount) => sectionsCount + 1);
    };

    const documentTemplateJSX = Object.values(documentSections).map(
      (sectionElement: any, index) => {
        return (
          <div key={uuidv4()} className={"colapse edit"}>
            <div className="head-section">
              <div className="circle">{sectionElement.sectionNumber}</div>
              <h5> {sectionElement.sectionTitle}</h5>
              <div className="rounded-square">
                <span
                  onClick={(e: any) => handleClickSections(index, e)}
                  className={arraySections[index] === true ? "icon-icon-minus" : "icon-icon-plus"}
                ></span>
              </div>
            </div>
            <div style={{ display: arraySections[index] === true ? "block" : "none", }}>
              {sectionElement.section.length > 0 && sectionElement.clause.length > 0 ? (
                <>
                  <FirstLevelChildren firstLevelChidren={SortingService.sortChildren(sectionElement.clause, sectionElement.section)}/>
                </>
              ) : sectionElement.section.length === 0 && sectionElement.clause.length > 0 ? (
                <>
                  <Clauses clause={sectionElement.clause} indexValue={index} />
                </>
              ) : (
                <SubSections subsections={sectionElement.section} />
              )}
            </div>
          </div>
        );
      }
    );
    return <>{documentTemplateJSX}</>;
  };


  // Componenete para recorrer arreglo de clausulas y subsecciones
  interface FirstLevelChildProps {
    firstLevelChidren: FirstLevelChild[];
  }
  
  const FirstLevelChildren: FC<FirstLevelChildProps> = ({ firstLevelChidren }) => {
    return (
      <>
        {
          firstLevelChidren.map((child: FirstLevelChild, index) => {
            switch (child.childType) {
              case "clause":
                return (
                  <div key={uuidv4()} className="body-section">
                    <div className="contents">
                      {clausexFields(child.clause?.clauseText!, child.clause?.clauseXField)}
                    </div>
                  </div>
                )
              case "subsection":
                return <FirstLevelSubsectionComponent child={child} key={uuidv4()}/>
            }
          })
        }
      </>
    )
  }

 // Componenete para recorrer arreglo hijos de subsecciones
  interface FirstLevelSubsectionProps {
    child: FirstLevelChild;
  }

  const FirstLevelSubsectionComponent: FC<FirstLevelSubsectionProps> = ({ child }) => {

    const [deploy, setDeploy] = useState(true);
    const [deployClass, setDeployClass] = useState("block")
    const [acordeonIcon, setAcordeonIcon] = useState("icon-icon-minus")

    const handleSpanClick = () => {
      
      if(deploy){
        setDeploy(false);
        setDeployClass("none");
        setAcordeonIcon("icon-icon-plus");
      }else{
        setDeploy(true);
        setDeployClass("block");
        setAcordeonIcon("icon-icon-minus");
      }
    }

    return (
      <div>
        <div className="subhead open">
          <div>
            <span></span>
          </div>
          <h6 className="text-center">{child.section?.sectionTitle}</h6>
          <div>
            <span onClick={handleSpanClick} className={acordeonIcon}></span>
          </div>
        </div>
        <div style={{ display: deployClass }}>
          {<Clauses clause={child.section?.clause} />}
        </div>
      </div>
    )
  }
  
  
  //Convierte un Array de SubSecciones en un elemento JSX que incluye dichas SubSecciones y todas sus clausulas/campos
   

  const SubSections = (subsections: any) => {
    
    const [subSectionCount, setSubSectionCount] = useState(1);
    if (arraySubsections.length === 0 || arraySubsections === undefined) {
      Object.values(subsections.subsections).forEach((element: any) => { arraySubsections.push(true); });
    }
    const handleClickSubSections = (index: number, e: any) => {
      arraySubsections[index] = !arraySubsections[index];
      if (arraySubsections[index] === true) {
        e.target.className = "icon-icon-minus";
      } else {
        e.target.className = "icon-icon-plus";
      }
      setSubSectionCount((count) => count + 1);
    };

    const subsectionsJSX = Object.values(subsections.subsections).map(
      (u: any, index) => {
        return (
          <div key={uuidv4()}>
            <div className="subhead open">
              <div>
                <span></span>
              </div>
              <h6 className="text-center">{u.sectionTitle}</h6>
              <div>
                <span onClick={(e: any) => handleClickSubSections(index, e)} 
                className={arraySubsections[index] === true || arraySubsections[index] === undefined ? "icon-icon-minus" : "icon-icon-plus"}></span>
              </div>
            </div>
            <div style={{ display: arraySubsections[index] === true || arraySubsections[index] === undefined ? "block" : "none", }}>
              <Clauses clause={u.clause} />
            </div>
          </div>
        );
      }
    );
    return <>{subsectionsJSX}</>;
  };

  /**
   * Convierte un 'Array' de clausulas en un elemento JSX incluyendo los campos
   * envia el nombre de la clausula y un 'Array' de los campos asociados a cada clausula
   * recibe un 'Array' de clausulas
   * @param clauseArray
   * @returns {JSX.Element}
   */
  const Clauses = (props: any) => {
    const clausesJSX = Object.values(props.clause).map((u: any) => {
      return (
        <div key={uuidv4()} className="body-section">
          <div className="contents">
            {clausexFields(u.clauseText, u.clauseXField)}
          </div>
        </div>
      );
    });
    return <>{clausesJSX}</>;
  };

  /**
   * Convierte cada elemento de un 'Array' de campos, a un elemento JSX, ubicando cada uno de ellos en la clausula.
   * Recibe el nombre de la clausula y un array de campos.
   * @param clauseText
   * @param clauseXField
   * @returns {JSX.Element}
   */
  const clausexFields = (clauseText: string, clauseXField: any) => {
    const deleteHtmlTags = clauseText.replace( /(<([^>]+)>)/ig, '');
    let clausulatexto = deleteHtmlTags.split("{##}");
    let resultado = <></>;
    const fieldsJSX = Object.values(clauseXField).map((element: any, index) => {

      let elementJSX = <></>;
      elementJSX = (
        <FormManagement element={element} valueInit={init()} />
      );
      return <>{elementJSX}</>;
    });
    for (let index = 0; index < clausulatexto.length; index++) {
      resultado = (
        <>
          {resultado}
          {clausulatexto[index]}
          {fieldsJSX[index]}
        </>
      );
    }
    return resultado;
  };


  return (
    <Layout>
      <form ref={formRef} onSubmit={handleSubmit}>
        {isLoading ? <div className="spinner"></div> :
          <section className="diligenciamiento mt-50">
            <div className="container">
              
              {status === AsyncStatusEnum.loading && (<div className="spinner"></div>)}
              {status === AsyncStatusEnum.completed && (
              <>
                <div className="row">
                  <div className="col-8">
                    <h3 className="title green">{templateInformation?.data.templateName}</h3>
                  </div>

                  <button type="button" onClick={handleChangePage} className="edit-template-boton edit-document" hidden={isLawyer}>
                    <p>Editar Plantilla</p>
                    <div className="circle">
                      <EditIcon sx={{ color: "#00A9E3", fontSize: "30px" }} />
                    </div>
                  </button>

                </div>
                <div className="row">
                  <div className="row">
                    <div className="col-12">
                      <DocumentTemplateBody />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">

                      <div className="final-buttons-container">
                        <Link onClick={() => dispatch(cleanTemplateInformation(null))} to={`${GLOBALS.menu.template.link}`}>
                          <button className="final-buttons-container__save-back">Atrás</button>
                        </Link>
                        <button onClick={handleSketchButtonClick} id="saveButton" type="button" className="final-buttons-container__save-sketch">
                          Guardar borrador
                        </button>
                        <button id="endEditButton" type="submit" className="final-buttons-container__save-final">
                          Finalizar documento
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </>
              
              )}
            </div>

          </section>}
      </form>

      
      {statusDocumentResponse === AsyncStatusEnum.loading && 
      <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
        <div className="dashboard-advice-modal">
          <div className="dashboard-advice-modal__message">
            <div className="spinner"></div>
          </div>

          <div className="dashboard-advice-modal__button-bar">
          </div>

        </div>
      </Dialog>}

      {statusDocumentResponse === AsyncStatusEnum.completed && (window.location.href = "/")}

      {
        showEDitTemplateModal && <EditTemplateModalComponent
          setShowEDitTemplateModal={setShowEDitTemplateModal}
        />
      }

      {
        userLogged.profile.role === GLOBALS.roles.lawyer.roleName && showFinalDocumentModal && <FinalDocumentWarningModalComponent
          setShowFinalDocumentModal={setShowFinalDocumentModal}
        />
      }


      <FooterComponent />
    </Layout>
  );
}