import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Paragraph, SecondLevelSectionModel } from "../../redux-state/models/first-level-section-model";
import { changeFirstLevelChildrenDownwardOrder, changeFirstLevelChildrenUpwardOrder, updateChildrenToHiglight, updateParagraphMetadata, updateSecondLevelChildrenToHiglight, updateSubtitleText } from "../../redux-state/reducers/document-build-reducer";
import "./second-level-section-component.scss";
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditSubtitleModalComponent from "../edit-subtitle-modal-component/edit-subtitle-modal-component";
import DeleteSubtitleWarningModalComponent from "../delete-subtitle-warning-modal-component/delete-subtitle-warning-modal-component";
import { DocumentBuildService } from "../../services/document-build-service";
import { ParentTypeEnum } from "../../enums/parent-type-enum";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import SecondLevelParagraphComponent from "../second-level-paragraph-component/second-level-paragraph-component";

interface SecondLevelSectionProps {
    subtitle: SecondLevelSectionModel
}

const SecondLevelSectionComponent:FC<SecondLevelSectionProps> = ({subtitle}) => {

    const firstLevelSections = useAppSelector(state => state.documentBuildReducer.firstLevelSections);
    const firstLevelChildrenToHiglight = useAppSelector(state => state.documentBuildReducer.firstLevelChildrenToHiglight);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const [title, setTitle] = useState<string>(subtitle.title.titleText);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [deploySubtitleOptions, setDeploySubtitleOptions] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const secondLevelRef = useRef<HTMLDivElement>(null);
    

    //escucha cuando el input de titulo se monta en el dom y hace focus en el
    useEffect(() => {
        if(inputRef.current) inputRef.current!.focus();
    })

    //se ejecuta cuando el componente se monta y cambia los estilos del mismo si corresponde con el id del componente que está siendo editado
    useEffect(() => {
        if(firstLevelChildrenToHiglight?.childrenId === subtitle.selfPositionId && firstLevelChildrenToHiglight?.parentPositionId === subtitle.firstLevelParentPositionId) {
            secondLevelRef.current!.style.background = "#9bd2dd";
        }
    }, [])

    //se ejecuta cuando se hace click sobr el titulo existente
    const handleTitleClick = () => {
        setIsEditing(true);
    }

    const handleClickOutsideSecondLevelSection = () => {
        secondLevelRef.current!.style.background = "none";
        dispatch(updateChildrenToHiglight(null));
        dispatch(updateSecondLevelChildrenToHiglight(null));
    }

    //se ejecuta al oprimir enter en el input de titulo
    const handleInputKeyPress = (e: any) => {
        if(e.charCode === 13) setIsEditing(false);
        if(e.target.value.trim() !== "" && e.charCode === 13) {
           setTitle(e.target.value);
           dispatch(updateSubtitleText({
                parentPositionId: subtitle.firstLevelParentPositionId,
                selfPositionId: subtitle.selfPositionId,
                newSubtitle: e.target.value
            }))
        }
    }

    //se ejecuta al hacer blur sobre el input de titulo
    const handleInputBlur = (e:any) => {
        setIsEditing(false);
        if(e.target.value.trim() !== "") {
           setTitle(e.target.value);
           dispatch(updateSubtitleText({
                parentPositionId: subtitle.firstLevelParentPositionId,
                selfPositionId: subtitle.selfPositionId,
                newSubtitle: e.target.value
            }))
        }
    }

    const handleShowOptionsClick = () => {
        if(deploySubtitleOptions) setDeploySubtitleOptions(false);
        else setDeploySubtitleOptions(true);
    }

    const handleClickOutsideSubtitleBar = () => {  
        setDeploySubtitleOptions(false);
    }

    //Muestra modal de edicion
    const handleEditButtonClick = () => {
        setShowEditModal(true);
    }

    const handleDeleteButtonClick = () => {
        setShowDeleteModal(true);
    }

    //se ejecuta cuando se hace click en la flecha hacia arriba del subtítulo
    const handleArrowUpClick = () => {
        
        //cambiando y actualizando la posición de los párrafos/subtítulos
        dispatch(changeFirstLevelChildrenUpwardOrder({firstLevelParentPositionId: subtitle.firstLevelParentPositionId, selfPositionId: subtitle.selfPositionId}));
        dispatch(updateChildrenToHiglight({childrenId: subtitle.selfPositionId - 1, parentPositionId: subtitle.firstLevelParentPositionId}));
        dispatch(updateSecondLevelChildrenToHiglight(null));
    }

    //se ejecuta cuando se hace click en la flecha hacia abajo del subtítulo
    const handleArrowDownClick = () => {

        //cambiando y actualizando la posición de los párrafos/subtítulos
        dispatch(changeFirstLevelChildrenDownwardOrder({firstLevelParentPositionId: subtitle.firstLevelParentPositionId, selfPositionId: subtitle.selfPositionId}));
        dispatch(updateChildrenToHiglight({childrenId: subtitle.selfPositionId + 1, parentPositionId: subtitle.firstLevelParentPositionId}));
        dispatch(updateSecondLevelChildrenToHiglight(null));
    }

    //se ejecuta cuando hacemos click sobre el botón agregar párrafo
    const handleAddParagraphButtonClick = () => {
        dispatch(updateParagraphMetadata({
            parentType: ParentTypeEnum.subTitle,
            parentText: subtitle.title.titleText,
            firstLevelParentPositionId: subtitle.firstLevelParentPositionId,
            secondLevelParentPositionId: subtitle.selfPositionId,
            selfPositionId: DocumentBuildService.getSecondLevelChildSelfPosition(subtitle.firstLevelParentPositionId, subtitle.selfPositionId)
        }));
        navigate('/paragraph-build');
    }

    return (
        <ClickAwayListener onClickAway={handleClickOutsideSecondLevelSection}>
            <div className="second-level-section" ref={secondLevelRef}>

                <ClickAwayListener onClickAway={handleClickOutsideSubtitleBar}>
                    <div className="second-level-section__title-container">

                        <div className="second-level-section__title-bar">

                            <div className="second-level-section__arrow-container">

                                { DocumentBuildService.showUpArrowInChild(subtitle) && <button className="second-level-section__arrow" onClick={handleArrowUpClick}><KeyboardArrowUpIcon sx={{color: "white", background: "#0051a8", "&:hover": {background: "#00a9e3"}}}/></button>}
                                { DocumentBuildService.showDownArrowInChild(firstLevelSections, subtitle) && <button className="second-level-section__arrow" onClick={handleArrowDownClick}><KeyboardArrowDownIcon sx={{color: "white", background: "#0051a8", "&:hover": {background: "#00a9e3"}}}/></button>}

                            </div>

                            { !isEditing && <h2 onClick={handleTitleClick} className="second-level-section__title">{title}</h2>}
                            { isEditing && <input ref={inputRef} className="second-level-section__input-title" type="text" onKeyPress={handleInputKeyPress} onBlur={handleInputBlur}/>}

                            <div className="second-level-section__options-container">

                                {   
                                    deploySubtitleOptions &&
                                    <div className="second-level-section__options-buttons">

                                        <Tooltip title="Agregar párrafo" placement="top" arrow>
                                            <IconButton onClick={handleAddParagraphButtonClick}>
                                                <AddCircleIcon sx={{color: "white", fontSize: "31px"}} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Editar subtítulo" placement="top" arrow>
                                            <IconButton onClick={handleEditButtonClick}>
                                                <EditIcon sx={{color: "white", fontSize: "31px"}} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Eliminar subtítulo" placement="top" arrow>
                                            <IconButton onClick={handleDeleteButtonClick}>
                                                <DeleteIcon sx={{color: "white", fontSize: "31px"}} />
                                            </IconButton>
                                        </Tooltip>
                                        
                                    </div>
                                }

                                <IconButton sx={{"background": "#00458E"}} onClick={handleShowOptionsClick}>
                                    <MenuIcon sx={{"color": "white"}}/>
                                </IconButton>

                            </div>

                        </div>

                        {   
                            subtitle.title.tooltip &&
                            <div className="second-level-section__tooltip-container">
                                <Tooltip title={subtitle.title.tooltipText} arrow>
                                    <IconButton sx={{marginTop: "7px"}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }

                    </div>
                </ClickAwayListener>

                {
                    showEditModal && <EditSubtitleModalComponent 
                    setShowEditModal={setShowEditModal}
                    titleText={subtitle.title.titleText}
                    tooltip={subtitle.title.tooltip}
                    tooltipText={subtitle.title.tooltipText}
                    parentPositionId={subtitle.firstLevelParentPositionId}
                    selfPositionId={subtitle.selfPositionId}
                    />
                }

                {
                    showDeleteModal && <DeleteSubtitleWarningModalComponent
                    parentPositionId={subtitle.firstLevelParentPositionId}
                    selfPositionId={subtitle.selfPositionId}
                    setShowDeleteModal={setShowDeleteModal}
                    />
                }

                {   
                    subtitle.content.length !== 0 &&
                    <div className="second-level-section__children-container">
                        {
                            subtitle.content.map((paragraph: Paragraph) => <SecondLevelParagraphComponent paragraph={paragraph} key={uuidv4()} />)
                        }
                    </div>
                }

            </div>
        </ClickAwayListener>
    )
}

export default SecondLevelSectionComponent;