import { TypeFieldData } from "../../commons/redux-state/models/field-model";
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";

//Funcion que obtiene los tipos de campos
export class PreviewDocumentService {
    static async getTypeFieldById(typeFieldId:number, token: string): Promise<any | null> {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}TypeField/Details/${typeFieldId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            //validate token with status 401
            ValidateUserSessionService.handleError(response.status);
            const TypeFied: any = await response.json();
            return TypeFied;
        } catch (error) {
            return null
        }
    }
}