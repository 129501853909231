import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { FirstLevelSectionModel } from "../../../lawyer/redux-state/models/first-level-section-model";
import { DocumentTemplateAdapterToEditInitialStateModel } from "../models/document-template-adapter-to-edit-initial-state-model";
import { getDocumentTemplateAdapterToEditThunk } from "../thunks/get-document-template-adapter-to-edit";

export const documentTemplateAdapterToEditInitialState: DocumentTemplateAdapterToEditInitialStateModel = {
    documentTemplateTrasnformedData: {
        status: AsyncStatusEnum.idle,
        documentTemplateTransformed: null,
        error: {
            error: false,
            message: "",
        },
    },
};

 //extraReducer solo para asincrono y/o sincronos - reducers solo sincronos 
export const documentTemplateAdapterToEditReducer = createSlice({
  name: "document-template-adapter-to-edit-reducer",
  initialState: documentTemplateAdapterToEditInitialState ,
  reducers: {
    cleanTemplateToEditData:(state, action: PayloadAction<null>) => {
      state.documentTemplateTrasnformedData.documentTemplateTransformed = action.payload},
  },
  extraReducers: (builder) => {
    builder
    .addCase(getDocumentTemplateAdapterToEditThunk.fulfilled, (state, action:PayloadAction<Array<FirstLevelSectionModel>>) => {
      state.documentTemplateTrasnformedData.status = AsyncStatusEnum.completed;
      state.documentTemplateTrasnformedData.documentTemplateTransformed = action.payload;
      state.documentTemplateTrasnformedData.error = {
        error: false,
        message: '',
      };
    })
    .addCase(getDocumentTemplateAdapterToEditThunk.pending, (state) => {
      state.documentTemplateTrasnformedData.status = AsyncStatusEnum.loading;
    })
    .addCase(getDocumentTemplateAdapterToEditThunk.rejected, (state, action) => {
      state.documentTemplateTrasnformedData.documentTemplateTransformed = documentTemplateAdapterToEditInitialState.documentTemplateTrasnformedData.documentTemplateTransformed;
      state.documentTemplateTrasnformedData.status = AsyncStatusEnum.rejected;
      state.documentTemplateTrasnformedData.error = {
        error: true,
        message: 'Error rejected',
      };
    })
  },
});
export const { cleanTemplateToEditData } = documentTemplateAdapterToEditReducer.actions;
  
export default documentTemplateAdapterToEditReducer.reducer;
