import React, { useContext, useEffect, useRef, useState } from "react";
import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Context } from "../../../Context";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { v4 as uuidv4 } from 'uuid';
import ActiveAdviceModalComponent from "../../../dashboard-admin/components/active-advice-model-component/active-advice-modal-component";
import DesactiveWarningModalComponent from "../../../dashboard-admin/components/desactive-warnig-modal-component/desactive-warnig-modal-component";
import { getuserListDashboardAdminThunk } from "../../../dashboard-admin/redux-state/thunks/get-user-list-dashboard-admin-thunk";
import SuccesDeclineAdviceModal from "../../../dashboard-admin/components/succes-decline-advice-modal/succes-decline-advice-modal";
import { DashboardAdminFilterUsersRequestModel, Filter } from "../../../dashboard-admin/redux-state/models/dashboard-admin-filter-users-request-model";
import { updateAdminUserListModel } from "../../../dashboard-admin/redux-state/reducers/dashboard-admin-reducer";
import SuccesActiveAdviceModal from "../../../dashboard-admin/components/succes-active-advice-modal-component/succes-active-advice-modal-component";
import { DashboardAdminService } from "../../../dashboard-admin/services/dashboard-admin-service";
import { PersonTypeData, PersonTypeModel } from '../../../commons/redux-state/models/person-type-model';
import CalendarAdviceModal from "../../../dashboard-admin/components/calendar-advice-model-component/calendar-advice-model-component";

import "./dashboard-admin-view.scss"


function DashboardAdminView() {
    const { dashboardAdminDocumentsUserModelResponseData } = useAppSelector((state) => state.dashboardAdminReducer);
    const { status } = useAppSelector((state) => state.dashboardAdminReducer.dashboardAdminDocumentsUserModelResponseData);
    const { dashboardAdminUserStatusModelResponseData: desactiveUserState } = useAppSelector((state) => state.disabledUserDashboardAdminReducer);
    const { dashboardAdminUserStatusModelResponseData: activeUserState } = useAppSelector((state) => state.activeUserDashboardAdminReducer);

    const dispatch = useAppDispatch();

    const { isAuth: token } = useContext(Context);
    const [showActiveUserModal, setShowActiveUserModal] = useState<boolean>(false);
    const [showDesactiveModal, setShowDesactiveModal] = useState<boolean>(false);
    const [showSuccesDeclineModal, setShowSuccesDeclineModal] = useState<boolean>(false);
    const [showSuccesAceptModal, setShowSuccesAceptModal] = useState<boolean>(false);
    const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
    const [personTypesList, setPersonTypesList] = useState<any>(<></>);
    const [personTypeSelected, setPersonTypeSelected] = useState<string>("")
    const [userStateSelected, setUserStateSelected] = useState<string>("")
    const [userId, setUserId] = useState<number>(0);
    const [userName, setUserName] = useState<string>("");
    const [page, setPage] = useState(1);
    const pageRef = useRef(page);

    //clouser para paginador
    const updatePage = (newValue: number) => {
        pageRef.current = newValue;
        setPage(newValue);
    };

    useEffect(() => {
        dispatch(getuserListDashboardAdminThunk(token));
        fetchData()
    }, []);

    useEffect(() => {
        dispatch(getuserListDashboardAdminThunk(token));
    }, [desactiveUserState]);

    useEffect(() => {
        dispatch(getuserListDashboardAdminThunk(token));
    }, [activeUserState]);

    const fetchData = async () => {
        try {
            const personTypeList: PersonTypeModel | null = await DashboardAdminService.getTypePersonList(token);
            if (personTypeList) {
                setPersonTypesList(
                    Object(personTypeList?.data).map((personTypeElement: PersonTypeData) => {
                        return (
                            <option key={uuidv4()} id={(personTypeElement.personTypeId).toString()} value={personTypeElement.name}>
                                {personTypeElement.name}
                            </option>
                        );
                    })
                );
            }
        } catch (error: any) { }
    };

    //Filtro de busqueda por nombre
    const handleChangeButtonNameSearch = (props: any) => {

        const filter: Filter = {
            "operator": "Contains",
            "propertyName": "fullName",
            "value": props.target.value
        };
        submit('fullName', [filter])
    }

    //Filtro de busqueda por correo
    const handleChangeButtonEmailSearch = (props: any) => {

        const filter: Filter = {
            "operator": "Contains",
            "propertyName": "Email",
            "value": props.target.value
        };
        submit('Email', [filter])
    }

    //Acciones para activar un usuario
    const handleClickButtonActive = (userId: number) => {
        setUserId(userId)
        setShowActiveUserModal(true)
    }

    //Accion del paginador, setear valor de pagina
    const handleClickPage = (event: React.ChangeEvent<unknown>, value: number) => {
        updatePage(value);
        submit('UserId', []);
    }
    
    //Abre modal para seleccion de rango de fechas
    const handleDateRangePickerClick = () => {
        setShowCalendarModal(true)
    }    
    
    //Accion que se ejecuta cuando se selecciona un rango de fechas pra el filtro
    const searchRangeDate = (rangeDateArray:Array<Filter>) => {       
        submit('NextBillingDate', rangeDateArray)
    };

    //Accion cuando se selecciona una opcion del selector de tipo de persona
    const handleSelectTypePerson = (props: any) => {
        setPersonTypeSelected(props.target.selectedOptions[0].label)

        if (props.target.selectedOptions[0].label !== "Tipo de persona") {
            const filter: Filter = {
                "operator": "Equals",
                "propertyName": "personTypeName",
                "value": props.target.selectedOptions[0].label
            };
            submit('personTypeName', [filter]);
        } else { 
            submit('UserId', []); 
        }
    }

    //Accion cuando se selecciona una opcion del selector de estado de usuario
    const handleSelectUserState = (props: any) => {
        setUserStateSelected(props.target.selectedOptions[0].label)
        
        const filter: Filter = {
            "operator": "Equals",
            "propertyName": "planActive",
            "value": props.target.selectedOptions[0].label === "Activo" ? "true" : "false"
        };

        submit('planActive', [filter]);
    }

    //Funcion que crea un objeto de tipo DashboardAdminFilterUsersRequestModel para hacer la peticion de filtros
    const submit = (sortProperty: string, filter: Array<Filter>) => {

        let filterUsersaList: DashboardAdminFilterUsersRequestModel = {
            pageNumber: pageRef.current,
            pageSize: 10,
            sortProperty: sortProperty,
            sortType: 'des',
            filters: filter,
        }

        dispatch(updateAdminUserListModel(filterUsersaList))
        dispatch(getuserListDashboardAdminThunk(token));
    }

    //Acciones para desactivar un usuario
    const handleClickButtonDesactive = (userId: number, userName: string) => {
        setUserId(userId);
        setUserName(userName);
        setShowDesactiveModal(true);
    }

    //Funcion para retornar el boton de activar o desactivar segun la validacion del plan
    const getButtonsPlanStatus = (planActive: boolean, userId: number, userName: string) => {
        let buttonJSX: JSX.Element = <></>
        planActive ? buttonJSX = <button onClick={() => handleClickButtonDesactive(userId, userName)} className="btnType download">Desactivar</button> :
            buttonJSX = <button onClick={() => handleClickButtonActive(userId)} className="btnType download">Activar</button>
        return buttonJSX
    }

    //funcion que recorre el objeto lista de documentos y retorna un objeto JSX para renderizar
    const tableRegisters = () => {
        const data = dashboardAdminDocumentsUserModelResponseData.dashboardAdminUserListData?.data.list;
        let rowsTable: JSX.Element[] = [];
        if (data !== undefined) {
            rowsTable = data.map((row) =>
                <TableRow key={uuidv4()} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                    <TableCell align="center">{row.fullName}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.personTypeName}</TableCell>
                    <TableCell align="center">{row.planActive ? "Activo" : "Inactivo"}</TableCell>
                    <TableCell align="center">{row.nextBillingDate}</TableCell>
                    <TableCell align="center">
                        {getButtonsPlanStatus(row.planActive, row.userId, row.fullName)}
                    </TableCell>
                </TableRow>
            );
        }
        return rowsTable.length > 0 ? (rowsTable) : (
            <tr>
                <td colSpan={4}>
                    <div>No se encontraron resultados para la busqueda</div>
                </td>
            </tr>
        );
    };

    return (
        <div className="dashboard mt-50">
            <div className="container">

                <div className="row justify-content-end mb-50">
                    <div className="col-12 col-md d-md-flex">
                    </div>
                </div>

                <h3 className="title blue">Actualizar Plan</h3>
                <div className="filters">

                    <div className="buscador">
                        <input onChange={handleChangeButtonNameSearch} placeholder="Buscar por nombre" className="icon-icon-check" type="text"></input>
                    </div>

                    <div className="buscador">
                        <input onChange={handleChangeButtonEmailSearch} placeholder="Buscar por correo" className="icon-icon-check" type="text"></input>
                    </div>

                    <div className="campos">

                        <div className="field">
                            <select onChange={handleSelectTypePerson} value={personTypeSelected}>
                                <option value="">Tipo de persona</option>
                                {personTypesList}
                            </select>
                            <span className="icon-arrow-down"></span>
                        </div>

                        <div className="field">
                            <select onChange={handleSelectUserState} value={userStateSelected}>
                                <option value="">Estado</option>
                                <option value='Activo'>Activo</option>
                                <option value='Inactivo'>Inactivo</option>
                            </select>
                            <span className="icon-arrow-down"></span>
                        </div>

                        <div className="field">
                            <button className="button-range" onClick={handleDateRangePickerClick}>Rango de fechas</button>
                        </div>

                    </div>
                </div>

                {
                    status === AsyncStatusEnum.loading && 
                    (
                        <div className="dashboard-spinner-container">
                            <div className="spinner"></div>
                        </div>
                    )
                }
                {
                    status === AsyncStatusEnum.completed && (<Paper sx={{ width: "100%", overflow: "hidden" }}>
                        <TableContainer component={Paper} className="mt-30">
                            <Table sx={{ minWidth: 650 }} className=" table" aria-label="simple">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nombre</TableCell>
                                        <TableCell align="center">Correo</TableCell>
                                        <TableCell align="center">Tipo de persona</TableCell>
                                        <TableCell align="center">Estado plan</TableCell>
                                        <TableCell align="center">Fecha de vencimiento</TableCell>
                                        <TableCell>Activar/Desactivar</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        status === AsyncStatusEnum.completed && tableRegisters()
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br />
                        <Pagination
                            onChange={handleClickPage}
                            page={page}
                            count={dashboardAdminDocumentsUserModelResponseData.dashboardAdminUserListData?.data.totalPages}
                            color="primary"
                            size="large"
                        />
                        <div>
                            {`Total resultados ${dashboardAdminDocumentsUserModelResponseData.dashboardAdminUserListData?.data.totalItems}`}
                        </div>
                    </Paper>)
                }

            </div>
            {
                showActiveUserModal && <ActiveAdviceModalComponent setShowSuccesAceptModal={setShowSuccesAceptModal} token={token} setShowActiveUserModal={setShowActiveUserModal} userId={userId} />
            }
            
            {
                showCalendarModal && <CalendarAdviceModal searchRangeDate={searchRangeDate} setShowCalendarModal={setShowCalendarModal}  />
            }

            {
                showDesactiveModal && <DesactiveWarningModalComponent setShowSuccesDeclineModal={setShowSuccesDeclineModal} setShowDesactiveModal={setShowDesactiveModal} userId={userId} token={token} userName={userName} />
            }

            {
                showSuccesDeclineModal && <SuccesDeclineAdviceModal setShowSuccesDeclineModal={setShowSuccesDeclineModal} token={token} messageRequestText={desactiveUserState.dashboardAdminUserStatusData?.header.message!} />
            }

            {
                showSuccesAceptModal && <SuccesActiveAdviceModal setSshowSuccesAceptModal={setShowSuccesAceptModal} token={token} messageRequestText={activeUserState.dashboardAdminUserStatusData?.header.message!} />
            }

        </div>

    );
}


export default DashboardAdminView;
