import React, { useRef } from "react";
import "./add-title-modal-component.scss";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TitleSubtitleModel } from "../../redux-state/models/title-subtitle-model";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { updateFirstLevelSections, updateShowAddTitleModal } from "../../redux-state/reducers/document-build-reducer";
import { FirstLevelSectionModel } from "../../redux-state/models/first-level-section-model";
import { DocumentBuildService } from "../../services/document-build-service";

//formulario para agragra un nuevo titulo en la vista de creación de plantilla---------------------------------------------------------------------------

const AddTitleMododalComponent = () => {
    /* Variables de colores */
    const blue = "#004571";
    const purple = "#82389A";

    const showAddTitleModal = useAppSelector(state => state.documentBuildReducer.showAddTitleModal); 
    const firstLevelSections = useAppSelector(state => state.documentBuildReducer.firstLevelSections); 
    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: TitleSubtitleModel = {
        titleText: "",
        tooltip: false,
        tooltipText: ""
    }

    const validationSchema = yup.object({
        titleText: yup.string()
        .max(300, "El nombre del titulo debe ser maximo de 300 caracteres")
        .min(1, "El nombre del titulo debe ser mínimo de 1 caracteres")
        .required("El nombre del titulo es requerido"),
        tooltip: yup.boolean()
        .required(""),
        tooltipText: yup.string()
        .max(300, "El texto debe ser maximo de 300 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres"),
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  

            const newFirstLevelSection: FirstLevelSectionModel = {
                positionid: DocumentBuildService.getTitlePositionId(),
                title: {
                    titleText: values.titleText,
                    tooltip: values.tooltip,
                    tooltipText: values.tooltipText
                },
                children:[]
            }
            dispatch(updateFirstLevelSections([...firstLevelSections, newFirstLevelSection]));

            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    const handleCancelButtonClick = () => {
        dispatch(updateShowAddTitleModal(false));
    }

    const handleAddButtonClick = () => {
        dispatch(updateShowAddTitleModal(false));
    }

    return (
    <Dialog open={showAddTitleModal} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle sx={{background: blue, color: "white"}}>Ingrese la información del título</DialogTitle>

        <div className="add-title-modal">
            
            <form onSubmit={form.handleSubmit} ref={formRef}>

                <div>
                    <TextField 
                        variant="outlined"
                        label="Nombre del titulo"
                        style = {{background: "white"}}
                        id="titleText"
                        name="titleText"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.errors.titleText && form.touched.titleText && <p className="validation-error">{form.errors.titleText}</p>}
                </div>

                <FormControlLabel control={<Checkbox id="tooltip" name="tooltip" checked={form.values.tooltip} onChange={form.handleChange}/>} label="Desea añadir autoayuda?" />

                <div>
                    <TextField 
                        variant="outlined"
                        label="Ingerse texto de la autoayuda"
                        style = {{background: "white"}}
                        id="tooltipText"
                        name="tooltipText"
                        disabled={!form.values.tooltip}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.errors.tooltipText && form.touched.tooltipText && <p className="validation-error">{form.errors.tooltipText}</p>}
                </div>

                <div className="add-title-modal__actions-bar">
                    <button className="add-title-modal__back-button" onClick={handleCancelButtonClick} type="button">cancelar</button>
                    <button className="add-title-modal__forward-button"onClick={handleAddButtonClick} type="submit" disabled={!form.isValid || !form.dirty }>Agregar título</button>
                </div>

                </form>

            </div>
      
    </Dialog>
    )
}

export default AddTitleMododalComponent;

