import React, { FC, useEffect, useRef, useState } from "react";
import { FirstLevelSectionModel, Paragraph, SecondLevelSectionModel } from "../../redux-state/models/first-level-section-model";
import "./first-level-secion-component.scss";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DocumentBuildService } from "../../services/document-build-service";
import { useAppDispatch } from "../../../redux/hooks";
import { updateChildrenToHiglight, updateSectionIdToDelete, updateShowDeleteTitleWarningModal, updateTitleText } from "../../redux-state/reducers/document-build-reducer";
import EditTitleModalComponent from "../edit-title-modal-component/edit-title-modal-component";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import AddTitleChildModalComponent from "../add-title-child-modal-component/add-title-child-modal-component";
import FirstLevelParagraphComponent from "../first-level-paragraph-component/first-level-paragraph-component";
import { v4 as uuidv4 } from 'uuid';
import SecondLevelSectionComponent from "../second-level-section-component/second-level-section-component";
import AddSubtitleModalComponent from "../add-subtitle-modal-component/add-subtitle-modal-component";
import { ChildTypeEnum } from "../../enums/child-type-enum";

interface IndividualSectionProps {
    section: FirstLevelSectionModel
}

const FirstLevelSectionComponent:FC<IndividualSectionProps> = ({section}) => {

    const dispatch = useAppDispatch();
    
    const [title, setTitle] = useState<string>(section.title.titleText);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [deployOptions, setDeployOptions] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showAddChildModal, setShowAddTitleModal] = useState<boolean>(false);
    const [showAddSubtitleModal, setShowAddSubtitleModal] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    //escucha cuando el input de titulo se monta en el dom y hace focus en el
    useEffect(() => {
        if(inputRef.current) inputRef.current!.focus();
    })

    //se ejecuta cuando se hace click sobr el titulo existente
    const handleTitleClick = () => {
        setIsEditing(true);
    }

    //se ejecuta al hacer blur sobre el input de titulo
    const handleInputBlur = (e: any) => {
        setIsEditing(false);
        if(e.target.value.trim() !== "") {
           setTitle(e.target.value);
           dispatch(updateTitleText({id: section.positionid, newTitle: e.target.value}))
        }
    }

    //se ejecuta al oprimir enter en el input de titulo
    const handleInputKeyPress = (e: any) => {
        if(e.charCode === 13) setIsEditing(false);
        if(e.target.value.trim() !== "" && e.charCode === 13) {
           setTitle(e.target.value);
           dispatch(updateTitleText({id: section.positionid, newTitle: e.target.value}))
        }
    }

    //se ejecuta cuando se hace click en el boton de opciones de cada titulo
    const handleShowOptionsClick = () => {
        if(deployOptions) setDeployOptions(false);
        else setDeployOptions(true);

        dispatch(updateChildrenToHiglight(null));
    }

    //Se ejecuta cuando se hace click por fuera del titulo
    const handleClickOutsideTitle = () => {
        setDeployOptions(false);
    }

    //se ejecuta cuando se hace click en el boton de eliminar de cada uno de los titulos
    const handleDeleteButtonClick = () => {
        dispatch(updateSectionIdToDelete(section.positionid));
        dispatch(updateShowDeleteTitleWarningModal(true));
    }

    //se ejecuta cuando se hace click en el boton editar de cada titulo
    const handleEditButtonClick = () => {
        setShowEditModal(true);
    }

    //se ejecuta cada vez que se hace cliec en la opción elemento de cada titulo
    const handleAddElementButtonClick = () => {
        setShowAddTitleModal(true);
    }
    
    return (
        <div className="first-level-section">

            <ClickAwayListener onClickAway={handleClickOutsideTitle}>
                <div className="first-level-section__title-container">

                    <div className="first-level-section__title-bar">
                        <div className="first-level-section__position-container"><p>{DocumentBuildService.convertIntToRoman(section.positionid + 1)}</p></div>
                        
                        { !isEditing && <h1 onClick={handleTitleClick} className="first-level-section__title">{title}</h1>}
                        { isEditing && <input ref={inputRef} className="first-level-section__input-title" type="text" onKeyPress={handleInputKeyPress} onBlur={handleInputBlur}/>}

                        <div className="first-level-section__options-container">

                            {   
                                deployOptions &&
                                <div className="first-level-section__options-buttons">

                                    <Tooltip title="Agregar elemento" placement="top" arrow>
                                        <IconButton onClick={handleAddElementButtonClick}>
                                            <AddCircleIcon sx={{color: "white", fontSize: "31px"}} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Editar título" placement="top" arrow>
                                        <IconButton onClick={handleEditButtonClick}>
                                            <EditIcon sx={{color: "white", fontSize: "31px"}} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Eliminar título" placement="top" arrow>
                                        <IconButton onClick={handleDeleteButtonClick}>
                                            <DeleteIcon sx={{color: "white", fontSize: "31px"}} />
                                        </IconButton>
                                    </Tooltip>

                                </div>
                            }

                            <IconButton sx={{"background": "#813899"}} onClick={handleShowOptionsClick}>
                                <MenuIcon sx={{"color": "white"}}/>
                            </IconButton>
                        </div>

                    </div>

                    {   
                        section.title.tooltip &&
                        <div className="first-level-section__tooltip-container">
                            <Tooltip title={section.title.tooltipText} arrow>
                            <IconButton>
                                <HelpOutlineIcon />
                            </IconButton>
                            </Tooltip>
                        </div>
                    }

                </div>
            </ClickAwayListener>

            {
                showEditModal && <EditTitleModalComponent
                positionId={section.positionid} 
                titleText={section.title.titleText}
                tooltip={section.title.tooltip}
                tooltipText={section.title.tooltipText}
                setShowModal={setShowEditModal}
                />
            }

            {
                showAddChildModal && <AddTitleChildModalComponent
                setShowModal={setShowAddTitleModal}
                setShowAddSubtitleModal={setShowAddSubtitleModal}
                firstLevelParentPositionId={section.positionid}
                parentText={section.title.titleText}
                secondLevelParentPositionId={null}
                selfPositionId={DocumentBuildService.getFirstLevelchildSelfPosition(section.positionid)}
                />
            }

            {
                showAddSubtitleModal && <AddSubtitleModalComponent 
                setShowAddSubtitleModal={setShowAddSubtitleModal}
                firstLevelParentPositionId={section.positionid}
                selfPositionId={DocumentBuildService.getFirstLevelchildSelfPosition(section.positionid)}
                />
            }

            {   
                section.children.length > 0 &&
                <div className="first-level-section__children-container">
                    {
                        section.children.map((child: Paragraph | SecondLevelSectionModel) => {
                            switch(child.childType){
                                case ChildTypeEnum.paragraph:
                                    return <FirstLevelParagraphComponent paragraph={child} key={uuidv4()}/>
                                case ChildTypeEnum.subtitle:
                                    return <SecondLevelSectionComponent subtitle={child} key={uuidv4()}/>
                            }
                        })
                    }
                </div>
            }

        </div>
    )
}

export default FirstLevelSectionComponent;