import { Dialog, DialogTitle } from "@mui/material";

import React, { FC } from "react";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getuserListDashboardAdminThunk } from "../../redux-state/thunks/get-user-list-dashboard-admin-thunk";
import "./succes-decline-advice-modal.scss"

interface SuccesDeclineAdviceProps {
    messageRequestText: string;
    setShowSuccesDeclineModal: any;
    token:string;
}

//Implementa modal de informacion del estado del usuario
const SuccesDeclineAdviceModal: FC<SuccesDeclineAdviceProps> = ({ messageRequestText, setShowSuccesDeclineModal, token }) => {

     const { statusUser: statusUserDisabled } = useAppSelector((state) => state.disabledUserDashboardAdminReducer.dashboardAdminUserStatusModelResponseData);

    const handleAceptButtonClick = async () => {
        setShowSuccesDeclineModal(false)
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle sx={{ background: "#D3A237", color: "white" }}>Información</DialogTitle>

            <div className="succes-decline-modal">
                <div className="succes-decline-modal__message">

                    {
                        statusUserDisabled === AsyncStatusEnum.loading && (<div className="spinner"></div>)
                    }
                    
                    {statusUserDisabled === AsyncStatusEnum.completed  && messageRequestText}
                     </div>

                <div className="succes-decline-modal__button-bar">
                    <button onClick={handleAceptButtonClick} className="succes-decline-modal__forward-button">Aceptar</button>
                </div>

            </div>
        </Dialog>
    )
}

export default SuccesDeclineAdviceModal