import React, { FC, useEffect, useRef, useState } from "react";
import { Paragraph, SecondLevelSectionModel } from "../../redux-state/models/first-level-section-model";
import "./first-level-paragraph-component.scss";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DocumentBuildService } from "../../services/document-build-service";
import { changeFirstLevelChildrenDownwardOrder, changeFirstLevelChildrenUpwardOrder, updateChildrenToHiglight, updateParagraphMetadata, updateSecondLevelChildrenToHiglight } from "../../redux-state/reducers/document-build-reducer";
import DeleteParagraphWarningModalComponent from "../delete-paragraph-warning-modal-component/delete-paragraph-warning-modal-component";
import { useNavigate } from "react-router-dom";
import { feedPreviewChildren, updateBuildType } from "../../redux-state/reducers/paragraph-build-reducer";
import { ParagraphBuildTypeEnum } from "../../enums/paragraph-build-type-enum";
import { ParentTypeEnum } from "../../enums/parent-type-enum";

interface FirstLevelParagraphProps {
    paragraph: Paragraph | SecondLevelSectionModel
}

const FirstLevelParagraphComponent:FC<FirstLevelParagraphProps> = ({paragraph}) => {

    const navigate = useNavigate();

    const firstLevelSections = useAppSelector(state => state.documentBuildReducer.firstLevelSections); 
    const firstLevelChildrenToHiglight = useAppSelector(state => state.documentBuildReducer.firstLevelChildrenToHiglight); 
    const dispatch = useAppDispatch();

    const [showDeleteParagraph, setShowDeleteParagraph] = useState<boolean>(false);

    const firstLevelParagraphRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(firstLevelChildrenToHiglight?.childrenId === paragraph.selfPositionId && firstLevelChildrenToHiglight?.parentPositionId === paragraph.firstLevelParentPositionId) {
            firstLevelParagraphRef.current!.style.background = "#9bd2dd";
        }
    }, []);

    //se ejecuta cuando se hace click en la flecha hacia arriba del parrafo
    const handleArrowUpClick = () => {
        
        //cambiando y actualizando la posición de los párrafos
        dispatch(changeFirstLevelChildrenUpwardOrder({firstLevelParentPositionId: paragraph.firstLevelParentPositionId, selfPositionId: paragraph.selfPositionId}));
        dispatch(updateChildrenToHiglight({childrenId: paragraph.selfPositionId - 1, parentPositionId: paragraph.firstLevelParentPositionId}));
        dispatch(updateSecondLevelChildrenToHiglight(null));
    }

    //se ejecuta cuando se hace click en la flecha hacia abajo del parrafo
    const handleArrowDownClick = () => {

        //cambiando y actualizando la posición de los párrafos
        dispatch(changeFirstLevelChildrenDownwardOrder({firstLevelParentPositionId: paragraph.firstLevelParentPositionId, selfPositionId: paragraph.selfPositionId}));
        dispatch(updateChildrenToHiglight({childrenId: paragraph.selfPositionId + 1, parentPositionId: paragraph.firstLevelParentPositionId}));
        dispatch(updateSecondLevelChildrenToHiglight(null));
    }

    //se ejecuta cuando se hace click en cualquier parte fuera del parrafo
    const handleClickOutsideParagraph = () => {
        firstLevelParagraphRef.current!.style.background = "none";
        dispatch(updateChildrenToHiglight(null));
        dispatch(updateSecondLevelChildrenToHiglight(null));
    }

    const handleDeleteButtonClick = () => {
        setShowDeleteParagraph(true);
    }

    const handleEditButtonClick = () => {
        dispatch(updateParagraphMetadata({
            parentType: paragraph.parentType,
            parentText: firstLevelSections[paragraph.firstLevelParentPositionId].title.titleText,
            firstLevelParentPositionId: paragraph.firstLevelParentPositionId,
            secondLevelParentPositionId: paragraph.parentType === ParentTypeEnum.title ? null : Number(paragraph.secondLevelParentPositionId),
            selfPositionId: paragraph.selfPositionId
        }));
        dispatch(feedPreviewChildren(paragraph.content));
        dispatch(updateBuildType(ParagraphBuildTypeEnum.edition));
        dispatch(updateChildrenToHiglight({childrenId: paragraph.selfPositionId, parentPositionId: paragraph.firstLevelParentPositionId}));
        dispatch(updateSecondLevelChildrenToHiglight(null));
        navigate("/paragraph-build");
    }

    return (
        <ClickAwayListener onClickAway={handleClickOutsideParagraph}>
            <div className="first-level-paragraph" ref={firstLevelParagraphRef}>

                <div className="first-level-paragraph__arrow-container">

                    { DocumentBuildService.showUpArrowInChild(paragraph) && <button className="first-level-paragraph__arrow" onClick={handleArrowUpClick} ><KeyboardArrowUpIcon sx={{color: "#565656", "&:hover": {color: "white"}}}/></button>}
                    { DocumentBuildService.showDownArrowInChild(firstLevelSections, paragraph) && <button className="first-level-paragraph__arrow" onClick={handleArrowDownClick}><KeyboardArrowDownIcon sx={{color: "#565656", "&:hover": {color: "white"}}}/></button>}

                </div>

                <div className="first-level-paragraph__main">
                    {paragraph.content.map(element => element)}
                </div>

                <div className="first-level-paragraph__button-bar">
                    <button className="first-level-paragraph__edit-button" onClick={handleEditButtonClick}><ModeEditOutlineOutlinedIcon sx={{color: "white"}}/></button>
                    <button className="first-level-paragraph__delete-button" onClick={handleDeleteButtonClick}><DeleteOutlineOutlinedIcon sx={{color: "white"}}/></button>
                </div>

                <DeleteParagraphWarningModalComponent 
                setShowDeleteParagraph={setShowDeleteParagraph} 
                paragraph={paragraph}
                showDeleteParagraph={showDeleteParagraph}
                />

            </div>
        </ClickAwayListener>
    )
}

export default FirstLevelParagraphComponent;