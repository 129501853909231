import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../commons/enums/async-status-enum";
import { TemplateInformationModel } from "../../commons/redux-state/models/template-information-model";
import { TemplateViewInitialStateModel } from "../models/template-view-Initial-state-model";

export const templateInformationInitialState: TemplateViewInitialStateModel = {
    templateViewData: {
        status: AsyncStatusEnum.idle,
        templateInformation: null,
        error: {
            error: false,
            message: "",
        }
    }
};

  //dashboard reducer, para mostrar documentos para el usuario
  export const templateInformationReducer = createSlice({
    name: "template-information-reducer",
    initialState: templateInformationInitialState,
    reducers: {
      cleanTemplateInformation:(state, action: PayloadAction<null>) => {
        state.templateViewData.templateInformation = action.payload},
      updateTemplateInformation:(state, action: PayloadAction<TemplateInformationModel>) => {
      state.templateViewData.templateInformation = action.payload;
    }}
  });

  export const { updateTemplateInformation, cleanTemplateInformation } = templateInformationReducer.actions;
  export default templateInformationReducer.reducer;
  

