import React, { FC, useRef } from "react";
import { TitleSubtitleModel } from "../../redux-state/models/title-subtitle-model";
import "./edit-title-modal-component.scss";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Checkbox, Dialog, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import { EditTitleModalModel } from "../../redux-state/models/edit-title-modal-model";
import { useAppDispatch } from "../../../redux/hooks";
import { updateAllTitleData } from "../../redux-state/reducers/document-build-reducer";

const EditTitleModalComponent:FC<EditTitleModalModel> = ({titleText, tooltip, tooltipText, setShowModal, positionId}) => {

    /* Variables de colores */
    const blue = "#004571";
    const purple = "#82389A";

    const dispatch = useAppDispatch();
    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: TitleSubtitleModel = {
        titleText: titleText,
        tooltip: tooltip,
        tooltipText: tooltipText
    }

    const validationSchema = yup.object({
        titleText: yup.string()
        .max(300, "El nombre del título debe ser maximo de 300 caracteres")
        .min(1, "El nombre del título debe ser mínimo de 1 caracteres")
        .required("El nombre del título es requerido"),
        tooltip: yup.boolean()
        .required(""),
        tooltipText: yup.string()
        .max(300, "El texto debe ser maximo de 300 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres"),
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  

            
        
            dispatch(updateAllTitleData({
                positionId: positionId,
                titleText: form.values.titleText,
                tooltip: form.values.tooltip,
                tooltipText: form.values.tooltipText
            }));
            setShowModal(false);

            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    const handleCancelButtonClick = () => {
        form.resetForm();
        setShowModal(false);
    }

    return (
    <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle sx={{background: blue, color: "white"}}>Modifique la información para editar del título</DialogTitle>

        <div className="edit-title-modal">
            
            <form onSubmit={form.handleSubmit} ref={formRef}>

                <div>
                    <TextField 
                        variant="outlined"
                        label="Nombre del título"
                        style = {{background: "white"}}
                        id="titleText"
                        name="titleText"
                        defaultValue={form.values.titleText}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.errors.titleText && form.touched.titleText && <p className="validation-error">{form.errors.titleText}</p>}
                </div>

                <FormControlLabel control={<Checkbox id="tooltip" name="tooltip" checked={form.values.tooltip} onChange={form.handleChange}/>} label="Desea añadir autoayuda?" />

                <div>
                    <TextField 
                        variant="outlined"
                        label="Ingerse texto de la autoayuda"
                        style = {{background: "white"}}
                        id="tooltipText"
                        name="tooltipText"
                        defaultValue={form.values.tooltipText}
                        disabled={!form.values.tooltip}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.errors.tooltipText && form.touched.tooltipText && <p className="validation-error">{form.errors.tooltipText}</p>}
                </div>

                <div className="edit-title-modal__actions-bar">
                    <button className="edit-title-modal__back-button" onClick={handleCancelButtonClick} type="button">Cancelar</button>
                    <button className="edit-title-modal__forward-button" type="submit">Guardar</button>
                </div>
                

                </form>

            </div>
      
    </Dialog>
    )
}

export default EditTitleModalComponent;