import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateFirstLevelSections, updateShowAddTitleModal } from "../../redux-state/reducers/document-build-reducer";
import { DocumentBuildService } from "../../services/document-build-service";
import FirstLevelSectionComponent from "../first-level-secion-component/first-level-secion-component";
import AddIcon from '@mui/icons-material/Add';
import "./main-stack-container-component.scss";
import { Tooltip } from "@mui/material";
import Context from "../../../Context";
import { DocumentTemplateAdapterToFillOutService } from "../../../document-template-to-fill-out/services/document-template-adapter-to-fill-out-service";
import { getDocumentTemplateAdapterToFillThunk } from "../../../document-template-to-fill-out/redux-state/thunk/get-document-template-adapter-to-fill-out";

const MainStackContainerComponent = () => {
    const { templateInformation } = useAppSelector(state => state.templateInformationReducer.templateViewData);
    const firstLevelSections = useAppSelector(state => state.documentBuildReducer.firstLevelSections);
    const dispatch = useAppDispatch();

    const { isAuth: token } = useContext(Context);
    const navigate = useNavigate();

    const handleAddButtonClick = () => {
        dispatch(updateShowAddTitleModal(true));
    }

    const handleSaveButtonClick = () => {
        const documentTemplateTransformToFill = DocumentTemplateAdapterToFillOutService.getDocumentTemplateTransformedToFillOut(firstLevelSections, templateInformation?.data.documentTemplateId!)
        if (documentTemplateTransformToFill) {
            sessionStorage.setItem('estructureChangeFlag', 'true');
            dispatch(getDocumentTemplateAdapterToFillThunk({ documentTemplateTransformToFill, token }))
            navigate("/new-document/create")
        }
    }

    return (
        <main className="main-stack">

            {firstLevelSections.map(section => <FirstLevelSectionComponent section={section} key={DocumentBuildService.generateRandomId()} />)}

            <div className="main-stack__add-button-container">
                <Tooltip title="Agregar nuevo título" placement="top" arrow>
                    <button onClick={handleAddButtonClick}><AddIcon sx={{ color: "white", fontSize: "40px" }} /></button>
                </Tooltip>
            </div>

            <div className="main-stack__action-buttons-bar">
                <button onClick={() => navigate("/new-document")} className="main-stack__cancel-button">Cancelar</button>
                <button onClick={handleSaveButtonClick} className="main-stack__save-button">Guardar</button>
            </div>

        </main>


    )
}

export default MainStackContainerComponent;

