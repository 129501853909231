import React from "react";
import { Dialog, DialogTitle } from "@mui/material";
import "./delete-title-warning-modal-component.scss"
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteFirstLevelSection, updateSectionIdToDelete, updateShowDeleteTitleWarningModal } from "../../redux-state/reducers/document-build-reducer";

const DeleteTitleWarningModalComponent = () => {

    const showDeleteTitleWarningModal = useAppSelector(state => state.documentBuildReducer.showDeleteTitleWarningModal);
    const sectionIdToDelete = useAppSelector(state => state.documentBuildReducer.sectionIdToDelete);
    const dispatch = useAppDispatch();

    const handleCancelButtonClick = () => {
        dispatch(updateShowDeleteTitleWarningModal(false));
        dispatch(updateSectionIdToDelete(null));
    }

    const handleDeleteButtonClick = () => {
        const id: number = Number(sectionIdToDelete);
        dispatch(updateShowDeleteTitleWarningModal(false));
        dispatch(deleteFirstLevelSection(id));
        dispatch(updateSectionIdToDelete(null));
    }

    return (
     <Dialog open={showDeleteTitleWarningModal} maxWidth={"sm"} fullWidth={true}>
         <DialogTitle sx={{background: "#D3A237", color: "white"}}>Atención!</DialogTitle>

         <div className="delete-title-modal">

            <div className="delete-title-modal__message">
                <WarningAmberIcon sx={{fontSize: "70px", color: "#f0ad4e"}}/>
                <p>Está seguro que desea eliminar este titulo y todo su contenido?</p>
            </div>

            <div className="delete-title-modal__button-bar">
                <button className="delete-title-modal__back-button" onClick={handleCancelButtonClick}>Cancelar</button>
                <button className="delete-title-modal__forward-button" onClick={handleDeleteButtonClick}>Eliminar</button>
            </div>

         </div>
     </Dialog>
    )
}

export default DeleteTitleWarningModalComponent;