import React, { FC } from "react";
import { Dialog, DialogTitle } from "@mui/material";
import "./add-title-child-modal-component.scss";
import SegmentIcon from '@mui/icons-material/Segment';
import TitleIcon from '@mui/icons-material/Title';
import { useAppDispatch } from "../../../redux/hooks";
import { updateParagraphMetadata } from "../../redux-state/reducers/document-build-reducer";
import { ParentTypeEnum } from "../../enums/parent-type-enum";
import { useNavigate } from 'react-router-dom'

interface AddTitleChildModalProps {
    setShowModal: any;
    setShowAddSubtitleModal: any;
    firstLevelParentPositionId: number;
    parentText: string;
    secondLevelParentPositionId: number | null;
    selfPositionId: number;
}

const AddTitleChildModalComponent:FC<AddTitleChildModalProps> = ({setShowModal, firstLevelParentPositionId, parentText, secondLevelParentPositionId, selfPositionId, setShowAddSubtitleModal}) => {

    /* Variables de colores */
    const blue = "#004571";
    const purple = "#82389A";

    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const handleCancelButtonClick = () => {
        setShowModal(false);
    }

    const handleAddSubtitleClick = () => {
        setShowAddSubtitleModal(true);
    }

    const handleAddParagraphClick = () => {
        dispatch(updateParagraphMetadata({
            parentType: ParentTypeEnum.title,
            parentText,
            firstLevelParentPositionId,
            secondLevelParentPositionId,
            selfPositionId,
        }));
        setShowModal(false);
        navigate('/paragraph-build');
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
            <DialogTitle sx={{background: blue, color: "white"}}>Seleccione el elemento que desea agregar</DialogTitle>

            <div className="add-title-child-modal">
                <div className="add-title-child-modal__element-bar">
                    <button className="add-title-child-modal__button" onClick={handleAddSubtitleClick}><TitleIcon sx={{fontSize: "45px", color: "white"}}/>Subtítulo</button>
                    <button className="add-title-child-modal__button" onClick={handleAddParagraphClick}><SegmentIcon sx={{fontSize: "45px", color: "white"}}/>Párrafo</button>
                </div>
                <div className="add-title-child-modal__action-bar">
                    <button className="add-title-child-modal__cancel-button" onClick={handleCancelButtonClick}>Cancelar</button>
                </div>
            </div>
        
        </Dialog>
    )

}

export default AddTitleChildModalComponent;