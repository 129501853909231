import { DocumentTemplateResponseModel } from "../../commons/redux-state/models/document-template-response-model";
import { DocumentTemplateAdapterToFillRequestModel, Content, Child, Title, ParagraphContent } from '../redux-state/models/document-template-adapter-to-fill-out-request-model';
import { FirstLevelSectionModel, Paragraph, SecondLevelSectionModel } from '../../lawyer/redux-state/models/first-level-section-model';
import { ParentTypeEnum } from '../../lawyer/enums/parent-type-enum';
import { ChildTypeEnum } from '../../lawyer/enums/child-type-enum';
import { ElementTypeEnum } from "../../lawyer/enums/element-type-enum";
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";
export class DocumentTemplateAdapterToFillOutService {
  /**
   * Metodo para obtener una plantilla para el diligenciamiento despues de editarla
   * @returns Objeto de tipo 
   */
  public static async getDocumentTemplateAdapterToFillOut(documentTemplateToFillOut: DocumentTemplateAdapterToFillRequestModel, token: string): Promise<DocumentTemplateResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}DocumentTemplate/GetDocumentTemplateFromAdapter`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(documentTemplateToFillOut)
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const data: DocumentTemplateResponseModel = await response.json();
      return data;
    } catch (error) {
      return null
    }
  }

  //Genera un objeto de tipo DocumentTemplateAdapterToFillRequestModel desde un objeto de tipo FirstLevelSectionModel
  static getDocumentTemplateTransformedToFillOut(firstLevelSections: FirstLevelSectionModel[], documentTemplateId: number) {

    const firstLevelSectionsArray: DocumentTemplateAdapterToFillRequestModel = {
      documentTemplateId: documentTemplateId,
      content: this.generateContentArray(firstLevelSections)
    }
    return firstLevelSectionsArray
  }

  //Genera un arreglo de tipo Content desde un arrelgo de tipo FirstLevelSectionModel
  static generateContentArray(firstLevelSections: FirstLevelSectionModel[]) {
    const contentArray: Array<Content> = [];
    firstLevelSections.map((sectionElement: FirstLevelSectionModel, index) => {

      const title: Title = {
        titleText: sectionElement.title.titleText,
        tooltip: sectionElement.title.tooltip || false,
        tooltipText: sectionElement.title.tooltipText || ""
      }

      const content: Content = {
        positionId: sectionElement.positionid,
        title: title,
        children: this.generateChildrenArray(sectionElement.children)
      }

      contentArray.push(content)
    })
    return contentArray
  }

  //Genera un arreglo de tipo Child desde un arrelgo tipo Paragraph o SecondLevelSectionModel 
  static generateChildrenArray(childrenArray: Array<Paragraph | SecondLevelSectionModel>) {

    const childArray: Array<Child> = [];
    const clauseIdRandom = Math.floor(Math.random() * (9999 -1 + 1) + 0);
    const constClauseRandom = clauseIdRandom;
    childrenArray.map((childElement: Paragraph | SecondLevelSectionModel, index) => {

      switch (childElement.childType) {
        case ChildTypeEnum.paragraph:
          const paragraphChild: Child = {
            firstLevelParentPositionId: childElement.firstLevelParentPositionId,
            secondLevelParentPositionId: childElement.secondLevelParentPositionId,
            selfPositionId: constClauseRandom + index,
            parentType: childElement.parentType === ParentTypeEnum.title ? ParentTypeEnum.title : ParentTypeEnum.subTitle,
            childType: ChildTypeEnum.paragraph,
            title: null,
            paragraphContent: this.generateParagraphContentArray(childElement.content),
            subtitleContent: null,
          }
          
          return childArray.push(paragraphChild)
        case ChildTypeEnum.subtitle:

          const title = {
            titleText: childElement.title.titleText,
            tooltip: childElement.title.tooltip || false,
            tooltipText: childElement.title.titleText || "",
          }

          const subtitleChild: Child = {
            firstLevelParentPositionId: childElement.firstLevelParentPositionId,
            secondLevelParentPositionId: childElement.secondLevelParentPositionId,
            selfPositionId: constClauseRandom + index,
            parentType: ParentTypeEnum.title,
            childType: ChildTypeEnum.subtitle,
            title: title,
            paragraphContent: null,
            subtitleContent: this.generateChildrenArray(childElement.content),
          }
          return childArray.push(subtitleChild)
      }
      
    })

    return childArray
  }

  //Transforma un arreglo de elementos react a un arreglo de tipo ParagraphContent
  static generateParagraphContentArray(paragraphContent: any[]) {
    const paragraphContentArray: Array<ParagraphContent> = [];
    paragraphContent.map((reactElement: any) => {

      switch (reactElement.props.elementType) {
        case ElementTypeEnum.boldText:
          const boldTextElement: ParagraphContent = {
            elementType: ElementTypeEnum.boldText,
            innerText: reactElement.props.innerText,
            label: null,
            tooltip: null,
            tooltipText: null,
            dropdownOptions: null,
            required: null,
            defaultValue: null,
          }
          return paragraphContentArray.push(boldTextElement);
        case ElementTypeEnum.dateField:
          const dateFieldElement: ParagraphContent = {
            elementType: ElementTypeEnum.dateField,
            innerText: null,
            label: reactElement.props.label,
            tooltip: reactElement.props.tooltip,
            tooltipText: reactElement.props.tooltipText,
            dropdownOptions: null,
            required: reactElement.props.required,
            defaultValue: null,
          }
          return paragraphContentArray.push(dateFieldElement);
        case ElementTypeEnum.lineBreak:
          const lineBreakElement: ParagraphContent = {
            elementType: ElementTypeEnum.lineBreak,
            innerText: null,
            label: null,
            tooltip: null,
            tooltipText: null,
            dropdownOptions: null,
            required: null,
            defaultValue: null,
          }
          return paragraphContentArray.push(lineBreakElement);
        case ElementTypeEnum.paragraph:
          const paragraphElement: ParagraphContent = {
            elementType: ElementTypeEnum.paragraph,
            innerText: reactElement.props.innerText,
            label: null,
            tooltip: null,
            tooltipText: null,
            dropdownOptions: null,
            required: null,
            defaultValue: null,
          }
          return paragraphContentArray.push(paragraphElement);
        case ElementTypeEnum.selectField:
          const selectFieldElement: ParagraphContent = {
            elementType: ElementTypeEnum.selectField,
            innerText: null,
            label: reactElement.props.label,
            tooltip: reactElement.props.tooltip,
            tooltipText: reactElement.props.tooltipText,
            dropdownOptions: reactElement.props.dropdownOptions,
            required: reactElement.props.required,
            defaultValue: null,
          }
          return paragraphContentArray.push(selectFieldElement);
        case ElementTypeEnum.textBox:
          const textBoxElement: ParagraphContent = {
            elementType: ElementTypeEnum.textBox,
            innerText: null,
            label: reactElement.props.label,
            tooltip: reactElement.props.tooltip,
            tooltipText: reactElement.props.tooltipText,
            dropdownOptions: null,
            required: reactElement.props.required,
            defaultValue: reactElement.props.defaultValue,
          }
          return paragraphContentArray.push(textBoxElement);
        case ElementTypeEnum.textField:
          const textFieldElement: ParagraphContent = {
            elementType: ElementTypeEnum.textField,
            innerText: null,
            label: reactElement.props.label,
            tooltip: reactElement.props.tooltip,
            tooltipText: reactElement.props.tooltipText,
            dropdownOptions: null,
            required: reactElement.props.required,
            defaultValue: reactElement.props.defaultValue,
          }
          return paragraphContentArray.push(textFieldElement);
        case ElementTypeEnum.signature:
          const signatureElement = {
            elementType: ElementTypeEnum.signature,
            innerText: null,
            label: reactElement.props.label,
            tooltip: null,
            tooltipText: null,
            dropdownOptions: null,
            required: true,
            defaultValue: null
          }
          return paragraphContentArray.push(signatureElement)
      }
    })

    return paragraphContentArray
  }

}