import { DocumentStatusModel } from '../redux-state/models/document-status-model';
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";

export class DocumentStatusService {
 /**
   * Para obtener estados de un documento
   * @param token {token de acceso del usuario}
   * @returns todas estados de un documento
   */

  static async getAllDocumentStatus(token: string): Promise<DocumentStatusModel|null> {
    try 
    {
        const response = await fetch(`${process.env.REACT_APP_API_URL}DocumentStatus/getAll`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const documentStatus: DocumentStatusModel = await response.json();
      return documentStatus;
    } catch (error) {
        return null
    }
  }
}