import { get } from "./Api";
import { DocumentTemplateResponseModel } from "../commons/redux-state/models/document-template-response-model";
import axios from "axios";
import { ValidateUserSessionService } from "./ValidateUserSessionService";

export class DocumentTemplateHttpService {
  /**
   * Metodo para obtener todas las plantillas
   * @param token {token de acceso del usuario}
   * @param queryParams {Parametros opcionales para la consulta}
   * @returns Lista de todas las plantillas
   */
  static getAll(token: string, queryParams = {}) {
    return get(
      `${process.env.REACT_APP_API_URL}DocumentTemplate/GetAll`,
      token,
      queryParams
    );
  }

  /**
   * Metodo para obtener una unica plantilla dado su Id
   * @param id {Id de la plantilla}
   * @param token {token de acceso del usuario}
   * @returns Detalle de una plantilla
   */
  static getById(id: number, token: string) {
    return get(
      `${process.env.REACT_APP_API_URL}DocumentTemplate/details/${id}`,
      token
    );
  }

  /**
   * Metodo para obtener plantillas dada una Sub Categoria
   * @param subCategoryId {Id de la Sub Categoria}
   * @param token {token de acceso del usuario}
   * @returns Lista de plantillas filtrada por el Id de la sub categoria
   */
  static getDocumentsTemplatesXsubCategoryById(
    subCategoryId: number,
    token: string
  ) {
    return get(
      `${process.env.REACT_APP_API_URL}DocumentTemplate/getAllBySubCategoryId?subCategoryId=${subCategoryId}`,
      token
    );
  }

  /**
   * Metodo para obtener plantillas completas con secciones, clausulas y campos
   * @param documentTemplateId {Id de la plantillas}
   * @param token {token de acceso del usuario}
   * @returns Plantilla completa con todas sus secciones, clausulas y campos
   */
  static async getDocumentTemplatePreview(documentTemplateId: number,token: string):Promise<DocumentTemplateResponseModel> {
    const response = await fetch(`${process.env.REACT_APP_API_URL}DocumentTemplate/previewDocument?documentTemplateId=${documentTemplateId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //validate token with status 401
    ValidateUserSessionService.handleError(response.status);
    const documentTemplate:DocumentTemplateResponseModel = await response.json();
    return documentTemplate;    
  }
}
