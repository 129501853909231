import { CurrentDocumentPreviewResponseModel } from "../redux-state/models/current-document-preview-response-model";
import { DocumentTemplateResponseModel, Section } from "../redux-state/models/document-template-response-model";
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";
export class DocumentTemplateService {

  /**
   * Metodo para obtener plantillas completas con secciones, clausulas y campos
   * @param documentTemplateId {Id de la plantillas}
   * @param token {token de acceso del usuario}
   * @returns Plantilla completa con todas sus secciones, clausulas y campos
   */

  static async getDocumentTemplateInitial(documentTemplateId: number, token: string): Promise<DocumentTemplateResponseModel|null> {
    try 
    {
        const response = await fetch(`${process.env.REACT_APP_API_URL}DocumentTemplate/previewDocument?documentTemplateId=${documentTemplateId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const documentTemplate: DocumentTemplateResponseModel = await response.json();
      return documentTemplate;
    } catch (error) {
        return null
    }
  }


   /**
   * Metodo para obtener secciones completas de un documento
   * @param documentTemplate
   * @returns secciones de un documento
   */
  static getSectionsArrays(documentTemplate:DocumentTemplateResponseModel | null):Section[]{
   const result = documentTemplate!.data.section!.map((u: any) => {
      return {
        sectionId: u.sectionId,
        sectionNumber: u.sectionNumber,
        sectionTitle: u.sectionTitle,
        clause: u.clause,
        section: u.section,
      };
    })
    return result
  }


    /**
   * Metodo para obtener la previsualizacion de un documento
   * @param documentTemplateId {Id de la plantillas}
   * @param token {token de acceso del usuario}
   * @returns Previsualizacion de un documento
   */

    static async getDocumentPreview(documentId: number, token: string): Promise<CurrentDocumentPreviewResponseModel | null> {
      try 
      {
          const response = await fetch(`${process.env.REACT_APP_API_URL}Document/previewDocument?documentId=${documentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //validate token with status 401
        ValidateUserSessionService.handleError(response.status);
        const documentPreview:CurrentDocumentPreviewResponseModel  = await response.json();
        return documentPreview;
      } catch (error) {
          return null
      }
    }

}
