import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { ChildTypeEnum } from "../../enums/child-type-enum";
import { DocumentBuildTypeEnum } from "../../enums/document-build-type-enum";
import { ParentTypeEnum } from "../../enums/parent-type-enum";
import { DocumentBuildInitialStateModel } from "../models/document-build-initial-state-model";
import { EditSubtitleModalModel } from "../models/edit-subtitle-modal-model";
import { EditTitleModalModel } from "../models/edit-title-modal-model";
import { FirstLevelSectionModel, Paragraph, SecondLevelSectionModel } from "../models/first-level-section-model";
import { ParagraphCreationMetadataModel } from "../models/paragrah-creation-metadata-model";
import { SubtitleChangePayloadModel } from "../models/subtitle-change-payload-model";
import { TitleChangePayloadModel } from "../models/Title-change-payload-model";


export const documentBuildInitialState: DocumentBuildInitialStateModel = {
    documentBuildType: DocumentBuildTypeEnum.newDocument,
    showAddTitleModal: false,
    showDeleteTitleWarningModal: false,
    sectionIdToDelete: null,
    firstLevelSections: [],
    paragraphMetadata: null,
    firstLevelChildrenToHiglight: null,
    SecondLevelChildrenToHiglight: null
};

export const documentBuildReducer = createSlice({
  name: "document-build-reducer",
  initialState: documentBuildInitialState,
  reducers: {
    updateDocumentBuildType:(state, action: PayloadAction<DocumentBuildTypeEnum>) => {
      state.documentBuildType = action.payload;
    },
    updateShowAddTitleModal:(state, action: PayloadAction<boolean>) => {
        state.showAddTitleModal = action.payload;
    },
    updateShowDeleteTitleWarningModal:(state, action: PayloadAction<boolean>) => {
        state.showDeleteTitleWarningModal = action.payload;
    },
    updateSectionIdToDelete:(state, action: PayloadAction<number | null>) => {
        state.sectionIdToDelete = action.payload;
    },
    updateFirstLevelSections:(state, action: PayloadAction<FirstLevelSectionModel[]>) => {
        state.firstLevelSections = action.payload;
    },
    updateTitleText:(state, action: PayloadAction<TitleChangePayloadModel>) => {
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.id)[0];
        const filteredIndex: number = state.firstLevelSections.indexOf(filteredSection);
        state.firstLevelSections[filteredIndex].title.titleText = action.payload.newTitle;
    },
    updateSubtitleText: (state, action: PayloadAction<SubtitleChangePayloadModel>) => {
        //filtrando index del titulo padre
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.parentPositionId)[0];
        const filteredSectionIndex: number = state.firstLevelSections.indexOf(filteredSection);
        //filtrando index del subtitulo hijo
        const filteredSubtitle: SecondLevelSectionModel | Paragraph = state.firstLevelSections[filteredSectionIndex].children.filter(child => child.selfPositionId === action.payload.selfPositionId)[0];
        const filteredSubtitleIndex: number =  state.firstLevelSections[filteredSectionIndex].children.indexOf(filteredSubtitle);

        state.firstLevelSections[filteredSectionIndex].children[filteredSubtitleIndex].title!.titleText = action.payload.newSubtitle;
    },
    updateAllTitleData:(state, action: PayloadAction<EditTitleModalModel>) => {
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.positionId)[0];
        const filteredIndex: number = state.firstLevelSections.indexOf(filteredSection);
        state.firstLevelSections[filteredIndex].title.titleText = action.payload.titleText;
        state.firstLevelSections[filteredIndex].title.tooltip = action.payload.tooltip;
        state.firstLevelSections[filteredIndex].title.tooltipText = action.payload.tooltipText;
    },
    updateAllSubtitleData:(state, action: PayloadAction<EditSubtitleModalModel>) => {
        //encontrando el index del padre
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.parentPositionId)[0];
        const filteredSectionIndex: number = state.firstLevelSections.indexOf(filteredSection);
        //encontrando el index del subtítulo hijo
        const filteredSubtitle: Paragraph | SecondLevelSectionModel = state.firstLevelSections[filteredSectionIndex].children.filter(child => child.selfPositionId === action.payload.selfPositionId)[0];
        const filteredSubtitleIndex: number = state.firstLevelSections[filteredSectionIndex].children.indexOf(filteredSubtitle);
        //actualizando la data del subtítulo
        state.firstLevelSections[filteredSectionIndex].children[filteredSubtitleIndex].title!.titleText = action.payload.titleText;
        state.firstLevelSections[filteredSectionIndex].children[filteredSubtitleIndex].title!.tooltip = action.payload.tooltip;
        state.firstLevelSections[filteredSectionIndex].children[filteredSubtitleIndex].title!.tooltipText = action.payload.tooltipText;
    },
    deleteFirstLevelSection: (state, action: PayloadAction<number>) => {
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload)[0];
        const filteredIndex: number = state.firstLevelSections.indexOf(filteredSection);
        state.firstLevelSections.splice(filteredIndex, 1);
        
        //Actualiza los ids de posicion de todos los titulos de primer nivel y de sus hijos(parrafos/subitulos)
        for(let i = 0; i < state.firstLevelSections.length; i++){

            const newFirstLevelSection: FirstLevelSectionModel = {
                ...state.firstLevelSections[i],
                positionid: i,
                children: state.firstLevelSections[i].children.map(individualChild => ({ 
                    ...individualChild,
                    firstLevelParentPositionId: i,
                    content: individualChild.content.map(individualParagraph => ({
                        ...individualParagraph,
                        firstLevelParentPositionId: i
                    }))
                })),
            }

            state.firstLevelSections[i] = newFirstLevelSection;
        }
    },
    deleteSecondLevelSection: (state, action: PayloadAction<{firstLevelSectionId: number, selfPositionId: number}>) => {
        //encontrando el index del padre
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.firstLevelSectionId)[0];
        const filteredSectionIndex: number = state.firstLevelSections.indexOf(filteredSection);
        //encontrando el index del subtítulo hijo
        const filteredSubtitle: Paragraph | SecondLevelSectionModel = state.firstLevelSections[filteredSectionIndex].children.filter(child => child.selfPositionId === action.payload.selfPositionId)[0];
        const filteredSubtitleIndex: number = state.firstLevelSections[filteredSectionIndex].children.indexOf(filteredSubtitle);
        
        //borrando el subtítulo seleccionado
        state.firstLevelSections[filteredSectionIndex].children.splice(filteredSubtitleIndex, 1);
        
        //actualizando ids de posición en todos los niveles
        state.firstLevelSections[filteredSectionIndex].children.forEach((child, index) => {

            const newChild: Paragraph | SecondLevelSectionModel = {
                ...child,
                selfPositionId: index,
                content: child.content.map(e => {
                    if(child.childType === ChildTypeEnum.subtitle){
                        return {
                            ...e,
                            secondLevelParentPositionId: index
                        }
                    } else {
                        return e;
                    }
                })
            }

            state.firstLevelSections[filteredSectionIndex].children[index] = newChild;
        });
    },
    updateParagraphMetadata:(state, action: PayloadAction<ParagraphCreationMetadataModel | null>) => {
        state.paragraphMetadata = action.payload;
    },
    addParagraph:(state, action: PayloadAction<{children: any[], metadata: ParagraphCreationMetadataModel}>) => {

        const {firstLevelParentPositionId, parentType, secondLevelParentPositionId, selfPositionId} = action.payload.metadata;
        const children = action.payload.children;
        
        switch(parentType){
            case ParentTypeEnum.title:
                const paragraph: Paragraph = {
                    firstLevelParentPositionId: firstLevelParentPositionId,
                    selfPositionId: selfPositionId,
                    parentType: parentType,
                    childType: ChildTypeEnum.paragraph,
                    content: children,
                }
                state.firstLevelSections[firstLevelParentPositionId].children.push(paragraph);
            break;
            case ParentTypeEnum.subTitle:
                const subtitleParagraph: Paragraph = {
                    firstLevelParentPositionId: firstLevelParentPositionId,
                    secondLevelParentPositionId: Number(secondLevelParentPositionId),
                    selfPositionId: selfPositionId,
                    parentType: parentType,
                    childType: ChildTypeEnum.paragraph,
                    content: children,
                }
                state.firstLevelSections[firstLevelParentPositionId].children[Number(secondLevelParentPositionId)].content.push(subtitleParagraph);
            break;
        }
    },
    changeFirstLevelChildrenUpwardOrder: (state, action: PayloadAction<{firstLevelParentPositionId: number, selfPositionId: number}>) => {
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.firstLevelParentPositionId)[0];
        const filteredIndex: number = state.firstLevelSections.indexOf(filteredSection);
        const currenPosition: number = action.payload.selfPositionId;
        const newDesirePosition: number = action.payload.selfPositionId - 1;

        //inserta el parrafo en el nuevo index
        state.firstLevelSections[filteredIndex].children.splice(newDesirePosition, 0, state.firstLevelSections[filteredIndex].children[currenPosition]);
        //elimina el parrafo de su posición anterior
        state.firstLevelSections[filteredIndex].children.splice(currenPosition + 1, 1);

        //actualizando ids de posición
        state.firstLevelSections[filteredIndex].children.forEach((child, childIndex) => {
            child.selfPositionId = childIndex;

            //si el hijo es un subtítulos Actualiza todos los index y ids de los parrafos que son hijos del subtítulo
            if(child.childType === ChildTypeEnum.subtitle) child.content.forEach((paragraph, ParagraphIndex) => {
                paragraph.selfPositionId = ParagraphIndex;
                paragraph.secondLevelParentPositionId = childIndex;
            })
        });
    },
    changeSecondLevelChildrenUpwardOrder: (state, action: PayloadAction<{firstLevelParentPositionId: number, secondLevelParentPositionId: number, selfPositionId: number}>) => {

        const filteredFirstLevelSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.firstLevelParentPositionId)[0];
        const filteredFirstLevelIndex: number = state.firstLevelSections.indexOf(filteredFirstLevelSection);

        const filteredSecondLevelSection: Paragraph | SecondLevelSectionModel = state.firstLevelSections[filteredFirstLevelIndex].children.filter(child => child.selfPositionId === action.payload.secondLevelParentPositionId)[0];
        const filteredSecondLevelIndex: number = state.firstLevelSections[filteredFirstLevelIndex].children.indexOf(filteredSecondLevelSection);
        
        const currenPosition: number = action.payload.selfPositionId;
        const newDesirePosition: number = action.payload.selfPositionId - 1;

        //inserta el parrafo en el nuevo index
        state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content.splice(newDesirePosition, 0, state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content[action.payload.selfPositionId]);
        //elimina el parrafo de su posición anterior
        state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content.splice(currenPosition + 1, 1);

        //actualizando ids de posición
        state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content.forEach((paragraph, paragraphIndex) => {
            paragraph.selfPositionId = paragraphIndex;
        });
        
    }, 
    changeFirstLevelChildrenDownwardOrder: (state, action: PayloadAction<{firstLevelParentPositionId: number, selfPositionId: number}>) => {
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.firstLevelParentPositionId)[0];
        const filteredIndex: number = state.firstLevelSections.indexOf(filteredSection);
        const currenPosition: number = action.payload.selfPositionId;
        const newDesirePosition: number = action.payload.selfPositionId + 2;

        //inserta el parrafo en el nuevo index
        state.firstLevelSections[filteredIndex].children.splice(newDesirePosition, 0, state.firstLevelSections[filteredIndex].children[currenPosition]);
        //elimina el parrafo de su posición anterior
        state.firstLevelSections[filteredIndex].children.splice(currenPosition, 1);

        //actualizando ids de posición
        state.firstLevelSections[filteredIndex].children.forEach((child, childIndex) => {
            child.selfPositionId = childIndex;

            //si el hijo es un subtítulos Actualiza todos los index y ids de los parrafos que son hijos del subtítulo
            if(child.childType === ChildTypeEnum.subtitle) child.content.forEach((paragraph, ParagraphIndex) => {
                paragraph.selfPositionId = ParagraphIndex;
                paragraph.secondLevelParentPositionId = childIndex;
            })
        });
    },
    changeSecondLevelChildrenDownwardOrder: (state, action: PayloadAction<{firstLevelParentPositionId: number, secondLevelParentPositionId: number, selfPositionId: number}>) => {

        const filteredFirstLevelSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.firstLevelParentPositionId)[0];
        const filteredFirstLevelIndex: number = state.firstLevelSections.indexOf(filteredFirstLevelSection);

        const filteredSecondLevelSection: Paragraph | SecondLevelSectionModel = state.firstLevelSections[filteredFirstLevelIndex].children.filter(child => child.selfPositionId === action.payload.secondLevelParentPositionId)[0];
        const filteredSecondLevelIndex: number = state.firstLevelSections[filteredFirstLevelIndex].children.indexOf(filteredSecondLevelSection);
        
        const currenPosition: number = action.payload.selfPositionId;
        const newDesirePosition: number = action.payload.selfPositionId + 2;

        //inserta el parrafo en el nuevo index
        state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content.splice(newDesirePosition, 0, state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content[action.payload.selfPositionId]);
        //elimina el parrafo de su posición anterior
        state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content.splice(currenPosition, 1);

        //actualizando ids de posición
        state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content.forEach((paragraph, paragraphIndex) => {
            paragraph.selfPositionId = paragraphIndex;
        });
        
    }, 
    updateChildrenToHiglight: (state, action: PayloadAction<{childrenId: number, parentPositionId: number} | null>) => {
        state.firstLevelChildrenToHiglight = action.payload;
    },
    updateSecondLevelChildrenToHiglight: (state, action: PayloadAction<{childrenId: number, firstLevelParentPositionId: number, secondLevelParentPositionId: number} | null>) => {
        state.SecondLevelChildrenToHiglight = action.payload;
    },
    deleteFirstLevelParagraph: (state, action: PayloadAction<{firstLevelParentPositionId: number, selfPositionId: number}>) => {
        const filteredSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.firstLevelParentPositionId)[0];
        const filteredSectionIndex: number = state.firstLevelSections.indexOf(filteredSection);
        const filteredParagraph = state.firstLevelSections[filteredSectionIndex].children.filter(child => child.selfPositionId === action.payload.selfPositionId)[0];
        const filteredParagraphIndex: number = state.firstLevelSections[filteredSectionIndex].children.indexOf(filteredParagraph);
        state.firstLevelSections[filteredSectionIndex].children.splice(filteredParagraphIndex, 1);

        //actualizando ids de posición en todos los niveles
        state.firstLevelSections[filteredSectionIndex].children.forEach((child, index) => {

            const newChild: Paragraph | SecondLevelSectionModel = {
                ...child,
                selfPositionId: index,
                content: child.content.map(e => {
                    if(child.childType === ChildTypeEnum.subtitle){
                        return {
                            ...e,
                            secondLevelParentPositionId: index
                        }
                    } else {
                        return e;
                    }
                })
            }

            state.firstLevelSections[filteredSectionIndex].children[index] = newChild;
        });
    },
    deleteSecondLevelParagraph: (state, action: PayloadAction<{firstLevelParentPositionId: number, secondLevelParentPositionId: number, selfPositionId: number}>) => {
        
        const filteredFirstLevelSection: FirstLevelSectionModel = state.firstLevelSections.filter(section => section.positionid === action.payload.firstLevelParentPositionId)[0];
        const filteredFirstLevelIndex: number = state.firstLevelSections.indexOf(filteredFirstLevelSection);

        const filteredSecondLevelSection: Paragraph | SecondLevelSectionModel = state.firstLevelSections[filteredFirstLevelIndex].children.filter(child => child.selfPositionId === action.payload.secondLevelParentPositionId)[0];
        const filteredSecondLevelIndex: number = state.firstLevelSections[filteredFirstLevelIndex].children.indexOf(filteredSecondLevelSection);
        
        //eliminando el párrafo elegido
        state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content.splice(action.payload.selfPositionId, 1);

        //actualizando los ids de posición de los párrafos restantes
        state.firstLevelSections[filteredFirstLevelIndex].children[filteredSecondLevelIndex].content.forEach((paragraph, paragraphIndex) => {
            paragraph.selfPositionId = paragraphIndex;
        })
    },
    editFirstLevelParagraph: (state, action: PayloadAction<{children: any[], metadata: ParagraphCreationMetadataModel}>) => {
        const {firstLevelParentPositionId, parentType, secondLevelParentPositionId, selfPositionId} = action.payload.metadata;
        const children = action.payload.children;

        switch(parentType){
            case ParentTypeEnum.title:
                const firstLevelParagraph: Paragraph = {
                    firstLevelParentPositionId: firstLevelParentPositionId,
                    selfPositionId: selfPositionId,
                    parentType: parentType,
                    childType: ChildTypeEnum.paragraph,
                    content: children,
                }
                state.firstLevelSections[firstLevelParentPositionId].children[selfPositionId] = firstLevelParagraph;
            break;
            case ParentTypeEnum.subTitle:
                const secondLevelParagraph: Paragraph = {
                    firstLevelParentPositionId: firstLevelParentPositionId,
                    secondLevelParentPositionId: Number(secondLevelParentPositionId),
                    selfPositionId: selfPositionId,
                    parentType: parentType,
                    childType: ChildTypeEnum.paragraph,
                    content: children,
                }
                state.firstLevelSections[firstLevelParentPositionId].children[Number(secondLevelParentPositionId)].content[selfPositionId] = secondLevelParagraph;
            break;
        }
    },
    addSubtitleToFirstLevelSection: (state, action: PayloadAction<SecondLevelSectionModel>) => {
        state.firstLevelSections[action.payload.firstLevelParentPositionId].children.push(action.payload);
    },
}});

export const { 
    updateDocumentBuildType,
    updateFirstLevelSections,
    updateShowAddTitleModal,
    updateTitleText,
    updateSubtitleText,
    updateAllTitleData,
    updateAllSubtitleData,
    deleteFirstLevelSection,
    deleteSecondLevelSection,
    updateShowDeleteTitleWarningModal,
    updateSectionIdToDelete,
    updateParagraphMetadata,
    addParagraph,
    changeFirstLevelChildrenUpwardOrder,
    changeSecondLevelChildrenUpwardOrder,
    changeFirstLevelChildrenDownwardOrder,
    changeSecondLevelChildrenDownwardOrder,
    updateChildrenToHiglight,
    updateSecondLevelChildrenToHiglight,
    deleteFirstLevelParagraph,
    deleteSecondLevelParagraph,
    editFirstLevelParagraph,
    addSubtitleToFirstLevelSection
} = documentBuildReducer.actions;
export default documentBuildReducer.reducer;