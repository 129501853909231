import { createAsyncThunk } from "@reduxjs/toolkit";
import { FirstLevelSectionModel } from "../../../lawyer/redux-state/models/first-level-section-model";
import { updateFirstLevelSections } from "../../../lawyer/redux-state/reducers/document-build-reducer";
import { DocumentTemplateAdapterService } from "../../services/document-template-adapter-service";

interface payload {
  id: number,
  token: string,
}

//thunk para obtener el document template 
export const getDocumentTemplateAdapterToEditThunk = createAsyncThunk(
  "document-template-adapter/get-document-template-adapter-to-edit-thunk",
  async (payload:payload, { rejectWithValue, dispatch }) => {
    const documentTemplateToEdit = await DocumentTemplateAdapterService.getDocumentTemplateToEdit(payload.id, payload.token);
    if (documentTemplateToEdit){
      const documentTemplateTranformed:Array<FirstLevelSectionModel> = DocumentTemplateAdapterService.getDocumentTemplateTransformedToEdit(documentTemplateToEdit);
       dispatch(updateFirstLevelSections(documentTemplateTranformed))
      return documentTemplateTranformed;
    }else return rejectWithValue(null);
  }
);
