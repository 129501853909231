import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { SelectedFormEnum } from "../../enums/selected-form-enum";
import { updatePreviewChildren, updateSelectedForm } from "../../redux-state/reducers/paragraph-build-reducer";
import { ParagraphBuildService } from "../../services/paragraph-build-service";
import DropdownFactoryModalComponent from "../dropdown-factory-modal-component/dropdown-factory-modal-component";
import "./build-block-component.scss";


const BuildBlockComponent = () => {

    const previewChildren = useAppSelector(state => state.paragraphBuildReducer.previewChildren); 
    const dispatch = useAppDispatch();

    const [showVerticalSpaceSuccess, setShowVerticalSpaceSuccess] = useState<boolean>(false);
    const [showDropdownFactoryModal, setShowDropdownFactoryModal] = useState<boolean>(false);

    useEffect(() => {
        dispatch(updateSelectedForm(SelectedFormEnum.idle));
    },[previewChildren])

    const handleAddTextClick = () => {
        dispatch(updateSelectedForm(SelectedFormEnum.textForm));
    }

    const handleBoldTextclick = () => {
        dispatch(updateSelectedForm(SelectedFormEnum.boldTextForm));
    }

    const handleTextFieldClick = () => {
        dispatch(updateSelectedForm(SelectedFormEnum.textFieldForm));
    }

    const handleDateFieldClick = () => {
        dispatch(updateSelectedForm(SelectedFormEnum.datePickerForm));
    }

    const handleVerticalSpaceClick = () => {
        dispatch(updatePreviewChildren(ParagraphBuildService.getVerticalHtmlSpace()));
        setShowVerticalSpaceSuccess(true);
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setShowVerticalSpaceSuccess(false);
    }

    const handleTextBoxFieldClick = () => {
        dispatch(updateSelectedForm(SelectedFormEnum.textBoxForm));
    }

    const handleDropdownClick = () => {
        setShowDropdownFactoryModal(true);
    }

    const handleSignatureClick = () => {
        dispatch(updateSelectedForm(SelectedFormEnum.signatureForm));
    }

    return (
        <div className="build-block">
            <div>
                <button className="add" onClick={handleAddTextClick}>Texto <span className="icon-icon-plus"></span></button>
                <button className="add" onClick={handleBoldTextclick}>Texto en negrita <span className="icon-icon-plus"></span></button>
                <button className="add" onClick={handleVerticalSpaceClick}>Salto de línea <span className="icon-icon-plus"></span></button>
                <button className="add" onClick={handleTextFieldClick}>Campo de texto <span className="icon-icon-plus"></span></button>
                <button className="add" onClick={handleDateFieldClick}>Campo de fecha <span className="icon-icon-plus"></span></button>
                <button className="add" onClick={handleTextBoxFieldClick}>Caja de texto <span className="icon-icon-plus"></span></button>
                <button className="add" onClick={handleDropdownClick}>Lista desplegable <span className="icon-icon-plus"></span></button>
                <button className="add" onClick={handleSignatureClick}>Firma <span className="icon-icon-plus"></span></button>
            </div>

            {
                showDropdownFactoryModal && <DropdownFactoryModalComponent 
                setShowDropdownFactoryModal={setShowDropdownFactoryModal}
                />
            }

            <Snackbar open={showVerticalSpaceSuccess} autoHideDuration={6000} onClose={handleClose}>
                <Alert severity="success" sx={{ width: '100%'}} onClose={handleClose}>
                    Salto de línea agregado correctamente
                </Alert>
            </Snackbar>
            
        </div>
    )
}

export default BuildBlockComponent;