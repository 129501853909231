import { UserResponseModel } from "../redux-state/models/user-response-model";
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";

export class UserService {
 /**
   * Metodo para obtener informacion de un usuario
   * @param token {token de acceso del usuario}
   * @returns todas las categorias
   */

  static async getUserInfo(userId: number, token: string): Promise<UserResponseModel | null> {
    try 
    {
        const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/Account/getUserInfo?userId=${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);      
      const userInfo:UserResponseModel  = await response.json();
      return userInfo;
    } catch (error) {
        return null
    }
  }

}