import {actionsConstants} from "../constants/actions-constants";
import set from 'lodash/fp/set'

function formRenderReducer(state, action) {
  switch (action.type) {
    case actionsConstants.ON_CHANGE_RENDER:
      return {valueMap: set( action.id, action.value)(state.valueMap)};
    default:
      throw new Error();
  }
}

export default formRenderReducer
