import { PersonTypeModel } from "../../commons/redux-state/models/person-type-model";
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";
import { DashboardAdminFilterUsersRequestModel } from "../redux-state/models/dashboard-admin-filter-users-request-model";
import { DashboardAdminFilterUsersResponseModel } from "../redux-state/models/dashboard-admin-filter-users-response-model";
import { DashboardAdminUserStatusRequestModel } from "../redux-state/models/dashboard-admin-user-status-request-model";
import { DashboardAdminUserStatusResponseModel } from "../redux-state/models/dashboard-admin-user-status-response-model";

export class DashboardAdminService{

//Funcion para obtener un listado de usuarios segun un filtro dado
public static async getUserListPlan(filterUserList:DashboardAdminFilterUsersRequestModel , token: string): Promise< DashboardAdminFilterUsersResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/Account/getAllUsers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(filterUserList)
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const userList:DashboardAdminFilterUsersResponseModel  = await response.json();
      return userList;
    } catch (error) {
      return null
    }
  }

//Funcion que obtiene los tipos de persona
static async getTypePersonList(token: string): Promise<PersonTypeModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}PersonType/getAll`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const personTypeList: PersonTypeModel = await response.json();
      return personTypeList;
    } catch (error) {
      return null
    }
  }

  //Funcion que desactiva un plan de usuario segun su id
  static async disabledPlan (userId:number, token: string): Promise<DashboardAdminUserStatusResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}Plan/disabledPlan/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const userStatusInfo: DashboardAdminUserStatusResponseModel = await response.json();
      return userStatusInfo;
    } catch (error) {
      return null
    }
  }

  //Funcion para obtener un listo de usuarios segun un filtro dado
  public static async activePlan(userBillingDate:DashboardAdminUserStatusRequestModel , token: string): Promise< DashboardAdminUserStatusResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}Plan/activatePlan`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(userBillingDate)
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const data:DashboardAdminUserStatusResponseModel  = await response.json();
      return data;
    } catch (error) {
      return null
    }
  }
}


