import React, { useEffect, useState } from "react";
import map from "lodash/map";
import get from "lodash/get";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import 'dayjs/locale/es';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { v4 as uuidv4 } from 'uuid';
import { findValue } from "../../../utils/helpers/find-input-value";
import { initial } from "lodash";

export const RadioOption = ({ item, currentValue, handleChange, readOnly }) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        id={item.elementvalueId}
        disabled={readOnly}
        value={item.elementvalueId}
        checked={item.elementvalueId === currentValue}
        onChange={handleChange}
      />
      <label className="form-check-label" htmlFor={item.elementvalueId}>
        {item.displayName}
      </label>
    </div>
  );
};

/**
 * Funcion que obtiene la lista de valores de los elementos
 * @returns Lista de valores para el elemento
 */

function getOptionList(formElementValues) {
  const list =
    formElementValues && formElementValues.length
      ? map(formElementValues, (item) => {
        if (item.valueFieldText !== undefined) {
          return (
            <option
              key={`select${item.keyElement}selectOption${item.valueFieldId}`}
            >
              {item.valueFieldText}
            </option>
          );
        } else {
          return (
            <option
              key={uuidv4()}
            >
              {item}
            </option>
          );
        }

      })
      : null;

  return list;
}

export const SimpleSelectElement = (props) => {
  const { valueMap, element, handleChange, recoveryValue } = props;
  return (
    <FormControl sx={{ minWidth: 160 }} className="select">

      <select
        id={`${element.clauseId}-${element.position}`}
        onChange={handleChange}
        value={get(valueMap, element.fieldId)}
        key={`select${element.keyElement}`}
        defaultValue={recoveryValue}
      >
        {getOptionList(element.field.valueField)}
      </select>
    </FormControl>
  );
};

export const PasswordElement = (props) => {
  const { valueMap, element, handleChange } = props;
  return (
    <div className="form-group">
      <label htmlFor={element.elementId}>{element.displayName}</label>
      <input
        type="password"
        className="form-control"
        id={element.elementId}
        defaultValue={get(valueMap, element.elementId)}
        placeholder="Password"
        onChange={handleChange}
        disabled={element.readOnly}
      />
    </div>
  );
};

export const CheckboxElement = (props) => {
  const { valueMap, element, handleChange } = props;

  return (
    <div className="form-check">
      <label className="form-check-label">
        <input
          type="checkbox"
          defaultChecked={get(valueMap, element.elementId)}
          onChange={handleChange}
          disabled={element.readOnly}
          className="form-check-input"
        />{" "}
        <span>{element.displayName}</span>
      </label>
    </div>
  );
};

export const PrintElement = (props) => {
  const { element } = props;
  return (
    <div className="form-group">
      <label htmlFor="formControlRange">{element.displayName}</label>
    </div>
  );
};

export const TextElement = (props) => {
  const { valueMap, element, handleChange, recoveryValue } = props;
  return ( 
      <TextField
      id={`${element.clauseId}-${element.position}`}
      inputProps={{ clauseid: element.clauseId, position: element.position }}
      key={props.keyField}
      className="text"
      label={element.label}
      variant="outlined"
      onBlur={handleChange}
      required={element.optional === true ? false : true}
      defaultValue={recoveryValue}  
    />

  );
};

export const TextAreaElement = (props) => {
  const { valueMap, element, handleChange, recoveryValue } = props;

  return (
    <TextField
      id={`${element.clauseId}-${element.position}`}
      key={element.keyField}
      inputProps={{ clauseid: element.clauseId, position: element.position }}
      required={element.optional === true ? false : true}
      className="area"
      label={element.label}
      multiline
      onBlur={handleChange}
      rows={2}
      /* defaultValue={get(valueMap, element.fieldId)} */
      defaultValue={recoveryValue}
    />
  );
};

export const RadioElement = (props) => {
  const { valueMap, element, handleChange } = props;
  const { formElementValues } = element;
  const currentValue = get(valueMap, element.elementId);
  const list =
    formElementValues && formElementValues.length
      ? map(formElementValues, (item) => (
        <RadioOption
          key={item.elementvalueId}
          item={item}
          currentValue={currentValue}
          handleChange={handleChange}
          readOnly={element.readOnly}
        />
      ))
      : null;

  return (
    <fieldset className="form-group">
      <div className="row">
        <legend className="col-form-label col-sm-2 pt-0">
          {element.displayName}
        </legend>
        <div className="col-sm-10">{list}</div>
      </div>
    </fieldset>
  );
};

export const DatePickerElement = (props) => {
  const { valueMap, element, handleChange, recoveryValue } = props;
  return (
    <LocalizationProvider adapterLocale={"es"} className="calendar" dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Fecha"
        value={recoveryValue}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            required={element.optional === true ? false : true}
            id={`${element.clauseId}-${element.position}`}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};


export const Signature = (props) => {
  return (
    <>{props.element.label} : ____________________ </>
  );
};
