import { Box, Dialog, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { DropdownFormModel } from "../../redux-state/models/text-form-model";
import { ParagraphBuildService } from "../../services/paragraph-build-service";
import { DropdownForm } from "../build-block-form-component/build-block-form-component";
import "./dropdown-factory-modal-component.scss";
import { v4 as uuidv4 } from 'uuid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropddownListService } from "../../services/dropdown-list-service";
import { useAppDispatch } from "../../../redux/hooks";
import { updatePreviewChildren } from "../../redux-state/reducers/paragraph-build-reducer";
import { ElementTypeEnum } from "../../enums/element-type-enum";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

//componente principal modal -------------------------------------------------------------------------
interface DropdownFactoryProps {
    setShowDropdownFactoryModal: any;
}
const DropdownFactoryModalComponent:FC<DropdownFactoryProps> = ({setShowDropdownFactoryModal}) => {

    /* Variables de colores */
    const blue = "#004571";
    const purple = "#82389A";

    const dispatch = useAppDispatch();

    const [operationType, setOperationType] = useState<"creation" | "edition" >("creation");
    const [optionToEdit, setOptionToEdit] = useState<{optionIndex: number} | null>(null);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [creationInputValue, setCreationInputValue] = useState<string>("");
    const [dropdownData, setDropdownData] = useState<DropdownFormModel>({
        label: "",
        tooltip: false,
        tooltipText: "",
        dropdownOptions: [],
        required: false
    });

    const handleCreationInputChange = (e:any) => {
        setCreationInputValue(e.target.value);
    }

    const handleAddButtonClick = () => {

        if(creationInputValue.trim() === "") return;

        const newDropdownOptions = [...dropdownData.dropdownOptions, creationInputValue];
        setDropdownData({
            ...dropdownData,
            dropdownOptions: newDropdownOptions
        })
        setCreationInputValue("");
    }

    const handleSaveButtonClick = () => {

        if(creationInputValue.trim() === "") return;

        const newDropdownOptions = [...dropdownData.dropdownOptions];
        newDropdownOptions[Number(optionToEdit)] = creationInputValue;
        setDropdownData({
            ...dropdownData,
            dropdownOptions: newDropdownOptions
        })
        setOperationType("creation");
        setCreationInputValue("");
    }

    const handleCountryListClick = () => {
        setDropdownData({
            ...dropdownData,
            dropdownOptions: [...DropddownListService.countryList]
        })
    }

    const handleDocumentTypeClick = () => {
        setDropdownData({
            ...dropdownData,
            dropdownOptions: [...DropddownListService.documentTypesList]
        }) 
    }

    const handlePersonTypeClick = () => {
        setDropdownData({
            ...dropdownData,
            dropdownOptions: [...DropddownListService.personTypesList]
        }) 
    }

    const handleCleanButtonClick = () => {
        setDropdownData({
            ...dropdownData,
            dropdownOptions: []
        }) 
    }

    const handleBackButtonClick = () => {
        setActiveStep(0);
    }

    const handleAddListButtonClick = () => {
        dispatch(updatePreviewChildren(ParagraphBuildService.getDropdownInput(dropdownData)));
        setActiveStep(0);
        setDropdownData({
            label: "",
            tooltip: false,
            tooltipText: "",
            dropdownOptions: [],
            required: false
        });
        setShowDropdownFactoryModal(false);
    }

    return (
        <Dialog open={true} maxWidth={"md"} fullWidth={true}>
            <DialogTitle sx={{background: blue, color: "white"}}>Creación de nueva lista desplegable</DialogTitle>
            <div className="dropdown-factory">

                <div className="stepper-and-preview-container">

                    <DropdownModalStepper 
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    />

                    <div className="dropdown-factory__preview-container">
                        <Typography variant="caption" sx={{color: "#565656"}}>Previsualización</Typography>
                        {
                            dropdownData.label !== "" && <PreviewDropdownComponent 
                            elementType={ElementTypeEnum.selectField}
                            label={dropdownData.label}
                            tooltip={dropdownData.tooltip}
                            tooltipText={dropdownData.tooltipText}
                            dropdownOptions={dropdownData.dropdownOptions}
                            required={dropdownData.required}
                            />
                        }
                    </div>

                </div>

                { activeStep === 0 && <div className="dropdown-factory__divider"></div> }

                {
                    activeStep === 0 && <DropdownForm  
                    setActiveStep={setActiveStep} 
                    setShowDropdownFactoryModal={setShowDropdownFactoryModal}
                    dropdownData={dropdownData}
                    setDropdownData={setDropdownData}
                    />
                }

                {
                    activeStep === 1 && <div className="dropdown-factory__main-grid">

                        <div className="dropdown-factory__main-left-block">

                            <div className="dropdown-factory__creation">
                                <TextField onChange={handleCreationInputChange} label="Escriba una nueva opción" variant="outlined" size="small" value={creationInputValue}/>

                                {operationType === "creation" && <button onClick={handleAddButtonClick} className="dropdown-factory__creation-button">Agregar</button>}
                                {operationType === "edition" && <button onClick={handleSaveButtonClick} className="dropdown-factory__creation-button">Guardar</button>}

                            </div>

                            <div className="dropdown-factory__options-container">
                                {
                                    dropdownData.dropdownOptions.map((option, index) => {
                                        return <IndividualOption 
                                        option={option}
                                        optionIndex={index}
                                        dropdownData={dropdownData}
                                        setDropdownData={setDropdownData}
                                        setOperationType={setOperationType}
                                        setOptionToEdit={setOptionToEdit}
                                        setCreationInputValue={setCreationInputValue}
                                        key={uuidv4()}
                                        />
                                    })
                                }
                            </div>

                            <button className="clear-button" onClick={handleCleanButtonClick}>Limpiar opciones</button>

                        </div>

                        <div className="dropdown-factory__main-right-block">
                            <p>Listas predeterminadas</p>
                            <div className="dropdown-factory__list-container">

                                <button onClick={handleDocumentTypeClick} className="add-list">Tipo de documento<span className="icon-icon-plus"></span></button>
                                <button onClick={handlePersonTypeClick} className="add-list">Tipo de persona<span className="icon-icon-plus"></span></button>
                                <button onClick={handleCountryListClick} className="add-list">Países<span className="icon-icon-plus"></span></button>
                                
                            </div>
                        </div>

                    </div>
                    
                }

                {
                    activeStep === 1 && <div className="dropdown-factory__button-bar">
                        <button onClick={handleBackButtonClick} className="back-button">Atrás</button>
                        <button onClick={handleAddListButtonClick} className="forward-button" disabled={dropdownData.dropdownOptions.length < 2}>Agregar Lista</button>
                    </div>
                }
                
            </div>
        </Dialog>
    )

}

//componente secundario stepper -----------------------------------------------------------------------------
interface DropdownModalStepperProps {
    activeStep: number;
    setActiveStep: any;
}
const DropdownModalStepper:FC<DropdownModalStepperProps> = ({activeStep, setActiveStep}) => {

   const overridenStyles = {
        "& .MuiStepIcon-root.Mui-active": {
            width: "40px",
            height: "40px",
            padding: "2px",
            borderRadius: "50%",
            border: "1px solid #00b14f",
            color: "#00b14f"
        },
        "& .MuiStepIcon-root.Mui-completed": {
            width: "25px",
            height: "25px",
            color: "#00b14f"
        }
    }

   const steps = ['Información básica', 'Agregar opciones'];

   return (
        <Box sx={{ width: '55%'}}>
            <Stepper activeStep={activeStep}>
                {
                    steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        return (
                            <Step key={label} {...stepProps} sx={overridenStyles}>
                              {index === activeStep && <StepLabel><b>{label}</b></StepLabel>}
                              {index !== activeStep &&<StepLabel>{label}</StepLabel>}
                            </Step>
                        ); 
                    })
                }
            </Stepper>
        </Box>
   ) 
}

//componente secundario opción individual -----------------------------------------------------------------------------
interface IndividualOptionProps {
    option: string;
    optionIndex: number;
    dropdownData: DropdownFormModel;
    setDropdownData: any;
    setOperationType: any;
    setOptionToEdit: any;
    setCreationInputValue: any;
}
const IndividualOption:FC<IndividualOptionProps> = ({option, optionIndex, dropdownData, setDropdownData, setOperationType, setOptionToEdit, setCreationInputValue}) => {

    const handleDeleteButtonClick = () => {
        const currentOptions: string[] = [...dropdownData.dropdownOptions]
        currentOptions.splice(optionIndex, 1);
        setDropdownData({
            ...dropdownData,
            dropdownOptions: currentOptions
        })

    }

    const handleEditButtonClick = () => {
        setOptionToEdit(optionIndex);
        setCreationInputValue(option);
        setOperationType("edition");
    }

    return (
        <div className="indiviual-option">
            <p>{option}</p>

            <div className="indiviual-option__action-container">
                <IconButton onClick={handleEditButtonClick}>
                    <EditIcon sx={{color: "#82389A"}}/>
                </IconButton>
                <IconButton onClick={handleDeleteButtonClick}>
                    <DeleteIcon sx={{color: "#EF452E"}}/>
                </IconButton>
            </div>
        </div>
    )
}


//componente secundario dropdown preview -----------------------------------------------------------------------------

interface PreviewDropdownProps {
    elementType: ElementTypeEnum.selectField;
    label: string;
    tooltip: boolean;
    tooltipText: string;
    dropdownOptions: string[];
    required: boolean;
}
const PreviewDropdownComponent:FC<PreviewDropdownProps> = ({elementType, label, tooltip, tooltipText, dropdownOptions, required}) => {

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedValue(event.target.value as string);
    };

    return (
    
        <div className="preview-dropdown-container">
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValue}
                    label={label}
                    onChange={handleChange}
                    style={{background: "white"}}
                >
                    {dropdownOptions.map(option => <MenuItem value={option} key={uuidv4()}>{option}</MenuItem>)}
                </Select>
            </FormControl>

            {   
                tooltip && 
                <Tooltip title={tooltipText} arrow>
                    <IconButton>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            }

        </div>
    
    )
}

export default DropdownFactoryModalComponent;
      