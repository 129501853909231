import React from "react";
import "./footer-component.scss";

import Logo from "../../../assets/images/logo-color.svg";

function FooterComponent() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-3">
              <img src={Logo} alt="Anuwa" width="197" height="47" />
            </div>
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-3">
              <ul className="menuFooter">
                <li>Contáctanos</li>
                <a href="tel:">
                  <li>01 8000 123 123</li>
                </a>
                <a href="tel:">
                  <li>+57 300 123 45 67</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <nav className="footerNav">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-3">
              <p>© Copyright 2023 all rights reserved</p>
            </div>
            <div className="col-12 col-md-4">
              <p>
                <a target="_blank" href="/termnsandconditions">Términos y condiciones</a> |{" "}
                <a target="_blank" href="/dataprocessingpolicy">
                  Política de tratamiento de datos
                </a>
              </p>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
export default FooterComponent;
