import React, { FC, useRef } from "react";
import "./add-subtitle-modal-component.scss";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { TitleSubtitleModel } from "../../redux-state/models/title-subtitle-model";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { SecondLevelSectionModel } from "../../redux-state/models/first-level-section-model";
import { ParentTypeEnum } from "../../enums/parent-type-enum";
import { addSubtitleToFirstLevelSection } from "../../redux-state/reducers/document-build-reducer";
import { useAppDispatch } from "../../../redux/hooks";
import { ChildTypeEnum } from "../../enums/child-type-enum";

//formulario para agragra un nuevo subtitulo dentro de un titulo ---------------------------------------------------------------------------

interface AddSubtitleModalProps {
    setShowAddSubtitleModal: any;
    firstLevelParentPositionId: number;
    selfPositionId: number;
}

const AddSubtitleModalComponent:FC<AddSubtitleModalProps> = ({setShowAddSubtitleModal, firstLevelParentPositionId, selfPositionId}) => {

    /* Variables de colores */
    const blue = "#004571";
    const purple = "#82389A";

    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: TitleSubtitleModel = {
        titleText: "",
        tooltip: false,
        tooltipText: ""
    }

    const validationSchema = yup.object({
        titleText: yup.string()
        .max(300, "El nombre del subtítulo debe ser maximo de 300 caracteres")
        .min(1, "El nombre del subtítulo debe ser mínimo de 1 caracteres")
        .required("El nombre del subtítulo es requerido"),
        tooltip: yup.boolean()
        .required(""),
        tooltipText: yup.string()
        .max(300, "El texto debe ser maximo de 300 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres"),
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  

            const newSecondLevelSection: SecondLevelSectionModel = {
                firstLevelParentPositionId: firstLevelParentPositionId,
                selfPositionId: selfPositionId,
                parentType: ParentTypeEnum.title,
                childType: ChildTypeEnum.subtitle,
                title: {
                    titleText: values.titleText,
                    tooltip: values.tooltip,
                    tooltipText: values.tooltipText
                },
                content:[]
            };
            
            dispatch(addSubtitleToFirstLevelSection(newSecondLevelSection));

            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    const handleCancelButtonClick = () => {
        setShowAddSubtitleModal(false);
    }

    const handleAddButtonClick = () => {
        form.submitForm();
        setShowAddSubtitleModal(false);
    }

    return (
    <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle sx={{background: blue, color: "white"}}>Ingrese la información del subtítulo</DialogTitle>

        <div className="add-subtitle-modal">
            
            <form onSubmit={form.handleSubmit} ref={formRef}>

                <div>
                    <TextField 
                        variant="outlined"
                        label="Nombre del subtítulo"
                        style = {{background: "white"}}
                        id="titleText"
                        name="titleText"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.errors.titleText && form.touched.titleText && <p className="validation-error">{form.errors.titleText}</p>}
                </div>

                <FormControlLabel control={<Checkbox id="tooltip" name="tooltip" checked={form.values.tooltip} onChange={form.handleChange}/>} label="Desea añadir autoayuda?" />

                <div>
                    <TextField 
                        variant="outlined"
                        label="Ingerse texto de la autoayuda"
                        style = {{background: "white"}}
                        id="tooltipText"
                        name="tooltipText"
                        disabled={!form.values.tooltip}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.errors.tooltipText && form.touched.tooltipText && <p className="validation-error">{form.errors.tooltipText}</p>}
                </div>

                <div className="add-subtitle-modal__actions-bar">
                    <button className="add-subtitle-modal__back-button" onClick={handleCancelButtonClick} type="button">cancelar</button>
                    <button className="add-subtitle-modal__forward-button" onClick={handleAddButtonClick} type="button" disabled={!form.isValid || !form.dirty }>Agregar subtítulo</button>
                </div>

                </form>

            </div>
      
    </Dialog>
    )
}

export default AddSubtitleModalComponent;