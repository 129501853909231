import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import { DashboardService } from "../../services/dashboard-service";

export const getDocumentsUserThunk = createAsyncThunk(
  "dashboard/get-documents-user-thunk",
  async (payload:string, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const documentsUserResponse = await DashboardService.getAllDocumentsByUserId(state.dashboardReducer.dashboardDocumentsUserRequestModel!, payload);
    if (documentsUserResponse){
      return documentsUserResponse;
    }else return rejectWithValue(null); 
  }
);
