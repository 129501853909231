import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import './bread-crumb-component.scss'

function BreadCrumbComponent() {
    const { templateInformation } = useAppSelector((state) => state.templateInformationReducer.templateViewData);
  
    return(
        <div className="breadcrumb">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h4>Selección de plantilla</h4>
                        <p>Creación de documento</p>
                    </div>
                    <div  className="col-12"><p>{templateInformation === null ? "": `${templateInformation!.data.categoryName} > ${templateInformation!.data.subCategoryName} > ${templateInformation!.data.templateName}`}</p></div>
                </div>
            </div>
        </div>
    );
}

export default BreadCrumbComponent;