import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../commons/enums/async-status-enum";
import { CurrentDocumentPreviewEditRequestModel } from "../models/current-document-preview-edit-request-model";
import { PreviewDocumentInitialStateModel } from "../models/preview-document-initial-state-model";
import { getDocumentTemplatePreviewEditThunk } from "../thunks/get-document-template-preview-edit-thunk";

export const previewDocumentInitialState: PreviewDocumentInitialStateModel = {
  documentPreviewEditRequestTemplateData: {
    status: AsyncStatusEnum.idle,
    documentTemplatePreviewEdit: null,
    error: {
      error: false,
      message: "",
    }
  }
};

 //extraReducer solo para asincrono y/o sincronos - reducers solo sincronos 
export const previewDocumentReducer = createSlice({
  name: "preview-document-reducer",
  initialState: previewDocumentInitialState ,
  reducers: {
    updateDocumentTemplatePreviewEdit:(state, action: PayloadAction<CurrentDocumentPreviewEditRequestModel>) =>{
      state.documentPreviewEditRequestTemplateData.documentTemplatePreviewEdit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getDocumentTemplatePreviewEditThunk.fulfilled, (state, action:PayloadAction<any>) => {
      state.documentPreviewEditRequestTemplateData.status = AsyncStatusEnum.completed;
      state.documentPreviewEditRequestTemplateData.documentTemplatePreviewEdit = action.payload;
      state.documentPreviewEditRequestTemplateData.error = {
        error: false,
        message: '',
      };
    })
    .addCase(getDocumentTemplatePreviewEditThunk.pending, (state) => {
      state.documentPreviewEditRequestTemplateData.status = AsyncStatusEnum.loading;
    })
    .addCase(getDocumentTemplatePreviewEditThunk.rejected, (state, action) => {
      state.documentPreviewEditRequestTemplateData.documentTemplatePreviewEdit = previewDocumentInitialState.documentPreviewEditRequestTemplateData.documentTemplatePreviewEdit;
      state.documentPreviewEditRequestTemplateData.status = AsyncStatusEnum.rejected;
      state.documentPreviewEditRequestTemplateData.error = {
        error: true,
        message: 'Error rejected',
      };
    })
  },
});

export const { updateDocumentTemplatePreviewEdit } = previewDocumentReducer.actions;
export default previewDocumentReducer.reducer;
