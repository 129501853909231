import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import naturalPersonReducer from '../../src/natural-person/redux-state/reducers/natural-person-reducer';
import commonReducer from "../commons/redux-state/reducers/common-reducer";
import activeUserDashboardAdminReducer from "../dashboard-admin/redux-state/reducers/active-user-dashboard-admin-reducer";
import dashboardAdminReducer from "../dashboard-admin/redux-state/reducers/dashboard-admin-reducer";
import disabledUserDashboardAdminReducer from "../dashboard-admin/redux-state/reducers/disabled-user-dashboard-admin-reducer";
import dashboardReducer from "../dashboard/redux-state/reducers/dashboard-reducer";
import documentTemplateAdapterToEditReducer from "../document-template-edit/redux-state/reducers/document-template-adapter-to-edit-reducer";
import documentTemplateAdapterToFillReducer from "../document-template-to-fill-out/redux-state/reducer/document-template-adapter-to-fill-reducer";
import documentBuildReducer from "../lawyer/redux-state/reducers/document-build-reducer";
import lawyerReducer from "../lawyer/redux-state/reducers/lawyer-reducer";
import paragraphBuildReducer from "../lawyer/redux-state/reducers/paragraph-build-reducer";
import inputValueReducer from "../natural-person/redux-state/reducers/input-value-reducer";
import previewDocumentReducer from "../preview-document/reducers/preview-document-reducer";
import templateInformationReducer  from "../template-view/redux-state/template-view-reducer";
import userReducer from "../user/redux-state/reducers/user-reducer";

export const store = configureStore({
  reducer: {
    naturalPersonReducer: naturalPersonReducer,
    commonReducer: commonReducer,
    dashboardReducer:dashboardReducer,
    userReducer:userReducer,
    lawyerReducer:lawyerReducer,
    paragraphBuildReducer: paragraphBuildReducer,
    documentBuildReducer: documentBuildReducer,
    previewDocumentReducer: previewDocumentReducer,
    templateInformationReducer:templateInformationReducer,
    documentTemplateAdapterToEditReducer:documentTemplateAdapterToEditReducer,
    documentTemplateAdapterToFillReducer:documentTemplateAdapterToFillReducer,
    dashboardAdminReducer:dashboardAdminReducer,
    disabledUserDashboardAdminReducer:disabledUserDashboardAdminReducer,
    activeUserDashboardAdminReducer:activeUserDashboardAdminReducer,
    inputValueReducer: inputValueReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          "paragraph-build-reducer/updatePreviewChildren",
          "document-build-reducer/addParagraph",
          "document-build-reducer/updateFirstLevelSections",
          "paragraph-build-reducer/feedPreviewChildren",
          "document-build-reducer/editFirstLevelParagraph",
          "document-template-adapter/get-document-template-adapter-to-edit-thunk/fulfilled", 
        ],
        ignoredPaths: [
          "paragraphBuildReducer.previewChildren",
          "paragraphBuildReducer.paragraphMetadata",
          "documentBuildReducer.firstLevelSections",
          "documentTemplateAdapterToEditReducer.documentTemplateTrasnformedData.documentTemplateTransformed",
        ],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
