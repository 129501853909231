import React, { Component } from "react";
import { Context } from "../../../Context";
import { GLOBALS } from "../../../utils/Globals";
import HeaderLoginComponent from "../header-login-component/header-login-component";
import HeaderMenuComponent from "../header-menu-component/header-menu-component";
import BreadCrumbComponent from "../bread-crumb-component/bread-crumb-component";
import FooterComponent from "../footer-component/footer-component";
export class Layout extends Component {
  static contextType = Context;

  constructor() {
    super();
    this.state = {
      userLogged: null,
      isUserLoggedNaturalPerson: false,
      isUserLoggedAdmin: false,
    };
  }

  componentDidMount() {
    const isNaturalPerson =
      this.context.userLogged.profile.role ===
      GLOBALS.roles.naturalPerson.roleName;

    const isAdmin =
      this.context.userLogged.profile.role === GLOBALS.roles.admin.roleName;

    const isLawyer =
      this.context.userLogged.profile.role === GLOBALS.roles.lawyer.roleName;

    this.setState({
      userLogged: this.context.userLogged,
      isUserLoggedNaturalPerson: isNaturalPerson,
      isUserLoggedAdmin: isAdmin,
      isUserLoggedLawyer: isLawyer,
    });
  }

  render() {
    return (
      <>
        {<HeaderMenuComponent />}
        {<BreadCrumbComponent />}
        <main>{this.props.children}</main>
      </>
    );
  }
}
