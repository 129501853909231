import { get } from "./Api";

export class FieldService {

  /**
   * Metodo que devuelve un field dado su Id
   * @param id {Id de; field a consultar}
   * @param token {token de acceso del usuario}
   * @returns field
   */
   static getFieldById(fieldId: number, token: string) {
    return get(`${process.env.REACT_APP_API_URL}Field/previewField?fieldId=${fieldId}`, token);
  }}