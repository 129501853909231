import React, { useState, useEffect, useContext, FC, useRef } from "react";
import { Link } from "react-router-dom";
import { CategoryService } from "../../../services/CategoryService";
import { SubCategoryService } from "../../../services/SubCategoryService";
import { DocumentTemplateHttpService } from "../../../services/DocumentTemplateHttpService";
import { Context } from "../../../Context";
import { Layout } from "../../common/Layout";
import "./template-view.scss";
import { GLOBALS } from "../../../utils/Globals";
import { Alert, AlertTitle } from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import { TemplateInformationModel } from "../../../commons/redux-state/models/template-information-model";
import {updateTemplateInformation } from "../../../template-view/redux-state/template-view-reducer";
import { v4 as uuidv4 } from 'uuid';
import FooterComponent from "../../common/footer-component/footer-component";
interface CategoryButtonsProps{
  id:number;
  name:string;
  subCategoriesxCategory:any;
  index: number;
  isActiveCategory: boolean;
  setActiveIndexCategory:any;
}

interface SubCategoryButtonsProps{
  subCategoryId:number;
  name:string;
  categoryName:string;
}

 const TemplateView = () => {
  const { isAuth: token } = useContext(Context);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSubCategorySection, SetShowSubCategorySection] = useState(false);
  const [showDocumentTemplateSection, SetShowDocumentTemplateSection] = useState(false);
  const [categories, SetCategories] = useState({});
  const [subCategories, SetSubCategories] = useState({});
  const [showSubCategories, setShowSubCategories] = useState(<></>);
  const [showDocumentsTemplates, setShowDocumentsTemplates] = useState(<></>);
  const [categoryIdUrlParameter, SetCategoryIdUrlParameter] = useState(Number);
  const [subCategoryIdUrlParameter, SetSubCategoryIdUrlParameter] = useState(Number);
  const [documentTemplateUrlParameter, SetDocumentTemplateUrlParameter] = useState(Number);
  const [activeIndexCategory, setActiveIndexCategory] = useState();

  sessionStorage.setItem('estructureChangeFlag', 'false');

  const templateParametersProperties = {
    IdCategory: 0,
    IdSubCategory: 0,
    IdDocumentTemplate: 0,
    documentTemplateName: "",
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  /**
   * Metodo que se ejecuta luego del primer render
   * Obtiene y mapea todas las categorias y las subcategorias desde el API
   * Asigna los resultados a los Hooks 'SetCategories' y 'SetSubCategories' respectivamente
   */
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [categoryList, subCategoryList] = await Promise.all([
        CategoryService.getAll(token),
        SubCategoryService.getAll(token),
      ]);

      SetCategories(
        categoryList.data.map((u: any) => {
          return {
            categoryId: u.categoryId,
            name: u.name,
          };
        })
      );

      SetSubCategories(
        subCategoryList.data.map((u: any) => {
          return {
            subCategoryId: u.subCategoryId,
            categoryId: u.categoryId,
            name: u.name,
          };
        })
      );
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Metodo que mapea el objeto guardado en el hook 'categories'
   * le asigna a cada etiqueta button, la llave y la funcion para mostrar la sub categoria
   * @returns Etiquetas JSX button con cada categoria como valor
   */
  const Category = () => {    
    const allCategories = Object.values(categories).map((u: any, index: number) => {
      return <CategoryButtons key={uuidv4()} id={u.categoryId} name={u.name} subCategoriesxCategory={subCategoriesxCategory} index={index} isActiveCategory={activeIndexCategory===index} setActiveIndexCategory={setActiveIndexCategory} />;
    });
    return <>{allCategories}</>;
  };

  //Componente que retorna los botones de las categorias
  const CategoryButtons: FC<CategoryButtonsProps> = ({ id, name, subCategoriesxCategory, index, isActiveCategory, setActiveIndexCategory}) => {
    const handleButtonClick = (e:any, categoryId: number, categoryName:string) =>{
      setActiveIndexCategory(index);
      subCategoriesxCategory(categoryId, categoryName)
    }    
    
    return (
    <button onClick={(e) => handleButtonClick(e, id, name)} key={uuidv4()} className={`item${isActiveCategory ? ' selected':''}`}>
      <span className="icon-icon-folder"></span>
      {name}
      </button>
        )
  }

  /**
   * Metodo que mapea el objeto guardado en el hook 'subCategories'
   * le asigna a cada etiqueta button, la llave y la funcion para mostrar los tipos de plantillas
   * @returns Etiquetas JSX button con cada subcategoria como valor
   */
  const subCategoriesxCategory = (categoryId: number, categoryName:string) => {
    SetShowSubCategorySection(true);
    SetShowDocumentTemplateSection(false);
    SetCategoryIdUrlParameter(categoryId);
    SetSubCategoryIdUrlParameter(0);
    templateParametersProperties.IdCategory = categoryId;
    try {
      setIsLoading(true);
      const subCategoriesFiltered = Object.values(subCategories)
        .filter((element: any) => element.categoryId === categoryId)
        .map((u: any, indexSub: number) => {
          return <SubCategoryButtons key={uuidv4()} subCategoryId={u.subCategoryId} name={u.name} categoryName={categoryName} />;          
        });
      setShowSubCategories(<>{subCategoriesFiltered}</>);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const SubCategoryButtons: FC<SubCategoryButtonsProps> = ({ subCategoryId, name, categoryName}) => {    

    const handleButtonClick = (e:any, subCategoryId: number, subCategoryName:string, categoryName:string) =>{      
      var els = document.querySelectorAll('.subcategory');
      for (var i = 0; i < els.length; i++) {
        els[i].classList.remove('selected');
      }
      setTimeout(() => {
        e.target.classList.add('selected');
      },100);
      documentTemplatesxSubCategory(e, subCategoryId, subCategoryName, categoryName);
    }
    
    return (
      <button
        onClick={(e) => handleButtonClick(e, subCategoryId, name, categoryName)}
        key={uuidv4()}
        name="categ oryBtn"
        className={`item subcategory`}
      >
        <span className="icon-icon-folder"></span>
        {name}
      </button>
    );
  }

  /**
   * Metodo que hace el llamado al API para obtener y mapear los tipos de plantillas
   * le asigna a cada etiqueta button la llave, para enviarlo a la vista del diligenciamiento
   * @returns Etiquetas JSX button con cada tipo de plantilla como valor
   */
  const documentTemplatesxSubCategory = async (e:any, subCategoryId: number, subCategoryName: string, categoryName:string) => {    
    SetShowDocumentTemplateSection(true);
    SetSubCategoryIdUrlParameter(subCategoryId);
    try {
      setIsLoading(true);
      const documentsTemplates =
        await DocumentTemplateHttpService.getDocumentsTemplatesXsubCategoryById(
          subCategoryId,
          token
        );

      const documentsTemplatesJSX = documentsTemplates.data.map((u: any) => {
        const styleButton = !u.active ? "itemDisabled" : "item template-elements"
        return (
          <button
            onClick={(e) => setUrlParameters(e, u.documentTemplateId, u.name, subCategoryName, categoryName)}
            key={uuidv4()}
            name="categ oryBtn"
            className={styleButton}
            disabled={!u.active}
          >
            <span className="icon-icon-folder"></span>
            {u.name}
          </button>
        );
      });
      setShowDocumentsTemplates(documentsTemplatesJSX);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Metodo que asigna los valores de los IDs ('IdCategory','IdSubCategory','IdDocumentTemplate') seleccionados, en los hooks ('SetCategoryIdUrlParameter','SetSubCategoryIdUrlParameter','SetDocumentTemplateUrlParameter') para enviar valores por URL.
   * @param documentTemplateId Id del 'documentTemplate' seleccionado
   * @returns void
   */
  const setUrlParameters = (
    e: any,
    documentTemplateId: number,
    documentTemplateName: any,
    subCategoryName: string,
    categoryName: string
  ) => {
    var els = document.querySelectorAll('.template-elements');
      for (var i = 0; i < els.length; i++) {
        els[i].classList.remove('selected');
      }
      setTimeout(() => {
        e.target.classList.add('selected');
      },100);
    SetDocumentTemplateUrlParameter(documentTemplateId);
    const templateInfo:TemplateInformationModel = {
      data:{
        categoryName: categoryName,
        subCategoryName: subCategoryName,
        templateName: documentTemplateName,
        documentTemplateId: documentTemplateId
      }
    }
    dispatch(updateTemplateInformation(templateInfo))
  };

  return (
    <Layout>
      <section className="template">
        <div className="container">
          <div className="row">
            <div className="col-12 category">
              <div className="category_title">
                <p>Categorias</p>
              </div>
              <div className="category_items" id="categories">
                {isLoading && categoryIdUrlParameter === 0 ? (
                  <div className="spinner"></div>
                ) : (
                  <Category />
                )}
              </div>
            </div>
            <div
              id="subCategory"
              style={{ display: showSubCategorySection ? "block" : "none" }}
              className="col-12 category mt-60"
            >
              <div className="category_title">
                <p>Subcategorías</p>
              </div>
              <div className="category_items">
                {isLoading && subCategoryIdUrlParameter === 0 ? (
                  <div className="spinner"></div>
                ) : (
                  showSubCategories
                )}
              </div>
            </div>
            <div
              style={{
                display: showDocumentTemplateSection ? "block" : "none",
              }}
              className="col-12 category mt-60 mb-30"
            >
              <div className="category_title">
                <p>Plantillas</p>
              </div>
              <div className="category_items">
                {isLoading ? (
                  <div className="spinner"></div>
                ) : (
                  showDocumentsTemplates
                )}
              </div>
            </div>
            {subCategoryIdUrlParameter > 0 &&
            categoryIdUrlParameter > 0 &&
            documentTemplateUrlParameter === 0 ? (
              <Alert severity="warning">
                <AlertTitle>{error}</AlertTitle>
                Debe seleccionar una plantilla
              </Alert>
            ) : (
              <Link
                to={`${GLOBALS.menu.template.link}/create?documentTemplateId=${documentTemplateUrlParameter}`}
              >
                <button  style={{
                    display: showDocumentTemplateSection ? "block" : "none",
                  }}
                  className="continue"
                >
                  Continuar
                </button>
              </Link>
            )}
          </div>
        </div>
      </section>
      <FooterComponent />
    </Layout>
  );
};


export default TemplateView