import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { DashboardAdminFilterUsersResponseModel } from "../models/dashboard-admin-filter-users-response-model";
import { DashboardAminInitialStateModel } from "../models/dashboard-admin-initial-state-model";
import { getuserListDashboardAdminThunk } from "../thunks/get-user-list-dashboard-admin-thunk";
import { DashboardAdminFilterUsersRequestModel } from '../models/dashboard-admin-filter-users-request-model';


export const dashboardAdminInitialState: DashboardAminInitialStateModel = {

    dashboardAdminDocumentsUserModelResponseData: {
      status: AsyncStatusEnum.idle,
      dashboardAdminUserListData: null,
      error: {
        error: false,
        message: "",
      },
    },

    dashboardAdminDocumentsUserRequestModel:{
        pageNumber:1,
        pageSize:10,
        sortProperty: "UserId",
        sortType:"des",
        filters:[],
    }
}

//dashboard reducer, para obtener lista de usuarios
export const dashboardAdminReducer = createSlice({
    name: "dashboard-admin-reducer",
    initialState: dashboardAdminInitialState,
    reducers: {
      updateAdminUserListModel:(state, action:PayloadAction<DashboardAdminFilterUsersRequestModel>) => {
        state.dashboardAdminDocumentsUserRequestModel = action.payload;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getuserListDashboardAdminThunk.pending, (state) => {
          state.dashboardAdminDocumentsUserModelResponseData.status = AsyncStatusEnum.loading;
        })
        .addCase(getuserListDashboardAdminThunk.rejected, (state, action) => {
          state.dashboardAdminDocumentsUserModelResponseData.status = AsyncStatusEnum.rejected;
          state.dashboardAdminDocumentsUserModelResponseData.error = {
            error: true,
            message: 'Error rejected',
          };
          state.dashboardAdminDocumentsUserModelResponseData.dashboardAdminUserListData = dashboardAdminInitialState.dashboardAdminDocumentsUserModelResponseData.dashboardAdminUserListData
        })
        .addCase(getuserListDashboardAdminThunk.fulfilled, (state, action: PayloadAction<DashboardAdminFilterUsersResponseModel>) => {
          state.dashboardAdminDocumentsUserModelResponseData.status = AsyncStatusEnum.completed;
          state.dashboardAdminDocumentsUserModelResponseData.error = {
            error: false,
            message: '',
          };
          state.dashboardAdminDocumentsUserModelResponseData.dashboardAdminUserListData = action.payload;
        })
    },
  });
  
  export const { updateAdminUserListModel } = dashboardAdminReducer.actions;
  export default dashboardAdminReducer.reducer;