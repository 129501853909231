import {get} from './Api'

export class CategoryService {
    
    
    /**
     * Metodo para obtener todas las Categorias 
     * @param token {token de acceso del usuario}
     * @param queryParams {Parametros opcionales para la consulta}
     * @returns Lista de todas Categorias
     */
    static getAll(token:string, queryParams = {}) {
        return get(`${process.env.REACT_APP_API_URL}Category/GetAll`, token, queryParams)
    }

    /**
     * Metodo para obtener una Categoria dado su Id
     * @param id {Id de la Categoria a consultar}
     * @param token {token de acceso del usuario}
     * @returns Detalle de una Categoria
     */
    static getById(id:number, token:string) {
        return get(`${process.env.REACT_APP_API_URL}Category/details/${id}`, token)
    }
}