import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ValuesElementModel } from "../../../commons/redux-state/models/values-elements-model";
import { InputValueInitialState } from "../models/input-value-initial-state-model";

export const inputValueInitialState: InputValueInitialState = {
    valueElement: null
};

export const inputValueReducer = createSlice({
  name: "input-value-reducer",
  initialState: inputValueInitialState,
  reducers: {
    updateInputValue:(state, action: PayloadAction<ValuesElementModel>) => {
        state.valueElement = action.payload;
    },
}});

export const { updateInputValue } = inputValueReducer.actions;
export default inputValueReducer.reducer;