import React, { useEffect } from "react";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import AddTitleMododalComponent from "../../../lawyer/components/add-title-modal-component/add-title-modal-component";
import CleanDocumentAdviceComponent from "../../../lawyer/components/clean-document-advice-component/clean-document-advice-component";
import DeleteTitleWarningModalComponent from "../../../lawyer/components/delete-title-warning-modal-component/delete-title-warning-modal-component";
import MainStackContainerComponent from "../../../lawyer/components/main-stack-container-component/main-stack-container-component";
import { ParagraphBuildTypeEnum } from "../../../lawyer/enums/paragraph-build-type-enum";
import { updateBuildType } from "../../../lawyer/redux-state/reducers/paragraph-build-reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Layout } from "../../common/Layout";
import FooterComponent from "../../common/footer-component/footer-component";
import "./document-template-creation-lawyer-view.scss";


//vista principal para crear y editar una plantilla
const DocumentTemplateCreationLawyerView = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateBuildType(ParagraphBuildTypeEnum.creation));
  },[]);

  const firstLevelSections = useAppSelector(state => state.documentBuildReducer.firstLevelSections);
  const documentTemplateData = useAppSelector(state => state.naturalPersonReducer.documentTemplateData.documentTemplate?.data);
  const status = useAppSelector(state => state.documentTemplateAdapterToEditReducer.documentTemplateTrasnformedData.status);

  return (
    <Layout>
        <section className="diligenciamiento mt-50">
          <div className="container">
            <h3 className="title green">{documentTemplateData?.name}</h3>

            {status ===AsyncStatusEnum.loading && <div><div className="spinner"></div></div> }
            {firstLevelSections.length === 0 && status !== AsyncStatusEnum.loading && <CleanDocumentAdviceComponent />}
            {firstLevelSections.length !== 0 && <MainStackContainerComponent />}
            
            <AddTitleMododalComponent />
            <DeleteTitleWarningModalComponent />
          </div>
        </section>
        <FooterComponent />
    </Layout>
  );
}

export default DocumentTemplateCreationLawyerView;
