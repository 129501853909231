import { ClickAwayListener } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Paragraph, SecondLevelSectionModel } from "../../redux-state/models/first-level-section-model";
import "./second-level-paragraph-component.scss";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteParagraphWarningModalComponent from "../delete-paragraph-warning-modal-component/delete-paragraph-warning-modal-component";
import { DocumentBuildService } from "../../services/document-build-service";
import { changeSecondLevelChildrenDownwardOrder, changeSecondLevelChildrenUpwardOrder, updateChildrenToHiglight, updateParagraphMetadata, updateSecondLevelChildrenToHiglight } from "../../redux-state/reducers/document-build-reducer";
import DeleteSecondLevelParagraphModalComponent from "../delete-second-level-paragraph-modal-component/delete-second-level-paragraph-modal-component";
import { feedPreviewChildren, updateBuildType } from "../../redux-state/reducers/paragraph-build-reducer";
import { ParagraphBuildTypeEnum } from "../../enums/paragraph-build-type-enum";

interface SecondLevelParagraphProps {
    paragraph: Paragraph | SecondLevelSectionModel
}

const SecondLevelParagraphComponent:FC<SecondLevelParagraphProps> = ({paragraph}) => {

    const navigate = useNavigate();

    const firstLevelSections = useAppSelector(state => state.documentBuildReducer.firstLevelSections); 
    const secondLevelChildrenToHiglight = useAppSelector(state => state.documentBuildReducer.SecondLevelChildrenToHiglight); 
    const dispatch = useAppDispatch();

    const [showDeleteParagraph, setShowDeleteParagraph] = useState<boolean>(false);

    const secondLevelParagraphRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(
            secondLevelChildrenToHiglight?.childrenId === paragraph.selfPositionId &&
            secondLevelChildrenToHiglight?.firstLevelParentPositionId === paragraph.firstLevelParentPositionId &&
            secondLevelChildrenToHiglight?.secondLevelParentPositionId === paragraph.secondLevelParentPositionId
        ) {
            secondLevelParagraphRef.current!.style.background = "#9bd2dd";
        } 
    }, []);

    //se ejecuta cuando se hace click en la flecha hacia arriba del parrafo
    const handleArrowUpClick = () => {
        //cambiando y actualizando la posición de los párrafos
        dispatch(changeSecondLevelChildrenUpwardOrder({firstLevelParentPositionId: paragraph.firstLevelParentPositionId, secondLevelParentPositionId: Number(paragraph.secondLevelParentPositionId), selfPositionId: paragraph.selfPositionId}));
        dispatch(updateSecondLevelChildrenToHiglight({childrenId: paragraph.selfPositionId - 1, firstLevelParentPositionId: paragraph.firstLevelParentPositionId, secondLevelParentPositionId: Number(paragraph.secondLevelParentPositionId)}));
        dispatch(updateChildrenToHiglight(null));
    }

    //se ejecuta cuando se hace click en la flecha hacia abajo del parrafo
    const handleArrowDownClick = () => {
        //cambiando y actualizando la posición de los párrafos
        dispatch(changeSecondLevelChildrenDownwardOrder({firstLevelParentPositionId: paragraph.firstLevelParentPositionId, secondLevelParentPositionId: Number(paragraph.secondLevelParentPositionId), selfPositionId: paragraph.selfPositionId}));
        dispatch(updateSecondLevelChildrenToHiglight({childrenId: paragraph.selfPositionId + 1, firstLevelParentPositionId: paragraph.firstLevelParentPositionId, secondLevelParentPositionId: Number(paragraph.secondLevelParentPositionId)}));
        dispatch(updateChildrenToHiglight(null));
    }

    //se ejecuta cuando se hace click en cualquier parte fuera del parrafo
    const handleClickOutsideParagraph = () => {
        secondLevelParagraphRef.current!.style.background = "none";
        dispatch(updateSecondLevelChildrenToHiglight(null));
        dispatch(updateChildrenToHiglight(null));
    }

    //Elimina un parrafo
    const handleDeleteButtonClick = () => {
        setShowDeleteParagraph(true);
    }

    const handleEditButtonClick = () => {
        dispatch(updateParagraphMetadata({
            parentType: paragraph.parentType,
            parentText: firstLevelSections[paragraph.firstLevelParentPositionId].children[Number(paragraph.secondLevelParentPositionId)].title!.titleText,
            firstLevelParentPositionId: paragraph.firstLevelParentPositionId,
            secondLevelParentPositionId: Number(paragraph.secondLevelParentPositionId),
            selfPositionId: paragraph.selfPositionId
        }));
        dispatch(feedPreviewChildren(paragraph.content));
        dispatch(updateBuildType(ParagraphBuildTypeEnum.edition));
        dispatch(updateSecondLevelChildrenToHiglight({childrenId: paragraph.selfPositionId, firstLevelParentPositionId: paragraph.firstLevelParentPositionId, secondLevelParentPositionId: Number(paragraph.secondLevelParentPositionId)}));
        dispatch(updateChildrenToHiglight(null));
        navigate("/paragraph-build");
    }

    return (
        <ClickAwayListener onClickAway={handleClickOutsideParagraph}>
            <div className="second-level-paragraph" ref={secondLevelParagraphRef}>

                <div className="second-level-paragraph__arrow-container">

                    { DocumentBuildService.showUpArrowInChild(paragraph) && <button onClick={handleArrowUpClick} className="second-level-paragraph__arrow" ><KeyboardArrowUpIcon sx={{color: "#565656", "&:hover": {color: "white"}}}/></button>}
                    { DocumentBuildService.showDownArrowInChild(firstLevelSections, paragraph) && <button onClick={handleArrowDownClick} className="second-level-paragraph__arrow"><KeyboardArrowDownIcon sx={{color: "#565656", "&:hover": {color: "white"}}}/></button>}

                </div>

                <div className="second-level-paragraph__main">
                    {paragraph.content.map(element => element)}
                </div>

                <div className="second-level-paragraph__button-bar">
                    <button className="second-level-paragraph__edit-button" onClick={handleEditButtonClick}><ModeEditOutlineOutlinedIcon sx={{color: "white"}}/></button>
                    <button className="second-level-paragraph__delete-button" onClick={handleDeleteButtonClick}><DeleteOutlineOutlinedIcon sx={{color: "white"}}/></button>
                </div>

                {   
                    showDeleteParagraph && <DeleteSecondLevelParagraphModalComponent 
                    setShowDeleteParagraph={setShowDeleteParagraph} 
                    paragraph={paragraph}
                    />
                }

            </div>
        </ClickAwayListener>
    )
}

export default SecondLevelParagraphComponent;