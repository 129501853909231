import { Clause, FirstLevelChild, Section } from '../redux-state/models/document-template-response-model';
//Servicio que toma parrafos y subtitulos y los ordena en un nuevo arreglo segun su posicion

export class SortingService {
    public static sortChildren(clauses:Clause[], subsections:Section[]):FirstLevelChild[] {
        
        const listFirstLevelChild: Array<FirstLevelChild> = [];

        clauses.forEach(elementClause => {
            const firstLevelChild: FirstLevelChild = {
                clause: elementClause,
                position: elementClause.position,
                childType: "clause"
            }
            listFirstLevelChild.push(firstLevelChild)
        });    
        
        subsections.forEach(elementSubSection => {
            const firstLevelChild: FirstLevelChild = {
              section: elementSubSection,
              position: elementSubSection.sectionNumber,
              childType: "subsection"
            }
            listFirstLevelChild.push(firstLevelChild)
        });

        listFirstLevelChild.sort((x, y) => x.position - y.position)

        return listFirstLevelChild;
    }  
};