import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context";
import Logo from "../../../assets/images/logo.svg";
import { GLOBALS } from "../../../utils/Globals";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getUserInfoThunk } from "../../../user/redux-state/thunk/get-user-info";
import "./header-menu-component.scss";
import { Navigate } from "react-router-dom";

function logout() {
  var oidcUser = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`)!);
  sessionStorage.clear();
  window.location.replace(`${process.env.REACT_APP_END_SESSION_URL}?id_token_hint=${oidcUser.id_token}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`);
}

function HeaderMenuComponent() {
  const { isAuth: token, userLogged } = useContext(Context);
  const { status } = useAppSelector((state) => state.userReducer.userModelResponseData);
  const { userModelResponseData } = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  const greeting = `¡${GLOBALS.textResources.greeting.es} ${userModelResponseData.userResponseModel?.data.firstName}!`
  const [activeMenu, setActiveMenu] = useState("")
  const [redirectTo, setRedirectTo]: any = useState(null);

  useEffect(() => {
    dispatch(getUserInfoThunk({ id: Number(userLogged.profile.sub), token: token }))
  }, [])

  const handleClick = (classname: string) => {
    setActiveMenu(classname)
  }

  //Metodo para invocar mensaje de acuerdo al plan
  const PlanMessage = () => {
    const planDays = userModelResponseData.userResponseModel?.data.planDays!
    const planActive = userModelResponseData.userResponseModel?.data.planActive!

    return <div className="vigencia d-flex">
      <div className="circle">
        <span className="icon-icon-hourglass"></span>
      </div>
      <p>
        Vigencia de tu plan:
        <br />
        <strong>
          {userModelResponseData.userResponseModel?.data.planActive! ? `${userModelResponseData.userResponseModel?.data.planDays!} Dias` : "Plan vencido"  }
        </strong>
      </p>
    </div>
  }

  //Metodo que redirecciona a las diferentes vistas desde el menu
  const redirectMenuUrls = (menuUrl: string) => {
    setRedirectTo({
      pathname: menuUrl,
      state: { succes: true },
    })
  }

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <header id="headerMenu">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md-3 menu">
            <div onClick={() => handleClick("active")} className="circle">
              <span className="icon-icon-menu"></span>
            </div>
            <a href="/">
              <img src={Logo} alt="Anuwa" width="196" height="46" />
            </a>
          </div>
          <div className="col-12 col-md-4 menu">
            <a href="/">
              <div className="profile d-flex">
                <div className="circle">
                  <span className="icon-icon-user"></span>
                </div>
                <p>{greeting}</p>
              </div>
            </a>
            <PlanMessage />
            <a onClick={logout}>
              <div className="logout">
                <div className="circle">
                  <span className="icon-icon-logout"></span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <nav className={activeMenu}>
        <button onClick={() => handleClick("")} >
          <span className="icon-icon-close"></span>
        </button>
        <a href="/" className="nav-item">
          <div className="circle">
            <i className="icon-icon-home"></i>
          </div>
          <span>Home</span>
        </a>
        <a href="/" className="nav-item">
          <div className="circle">
            <i className="icon-icon-library-2"></i>
          </div>
          <span>Mis documentos</span>
        </a>
        <a href="/new-document" className="nav-item">
          <div className="circle">
            <i className="icon-new-document"></i>
          </div>
          <span>Crear documento</span>
        </a>
        {userLogged.profile.role === GLOBALS.roles.admin.roleName && <a href="/dashboard-admin" className="nav-item">
          <div className="circle">
            <i className="icon-icon-pencil"></i>
          </div>
          <span>Actualizar Plan</span>
        </a>}

      </nav>
    </header>
  );
}

export default HeaderMenuComponent;
