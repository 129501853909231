import { CategoryModel } from '../redux-state/models/category-model';
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";

export class CategoryService {
 /**
   * Metodo para obtener categorias
   * @param token {token de acceso del usuario}
   * @returns todas las categorias
   */

  static async getCategories(token: string): Promise<CategoryModel|null> {
    try 
    {
        const response = await fetch(`${process.env.REACT_APP_API_URL}Category/getAll`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const categories: CategoryModel = await response.json();
      return categories;
    } catch (error) {
        return null
    }
  }


    /**
   * Metodo para obtener una categoria por el Id
   * @param categoryId {Id de la categoria}
   * @param token {token de acceso del usuario}
   * @returns categoria
   */

     static async getCategoryById(categoryId: number, token: string): Promise<CategoryModel|null> {
      try 
      {
          const response = await fetch(`${process.env.REACT_APP_API_URL}Category/details/${categoryId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //validate token with status 401
        ValidateUserSessionService.handleError(response.status);
        const category: CategoryModel = await response.json();
        return category;
      } catch (error) {
          return null
      }
    }

}