async function request(
  method: string,
  url: string,
  token: string,
  data = {},
  encode: boolean
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "",
  };

  let body: any = null;
  if (method !== "GET") {
    body = encode ? JSON.stringify(data) : data;
  } else {
    const params = new URLSearchParams(data).toString();
    if (params) {
      url = url + "?" + params;
    }
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const res = await window.fetch(url, {
    method: method,
    headers,
    body: body,
  });

  const json = await res.json();

  if (!res.ok) {
    if (json.message) {
      throw new Error(json.message);
    } else {
      if (Array.isArray(json)) {
        throw new Error(json.map((e) => e.message || "Error").join(". "));
      }
      throw new Error("Ocurrió un error");
    }
  }

  return json;
}

export function get(url: string, token: string, queryParams = {}) {
  return request("GET", url, token, queryParams, false);
}

export function post(url: string, data = {}, encode: boolean, token: string) {
  return request("POST", url, token, data, encode);
}

export function put(url: string, data: {}, encode: boolean, token: string) {
  return request("PUT", url, token, data, encode);
}
