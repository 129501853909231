import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAdminService } from "../../services/dashboard-admin-service";
import { DashboardAdminUserStatusRequestModel } from "../models/dashboard-admin-user-status-request-model";

interface payload {
    nextBillingDate: DashboardAdminUserStatusRequestModel,
    token: string,
  }
//Acitva un usuario
export const activeUserDashboardAdminThunk = createAsyncThunk(
    "dashboard-admin/active-user-dashboard-admin-thunk",
    async (payload:payload, { rejectWithValue }) => {
      const userPlanDataResponse = await DashboardAdminService.activePlan(payload.nextBillingDate, payload.token);
      if (userPlanDataResponse){
        return userPlanDataResponse;
      }else return rejectWithValue(null); 
    }
  );
  