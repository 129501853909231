import React, { FC, useRef } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { TitleSubtitleModel } from "../../redux-state/models/title-subtitle-model";
import "./edit-subtitle-modal-component.scss";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Checkbox, Dialog, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import { EditSubtitleModalModel } from "../../redux-state/models/edit-subtitle-modal-model";
import { updateAllSubtitleData } from "../../redux-state/reducers/document-build-reducer";

interface EditSubtitleProps {
    setShowEditModal: any;
    titleText: string;
    tooltip: boolean;
    tooltipText: string;
    selfPositionId: number;
    parentPositionId: number;
}

const EditSubtitleModalComponent:FC<EditSubtitleProps> = ({setShowEditModal, titleText, tooltip, tooltipText, selfPositionId, parentPositionId}) => {

    /* Variables de colores */
    const blue = "#004571";
    const purple = "#82389A";

    const dispatch = useAppDispatch();
    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: TitleSubtitleModel = {
        titleText: titleText,
        tooltip: tooltip,
        tooltipText: tooltipText
    }

    const validationSchema = yup.object({
        titleText: yup.string()
        .max(300, "El nombre del subtítulo debe ser maximo de 300 caracteres")
        .min(1, "El nombre del subtítulo debe ser mínimo de 1 caracteres")
        .required("El nombre del subtítulo es requerido"),
        tooltip: yup.boolean()
        .required(""),
        tooltipText: yup.string()
        .max(300, "El texto debe ser maximo de 300 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres"),
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  

            const newSubtitleData: EditSubtitleModalModel = {
                titleText: values.titleText,
                tooltip: values.tooltip,
                tooltipText: values.tooltipText,
                selfPositionId: selfPositionId,
                parentPositionId: parentPositionId,
            }
        
            dispatch(updateAllSubtitleData(newSubtitleData));

            setShowEditModal(false);

            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    const handleCancelButtonClick = () => {
        form.resetForm();
        setShowEditModal(false);
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle sx={{background: blue, color: "white"}}>Modifique la información para editar el subtítulo</DialogTitle>

            <div className="edit-subtítle-modal">
                
                <form onSubmit={form.handleSubmit} ref={formRef}>

                    <div>
                        <TextField 
                            variant="outlined"
                            label="Nombre del subtítulo"
                            style = {{background: "white"}}
                            id="titleText"
                            name="titleText"
                            defaultValue={form.values.titleText}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                        {form.errors.titleText && form.touched.titleText && <p className="validation-error">{form.errors.titleText}</p>}
                    </div>

                    <FormControlLabel control={<Checkbox id="tooltip" name="tooltip" checked={form.values.tooltip} onChange={form.handleChange}/>} label="Desea añadir autoayuda?" />

                    <div>
                        <TextField 
                            variant="outlined"
                            label="Ingerse texto de la autoayuda"
                            style = {{background: "white"}}
                            id="tooltipText"
                            name="tooltipText"
                            defaultValue={form.values.tooltipText}
                            disabled={!form.values.tooltip}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                        {form.errors.tooltipText && form.touched.tooltipText && <p className="validation-error">{form.errors.tooltipText}</p>}
                    </div>

                    <div className="edit-subtítle-modal__actions-bar">
                        <button className="edit-subtítle-modal__back-button" onClick={handleCancelButtonClick} type="button">Cancelar</button>
                        <button className="edit-subtítle-modal__forward-button" type="submit">Guardar</button>
                    </div>
                    

                    </form>

                </div>
        
        </Dialog>
    )
}

export default EditSubtitleModalComponent;