export class ValidateUserSessionService {
  /**
   * delete session storage and redirect to front page to init Open Connect Id Protocol with back
   */
  static deleteUserSession() {
    sessionStorage.clear();        
    window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);    
  }

  static handleError(status: number){
    if(status === 401){
      this.deleteUserSession();
      return;
    }
  }
}