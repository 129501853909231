import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { DashboardAminUserStatusInitialStateModel } from "../models/dashboard-admin-user-status-initial-state-model";
import { DashboardAdminUserStatusResponseModel } from "../models/dashboard-admin-user-status-response-model";
import { activeUserDashboardAdminThunk } from "../thunks/active-user-dashboard-admin-thunk";

export const dashboardAdminUserStatusInitialState: DashboardAminUserStatusInitialStateModel = {

    dashboardAdminUserStatusModelResponseData: {
        statusUser: AsyncStatusEnum.idle,
        dashboardAdminUserStatusData:  null,
        error: { 
          error: false,
          message: "",
        }
      },
      dashboardAdminUserStatusModelRequestData: null

}

//dashboard reducer, para mostrar activar usuarios
export const activeUserDashboardAdminReducer = createSlice({
    name: "dashboard-admin-reducer",
    initialState: dashboardAdminUserStatusInitialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(activeUserDashboardAdminThunk.pending, (state) => {
          state.dashboardAdminUserStatusModelResponseData.statusUser = AsyncStatusEnum.loading;
        })
        .addCase(activeUserDashboardAdminThunk.rejected, (state, action) => {
          state.dashboardAdminUserStatusModelResponseData.statusUser = AsyncStatusEnum.rejected;
          state.dashboardAdminUserStatusModelResponseData.error = {
            error: true,
            message: 'Error rejected',
          };
          state.dashboardAdminUserStatusModelResponseData.dashboardAdminUserStatusData = dashboardAdminUserStatusInitialState.dashboardAdminUserStatusModelResponseData.dashboardAdminUserStatusData
        })
        .addCase(activeUserDashboardAdminThunk.fulfilled, (state, action: PayloadAction<DashboardAdminUserStatusResponseModel>) => {
          state.dashboardAdminUserStatusModelResponseData.statusUser = AsyncStatusEnum.completed;
          state.dashboardAdminUserStatusModelResponseData.error = {
            error: false,
            message: '',
          };
          state.dashboardAdminUserStatusModelResponseData.dashboardAdminUserStatusData = action.payload;
        })
    },
  });
  
  export default activeUserDashboardAdminReducer.reducer;