import React from "react";
import { Routes, Route } from "react-router-dom";
import { GLOBALS } from "../utils/Globals";
import HeaderLoginComponent from "../components/common/header-login-component/header-login-component";
import HeaderMenuComponent from "../components/common/header-menu-component/header-menu-component";
import DashboardView from "../components/views/dashboard-view/dashboard-view";
import PageErrorView from "../components/views/page-error-view/page-error-view";
import TemplateView from "../components/views/template-view/template-view";
import DocumentTemplateCreationView from "../components/views/document-template-creation-view/document-template-creation-view";
import PreviewTemplateView from "../components/views/preview-template-view/preview-template-view";
import FormManagement from '../components/views/Form/FormManagement'
import DocumentTemplateEditView from "../components/views/document-template-creation-lawyer-view/document-template-creation-lawyer-view";
import DynamicParagraphBuild from "../components/views/dynamic-paragraph-build-view/dynamic-paragraph-build-view";
import TermsConditions from "../components/views/terms-and-conditions/terms-conditions";
import DataProcesing from "../components/views/data-processing-policy/data-processing-policy";
import FooterComponent from "../components/common/footer-component/footer-component";
import DashboardAdminView from "../components/views/dashboard-admin-view/dashboard-admin-view";

type props = {
  userLogged: any;
};

export const Rout = ({ userLogged }: props) => (
  <>
    {
      (userLogged ?
        (userLogged.userLogged.profile.role === GLOBALS.roles.lawyer.roleName ||
          userLogged.userLogged.profile.role === GLOBALS.roles.admin.roleName ||
          userLogged.userLogged.profile.role === GLOBALS.roles.naturalPerson.roleName) && (
          <Routes>
            {userLogged.userLogged.profile.role === GLOBALS.roles.admin.roleName &&             
            <Route path={GLOBALS.menu.dashboardAdmin.link}
              element={
                <>
                  <HeaderMenuComponent />
                  <DashboardAdminView />
                  <FooterComponent />
                </>
              }
            />
            
            }
            <Route path="/login" element={<HeaderLoginComponent />}></Route>

            <Route path="/wrong-page" element={
              <>
                <HeaderMenuComponent />
                <PageErrorView />
                <FooterComponent />
              </>
            }>
            </Route>

            <Route path={GLOBALS.menu.dashboard.link}
              element={
                <>
                  <HeaderMenuComponent />
                  <DashboardView />
                  <FooterComponent />
                </>
              }
            />

            <Route path={GLOBALS.menu.template.link} element={<TemplateView />} />

            <Route
              path={`${GLOBALS.menu.template.link}/create`}
              element={<DocumentTemplateCreationView />}
            />
            <Route
              path={`${GLOBALS.menu.template.link}/preview`}
              element={<PreviewTemplateView />}
            />
            <Route
              path={`${GLOBALS.menu.editTemplateLawyer.link}`}
              element={<DocumentTemplateEditView />}
            />
            <Route
              path={`${GLOBALS.menu.dynamicParagraphBuild.link}`}
              element={<DynamicParagraphBuild />}
            />

            <Route path="/form" element={<FormManagement />}/>

            <Route path={GLOBALS.menu.termnsconditions.link} element={<TermsConditions/>} />

            <Route path={GLOBALS.menu.dataprocesing.link} element={<DataProcesing/>} />

          </Routes>) :

        <Routes>
          <Route path={GLOBALS.menu.termnsconditions.link} element={<TermsConditions />} />
          <Route path={GLOBALS.menu.dataprocesing.link} element={<DataProcesing />} />
        </Routes>
      )}

  </>
);
