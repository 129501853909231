import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { DocumentTemplateResponseModel } from "../../../commons/redux-state/models/document-template-response-model";
import { DocumentTemplateAdapterToFillInitialStateModel } from "../models/document-template-adapter-to-fill-out-initial-state";
import { getDocumentTemplateAdapterToFillThunk } from "../thunk/get-document-template-adapter-to-fill-out";

export const documentTemplateAdapterToFillInitialState: DocumentTemplateAdapterToFillInitialStateModel = {
    documentTemplateTrasnformedData: {
        status: AsyncStatusEnum.idle,
        documentTemplateTransformed: null,
        error: {
            error: false,
            message: "",
        },
    },
};

 export const documentTemplateAdapterToFillReducer = createSlice({
    name: "document-template-adapter-to-fill-reducer",
    initialState: documentTemplateAdapterToFillInitialState ,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
      .addCase(getDocumentTemplateAdapterToFillThunk.fulfilled, (state, action:PayloadAction<DocumentTemplateResponseModel>) => {
        state.documentTemplateTrasnformedData.status = AsyncStatusEnum.completed;
        state.documentTemplateTrasnformedData.documentTemplateTransformed = action.payload;
        state.documentTemplateTrasnformedData.error = {
          error: false,
          message: '',
        };
      })
      .addCase(getDocumentTemplateAdapterToFillThunk.pending, (state) => {
        state.documentTemplateTrasnformedData.status = AsyncStatusEnum.loading;
      })
      .addCase(getDocumentTemplateAdapterToFillThunk.rejected, (state, action) => {
        state.documentTemplateTrasnformedData.documentTemplateTransformed = documentTemplateAdapterToFillInitialState.documentTemplateTrasnformedData.documentTemplateTransformed;
        state.documentTemplateTrasnformedData.status = AsyncStatusEnum.rejected;
        state.documentTemplateTrasnformedData.error = {
          error: true,
          message: 'Error rejected',
        };
      })
    },
  });
  
  export default documentTemplateAdapterToFillReducer.reducer;
  
