import React, {useState} from "react";
import "./App.scss";
import { Rout } from "./config/Router";
import { AuthConfig } from "./config/authConfig";
import AuthenticateOpenId from "./components/auth/AuthenticateOpenId";
import { AuthProvider } from "./components/auth/AuthProvider";
import { Context } from "./Context";
import { Route, Routes } from "react-router-dom";
import { GLOBALS } from "./utils/Globals";
import TermsConditions from "./components/views/terms-and-conditions/terms-conditions";
import DataProcesing from "./components/views/data-processing-policy/data-processing-policy";

const App = () => {
  const [user, setUser] = useState<any>(undefined)

  const userLoaded = (user: any) => {
    if (user) {
      setUser(user);
    }
  }
  
  const userUnLoaded = () => {
    setUser(undefined)
  }

  const NotAuthenticated = () => {
    return (
      <div>
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    window.location.href.includes("termnsandconditions") || window.location.href.includes("dataprocessingpolicy") ?
     <Routes>
       <Route path={GLOBALS.menu.termnsconditions.link} element={<TermsConditions />} />
       <Route path={GLOBALS.menu.dataprocesing.link} element={<DataProcesing />} />
     </Routes> :
     <AuthenticateOpenId
       OidcSettings={AuthConfig}
       userLoaded={userLoaded}
       userunLoaded={userUnLoaded}
       renderNotAuthenticated={NotAuthenticated}
     >
       <AuthProvider>
         <Context.Consumer>
           {
             (userLogged) => <Rout userLogged={userLogged} />
           }

         </Context.Consumer>
       </AuthProvider>
     </AuthenticateOpenId>
 );
}

export default App;
