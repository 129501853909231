import React, { FC } from "react";
import "./delete-paragraph-warning-modal-component.scss";
import { Dialog, DialogTitle } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Paragraph, SecondLevelSectionModel } from "../../redux-state/models/first-level-section-model";
import { deleteFirstLevelParagraph } from "../../redux-state/reducers/document-build-reducer";
import { useAppDispatch } from "../../../redux/hooks";

interface DeleteParagraphWarningProps {
    paragraph: Paragraph | SecondLevelSectionModel;
    setShowDeleteParagraph: any;
    showDeleteParagraph: boolean;
}

const DeleteParagraphWarningModalComponent:FC<DeleteParagraphWarningProps> = ({paragraph, setShowDeleteParagraph, showDeleteParagraph}) => {

    /* Variables de colores */
    const blue = "#004571";

    const dispatch = useAppDispatch();
    const handleCancelButtonClick = () => {
        setShowDeleteParagraph(false);
    }

    const handleDeleteButtonClick = () => {
        dispatch(deleteFirstLevelParagraph({
            firstLevelParentPositionId: paragraph.firstLevelParentPositionId,
            selfPositionId: paragraph.selfPositionId
        }))
    }

    return (
     <Dialog open={showDeleteParagraph} maxWidth={"sm"} fullWidth={true}>
         <DialogTitle sx={{background: blue, color: "white"}}>Atención!</DialogTitle>

         <div className="delete-paragraph-modal">

            <div className="delete-paragraph-modal__message">
                <WarningAmberIcon sx={{fontSize: "70px", color: blue}}/>
                <p>Está seguro que desea eliminar este párrafo y todo su contenido?</p> 
            </div>

            <div className="delete-paragraph-modal__button-bar">
                <button className="delete-paragraph-modal__back-button" onClick={handleCancelButtonClick}>Cancelar</button>
                <button className="delete-paragraph-modal__forward-button"onClick={handleDeleteButtonClick}>Eliminar</button>
            </div>

         </div>
     </Dialog>
    )
}

export default DeleteParagraphWarningModalComponent;