import { Dialog, DialogTitle } from "@mui/material";
import React, { FC } from "react";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import "./final-document-warning-modal-component.scss"

interface FinalDocumentWarningProps {
    setShowFinalDocumentModal:any;
}

//Implementa modal de advertencia para edicion de documento
const FinalDocumentWarningModalComponent: FC<FinalDocumentWarningProps> = ({setShowFinalDocumentModal}) => {

    const handleCancelButtonClick = () => {
        setShowFinalDocumentModal(false);
    }

    const handleDeleteButtonClick = () => {
        setShowFinalDocumentModal(false);
    }

    return (
     <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
         <DialogTitle sx={{background: "#D3A237", color: "white"}}>Atención!</DialogTitle>

         <div className="final-document-modal">

            <div className="final-document-modal__message">
                <WarningAmberIcon sx={{fontSize: "70px", color: "#f0ad4e"}}/>
                <p>Al hacer click en continuar podra seguir editando los campos del documento, pero no sera posible modificar la estructura de la plantilla. <br/> ¿Esta seguro que desea continuar?</p>
            </div>

            <div className="final-document-modal__button-bar">
                <button className="final-document-modal__back-button" onClick={handleCancelButtonClick}>Cerrar</button>
                <button className="final-document-modal__forward-button" onClick={handleDeleteButtonClick}>Estoy seguro</button>
            </div>

         </div>
     </Dialog>
    )

}

export default FinalDocumentWarningModalComponent