import { store } from "../../redux/store"
import { v4 as uuidv4 } from 'uuid';
import { FirstLevelSectionModel, Paragraph, SecondLevelSectionModel } from "../redux-state/models/first-level-section-model";
import { ParentTypeEnum } from "../enums/parent-type-enum";

export class DocumentBuildService {

  //Define si un parrafo o un subtítulo debe mostrar la flecha de posicionamiento hacia arriba
  static showUpArrowInChild(child: Paragraph | SecondLevelSectionModel): boolean{
    if(child.selfPositionId !== 0) return true;
    else return false;
  }

  //Define si un parrafo o un subtítulo debe mostrar la flecha de posicionamiento hacia abajo
  static showDownArrowInChild(firstLevelSection: FirstLevelSectionModel[], child: Paragraph | SecondLevelSectionModel): boolean {
    switch(child.parentType){
      case ParentTypeEnum.title:
        const children: Array<Paragraph | SecondLevelSectionModel> = firstLevelSection[child.firstLevelParentPositionId].children;
        const lastChildPosition: number = children.length - 1;
        if(child.selfPositionId === lastChildPosition) return false;
        else return true;
      case ParentTypeEnum.subTitle:
        const SubtitleChildren: Array<Paragraph | SecondLevelSectionModel> = firstLevelSection[child.firstLevelParentPositionId].children[child.secondLevelParentPositionId!].content;
        const lastSubtitleChildPosition: number = SubtitleChildren.length - 1;
        if(child.selfPositionId === lastSubtitleChildPosition) return false;
        else return true;
    }
  }

  //retorna el id que se usa como identificador/posición de una nueva sección/titulo
  static getTitlePositionId(): number {
      const firstLevelSections = store.getState().documentBuildReducer.firstLevelSections;
      return firstLevelSections.length;
  }

  //retorna el id que se usa como selfPosition de un nuevo parrafo o subtitulo
  static getFirstLevelchildSelfPosition(parentpositionId: number): number {
    const firstLevelChildren = store.getState().documentBuildReducer.firstLevelSections[parentpositionId].children;
    return firstLevelChildren.length;
  }

  //retorna el id que se usa como selfPosition de un nuevo párrafo dentro de un subtítulo
  static getSecondLevelChildSelfPosition(firstLevelPositionId: number, secondLevelPositionId: number): number {
    const secondLevelChildren = store.getState().documentBuildReducer.firstLevelSections[firstLevelPositionId].children[secondLevelPositionId].content;
    return secondLevelChildren.length;
  }

  //genera un id aleatorio para los elementos del dom usando la librería de npm uuid
  static generateRandomId(): string {
    return uuidv4();
  }

  //convierte entros en número romanos
  static convertIntToRoman(num: number) {
      let roman: any = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1
      };

      let str = '';
    
      for (var i of Object.keys(roman)) {
        var q = Math.floor(num / roman[i]);
        num -= q * roman[i];
        str += i.repeat(q);
      }
    
      return str;
  }
}