import TextField from "@mui/material/TextField";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import "../../sass/paragraph-build-service.scss";
import { DatePickerFormModel, DropdownFormModel, TextBoxFormModel, TextFieldFormModel } from "../redux-state/models/text-form-model";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { v4 as uuidv4 } from 'uuid';
import { FC, useState } from "react";
import { ElementTypeEnum } from "../enums/element-type-enum";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

//componente que retorna un parrafo de html
interface paragraphProps {
    elementType: ElementTypeEnum.paragraph;
    innerText: string;
}
const  CustomTextComponent:FC<paragraphProps> = ({innerText, elementType}) => {
    return <p className="paragraph-build-text">{innerText}</p>
}



//retorna un control textField de material UI
interface CustomTextFieldInputProps {
    elementType: ElementTypeEnum.textField;
    label: string;
    tooltip: boolean;
    tooltipText: string;
    required: boolean;
    defaultValue: string;
}
const CustomTextFieldInputComponent:FC<CustomTextFieldInputProps> = ({elementType, tooltip, tooltipText, label, required, defaultValue}) => {
    return (
        <div className="paragraph-build-input-text">

            <TextField
                variant="outlined"
                label={label}
                defaultValue={defaultValue ? defaultValue : ""}
                style = {{width: 200, background: "white"}}
            />
            
            {   
                tooltip &&
                <Tooltip title={tooltipText} arrow>
                <IconButton>
                    <HelpOutlineIcon />
                </IconButton>
                </Tooltip>
            }

        </div>
    )
}


//retorna un selector de fecha de material UI
interface CustomDateInputProps {
    elementType: ElementTypeEnum.dateField
    label: string;
    tooltip: boolean;
    tooltipText: string;
    required: boolean;
}
////retorna un selector de fecha de material UI
const CusttomDateInputComponent:FC<CustomDateInputProps> = ({elementType,label, tooltip, tooltipText, required}) => {

    const [value, setValue] = useState<Dayjs | null>(null);
    return (
        <div className="paragraph-build-input-text">

            <LocalizationProvider adapterLocale={"es"} className="calendar" dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label={label}
                    inputFormat="MM/DD/YYYY"
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} style={{width: 200, background: "white"}}/>}   
                />
            </LocalizationProvider>
                
                
                {   
                    tooltip &&
                    <Tooltip title={tooltipText} arrow>
                        <IconButton>
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                }

        </div>
    )
}

//retorna una caja de texto de material UI
interface CustumTextBoxProps {
    elementType: ElementTypeEnum.textBox
    label: string;
    tooltip: boolean;
    tooltipText: string;
    required: boolean;
    defaultValue: string;
}
const CustomTextBoxInput:FC<CustumTextBoxProps> = ({elementType, label, tooltip, tooltipText, required, defaultValue}) => {
    return (
        <div className="paragraph-build-input-box">

            <TextField
                variant="outlined"
                label={label}
                defaultValue={defaultValue ? defaultValue : ""}
                multiline
                style = {{background: "white"}}
            />
            
            {   
                tooltip &&
                <Tooltip title={tooltipText} arrow>
                <IconButton>
                    <HelpOutlineIcon />
                </IconButton>
                </Tooltip>
            }

        </div>
    )
}

//retorna un salto de línea (<br/>) de html
interface VerticalSpaceProps {
    elementType: ElementTypeEnum.lineBreak
}
export const CustomVerticalSpaceComponent:FC<VerticalSpaceProps> = ({elementType}) => {
    return <br/>
}

//retorna un texto en negrita
interface CustomBoldTextProps {
    elementType: ElementTypeEnum.boldText;
    innerText: string;
}
const CustomBoldTextComponent:FC<CustomBoldTextProps> = ({elementType, innerText}) => {
    return  <b className="paragraph-build-text-bold">{innerText}</b>
}

//retorna un dropdown(lista desplegable) de material UI
interface CustomDropdownProps {
    elementType: ElementTypeEnum.selectField;
    label: string;
    tooltip: boolean;
    tooltipText: string;
    dropdownOptions: string[];
    required: boolean;
}
const CustomDropdownComponent:FC<CustomDropdownProps> = ({elementType, label, tooltip, tooltipText, dropdownOptions, required}) => {

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedValue(event.target.value as string);
    };

    return (
    
        <div className="paragraph-build-input-dropdown">
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValue}
                    label={label}
                    onChange={handleChange}
                    style={{background: "white"}}
                >
                    {dropdownOptions.map(option => <MenuItem value={option} key={uuidv4()}>{option}</MenuItem>)}
                </Select>
            </FormControl>

            {   
                tooltip && 
                <Tooltip title={tooltipText} arrow>
                    <IconButton>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            }

        </div>
    
    )
}

//retorna un elemento tipo firma
interface CustomSignatureProps {
    elementType: ElementTypeEnum.signature;
    label: string;
}
const SignatureComponent: FC<CustomSignatureProps> = ({ elementType, label }) => {
    return (
        <div className="paragraph-build-signature-component">{label} : ____________________ </div>
    );
};


//------------------------------------------fin de componentes-----------------------------------------------------//

export class ParagraphBuildService {

    static getText(text: string){
        return <CustomTextComponent 
        innerText={text} 
        elementType={ElementTypeEnum.paragraph} 
        key={uuidv4()}
        />
    }

    static getTextFieldInput(properties: TextFieldFormModel){
        return <CustomTextFieldInputComponent 
        elementType={ElementTypeEnum.textField}
        label={properties.label} 
        tooltip={properties.tooltip} 
        tooltipText={properties.tooltipText} 
        required={properties.required}
        defaultValue={properties.defaultValue}
        key={uuidv4()}
        />
    }

    static getDateInput(properties: DatePickerFormModel){
        return <CusttomDateInputComponent 
        elementType={ElementTypeEnum.dateField}
        label={properties.label} 
        tooltip={properties.tooltip} 
        tooltipText={properties.tooltipText} 
        required={properties.required}
        key={uuidv4()}
        />
    }

    static getTextBoxInput(properties: TextBoxFormModel){
        return <CustomTextBoxInput
        elementType={ElementTypeEnum.textBox} 
        label={properties.label} 
        tooltip={properties.tooltip} 
        tooltipText={properties.tooltipText} 
        required={properties.required}
        defaultValue={properties.defaultValue} 
        key={uuidv4()}
        />
    }

    static getVerticalHtmlSpace() {
        return <CustomVerticalSpaceComponent 
        elementType={ElementTypeEnum.lineBreak} 
        key={uuidv4()}
        />
    }

    static getBoldText(text: string){
        return <CustomBoldTextComponent 
        elementType={ElementTypeEnum.boldText} 
        innerText={text} 
        key={uuidv4()}
        />
    }

    static getDropdownInput(properties: DropdownFormModel){
        return <CustomDropdownComponent 
        elementType={ElementTypeEnum.selectField}
        label={properties.label}
        tooltip={properties.tooltip}
        tooltipText={properties.tooltipText}
        dropdownOptions={properties.dropdownOptions}
        required={properties.required}
        key={uuidv4()}
        />
    }

    static getSignature(label: string){
        return <SignatureComponent 
        key={uuidv4()}         
        elementType={ElementTypeEnum.signature}
        label={label}
        />
    }

}