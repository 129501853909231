import { SignDocumentRequestModel } from "../../commons/redux-state/models/sign-document-request-model";
import { SignDocumentResponseModel } from "../../commons/redux-state/models/sign-document-response-model";
import { DashboardDocumentsDownloadReponseModel } from "../redux-state/models/dashboard-documents-download-response-model";
import { DashboardDocumentSignDownloadResponse } from "../redux-state/models/dashboard-documents-sign-download-response-model";
import { DashboardDocumentsUserRequestModel } from "../redux-state/models/dashboard-documents-user-request-model";
import { DashboardDocumentsUserResponseModel } from "../redux-state/models/dashboard-documents-user-response-model";
import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";

export class DashboardService {

  /**
   * Metodo obtener los documentos que un usuario tiene 
   * @returns Objeto de tipo 
   */
  public static async getAllDocumentsByUserId(documentsUser: DashboardDocumentsUserRequestModel, token: string): Promise<DashboardDocumentsUserResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}Document/getAllByUserId`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(documentsUser)
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const data: DashboardDocumentsUserResponseModel = await response.json();
      return data;
    } catch (error) {
      return null
    }
  }

  /**
* Metodo para descargar un documento
* @param token {token de acceso del usuario}
* @param documentId {id del documento a descargar}
* @returns pdf de un documento
*/

  static async getDocumentSign(documentId: number, token: string): Promise<DashboardDocumentSignDownloadResponse | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}Document/DownloadDocumentSign/${documentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const documentSign: DashboardDocumentSignDownloadResponse = await response.json();
      return documentSign;
    } catch (error) {
      return null
    }
  }


  /**
* Metodo para descargar un documento
* @param token {token de acceso del usuario}
* @param documentId {id del documento a descargar}
* @returns pdf de un documento
*/

  static async getGenerateAndSavePDF(documentId: number, token: string): Promise<DashboardDocumentsDownloadReponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}GenerateDocument/GenerateAndSavePDF/${documentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const documentSign: DashboardDocumentsDownloadReponseModel = await response.json();
      return documentSign;
    } catch (error) {
      return null
    }
  }

  /**
* Metodo obtener url de DOCUSIGN para firmar un documento
* @returns Objeto de tipo 
*/
  public static async getUrlSignDocument(signDocument: SignDocumentRequestModel, token: string): Promise<SignDocumentResponseModel | null> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}Document/signDocument`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(signDocument)
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status); 
      const data: SignDocumentResponseModel = await response.json();
      return data;
    } catch (error) {
      return null
    }
  }
}
