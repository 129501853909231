import React from "react";
import { elementTypeConstants } from "../../constants/element-type-constants";
import { updateInputValue } from "../../../natural-person/redux-state/reducers/input-value-reducer";
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  TextElement,
  TextAreaElement,
  PrintElement,
  PasswordElement,
  CheckboxElement,
  RadioElement,
  SimpleSelectElement,
  DatePickerElement,
  Signature
} from "./FormElement";
import { shouldBeHidden } from "../../../utils/helpers/formUtils";
import { actionsConstants } from "../../constants/actions-constants";

const ElementDictionary = {
  [elementTypeConstants.PRINT]: PrintElement,
  [elementTypeConstants.TEXT]: TextElement,
  [elementTypeConstants.PASSWORD]: PasswordElement,
  [elementTypeConstants.CHECKBOX]: CheckboxElement,
  [elementTypeConstants.RADIO]: RadioElement,
  [elementTypeConstants.TEXTAREA]: TextAreaElement,
  [elementTypeConstants.SIMPLE_SELECT]: SimpleSelectElement,
  [elementTypeConstants.DATEPICKER]: DatePickerElement,
  [elementTypeConstants.SIGNATURE]: Signature,
};

const FormElementComponent = (props) => {
  const { element, formElements, valueMap/* , fieldId, clauseId,position */ } = props;

  const MyReactElement = ElementDictionary[element.field.typeField.typeFieldName];
  
  const handleChange = (event) => {
    const { element, setValue/* ,clauseId */ } = props;
    const value = getCurrentValue(event);
    setValue(element.elementId, value);

    if(localStorage.getItem('inputArrayValues')){

      const inputsValuesArray = localStorage.getItem('inputArrayValues');
      const stringToObj = JSON.parse(inputsValuesArray);

      const newArray = stringToObj.map(inputElement => { 
        if (inputElement.id === `${element.clauseId}-${element.position}`) {
           return { ...inputElement, value }; } 
           return inputElement; });

      localStorage.setItem('inputArrayValues', JSON.stringify(newArray))
    }
  };

  const getCurrentValue = (event) => {
    const { element } = props;
    switch (element.field.typeField.typeFieldName) {
      case elementTypeConstants.RADIO:
        return event.target.value;
      case elementTypeConstants.CHECKBOX:
        return event.target.checked;
      case elementTypeConstants.DATEPICKER:
        return event;
      default:
        return event.target.value;
    }
  };

  return <MyReactElement handleChange={handleChange} {...props} />;
};

export default FormElementComponent;
