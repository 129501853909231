import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardAdminService } from "../../services/dashboard-admin-service";

interface payload {
    id: number,
    token: string,
  }

//Desactiva un usuario  
export const disabledUserDashboardAdminThunk = createAsyncThunk(
    "dashboard-admin/disabled-user-dashboard-admin-thunk",
    async (payload:payload, { rejectWithValue }) => {
      const userPlanDataResponse = await DashboardAdminService.disabledPlan(payload.id, payload.token);
      if (userPlanDataResponse){
        return userPlanDataResponse;
      }else return rejectWithValue(null); 
    }
  );
  