import { Dialog, DialogTitle } from "@mui/material";
import React, { FC } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import "./delete-subtitle-warning-modal-component.scss";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { deleteSecondLevelSection } from "../../redux-state/reducers/document-build-reducer";

interface DeleteSubtitleWarningProps {
    parentPositionId: number;
    selfPositionId: number;
    setShowDeleteModal: any;
}


const DeleteSubtitleWarningModalComponent: FC<DeleteSubtitleWarningProps> = ({parentPositionId, selfPositionId, setShowDeleteModal}) => {

    const dispatch = useAppDispatch();

    const handleCancelButtonClick = () => {
        setShowDeleteModal(false);
    }

    const handleDeleteButtonClick = () => {
        dispatch(deleteSecondLevelSection({firstLevelSectionId: parentPositionId, selfPositionId: selfPositionId}));
        setShowDeleteModal(false);
    }

    return (
     <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
         <DialogTitle sx={{background: "#D3A237", color: "white"}}>Atención!</DialogTitle>

         <div className="delete-subtitle-modal">

            <div className="delete-subtitle-modal__message">
                <WarningAmberIcon sx={{fontSize: "70px", color: "#f0ad4e"}}/>
                <p>Está seguro que desea eliminar este subtítulo y todo su contenido?</p>
            </div>

            <div className="delete-subtitle-modal__button-bar">
                <button className="delete-subtitle-modal__back-button" onClick={handleCancelButtonClick}>Cancelar</button>
                <button className="delete-subtitle-modal__forward-button" onClick={handleDeleteButtonClick}>Eliminar</button>
            </div>

         </div>
     </Dialog>
    )

}

export default DeleteSubtitleWarningModalComponent;