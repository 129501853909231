import { createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentTemplateAdapterToFillOutService } from "../../services/document-template-adapter-to-fill-out-service";
import { updateDocumentTemplateSections } from "../../../natural-person/redux-state/reducers/natural-person-reducer";
import { DocumentTemplateAdapterToFillRequestModel } from "../models/document-template-adapter-to-fill-out-request-model";
import { DocumentTemplateService } from "../../../commons/services/document-template-service";

interface payload {
  documentTemplateTransformToFill: DocumentTemplateAdapterToFillRequestModel,
  token: string,
}

//Obtiene objeto de plantilla trasnformado para diligenciamiento
export const getDocumentTemplateAdapterToFillThunk = createAsyncThunk(
  "document-template-adapter/get-document-template-adapter-to-fill-thunk",
  async (payload:payload, { rejectWithValue, dispatch, getState }) => {
    const documentTemplateAdapterToFillResponse = await DocumentTemplateAdapterToFillOutService.getDocumentTemplateAdapterToFillOut(payload.documentTemplateTransformToFill, payload.token);
    if (documentTemplateAdapterToFillResponse){
      const sectionsResults = DocumentTemplateService.getSectionsArrays(documentTemplateAdapterToFillResponse);
      dispatch(updateDocumentTemplateSections(sectionsResults))
      return documentTemplateAdapterToFillResponse;
    }else return rejectWithValue(null); 
  }
);