import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import { DashboardAdminService } from "../../services/dashboard-admin-service";

//Obtiene lista de usuarios con planes
export const getuserListDashboardAdminThunk = createAsyncThunk(
    "dashboard-admin/get-user-list-dashboard-admin-thunk",
    async (payload:string, { rejectWithValue, dispatch, getState }) => {
      const state = getState() as RootState;
      const userPlanListResponse = await DashboardAdminService.getUserListPlan(state.dashboardAdminReducer.dashboardAdminDocumentsUserRequestModel, payload);
      if (userPlanListResponse){
        return userPlanListResponse;
      }else return rejectWithValue(null); 
    }
  );
  