import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { useAppSelector } from "../../../redux/hooks";
import { DashboardDocumentsUserRequestModel } from "../models/dashboard-documents-user-request-model";
import { DashboardDocumentsUserResponseModel } from "../models/dashboard-documents-user-response-model";
import { DashboardInitialStateModel } from "../models/dashboard-initial-state-model";
import { getDocumentsUserThunk } from "../thunks/get-document-user";

const getUserInfo = () => {
  const oidcUser = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`)!);
  if(oidcUser) {return Number(oidcUser.profile.sub) !== null ? Number(oidcUser.profile.sub) : 0}else{return 0}
}


export const dashboardInitialState: DashboardInitialStateModel = {
  dashboardDocumentsUserModelResponseData: {
    status: AsyncStatusEnum.idle,
    dashboardDocumentsUserResponseModel: null,
    error: {
      error: false,
      message: "",
    },
  },

  dashboardDocumentsUserRequestModel: {
    pageNumber: 1,
    pageSize: 10,
    sortProperty: "CreationDate",
    sortType: "des",
    filters: [],
    userId: getUserInfo()
  },
};

//dashboard reducer, para mostrar documentos para el usuario
export const dashboardReducer = createSlice({
  name: "dashboard-reducer",
  initialState: dashboardInitialState,
  reducers: {
    updateDocumentList: (state, action: PayloadAction<DashboardDocumentsUserRequestModel>) => {
      state.dashboardDocumentsUserRequestModel = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentsUserThunk.pending, (state) => {
        state.dashboardDocumentsUserModelResponseData.status = AsyncStatusEnum.loading;
      })
      .addCase(getDocumentsUserThunk.rejected, (state, action) => {
        state.dashboardDocumentsUserModelResponseData.status = AsyncStatusEnum.rejected;
        state.dashboardDocumentsUserModelResponseData.error = {
          error: true,
          message: 'Error rejected',
        };
        state.dashboardDocumentsUserModelResponseData.dashboardDocumentsUserResponseModel = dashboardInitialState.dashboardDocumentsUserModelResponseData.dashboardDocumentsUserResponseModel
      })
      .addCase(getDocumentsUserThunk.fulfilled, (state, action: PayloadAction<DashboardDocumentsUserResponseModel>) => {
        state.dashboardDocumentsUserModelResponseData.status = AsyncStatusEnum.completed;
        state.dashboardDocumentsUserModelResponseData.error = {
          error: false,
          message: '',
        };
        state.dashboardDocumentsUserModelResponseData.dashboardDocumentsUserResponseModel = action.payload;
      })
  },
});

export const { updateDocumentList } = dashboardReducer.actions;
export default dashboardReducer.reducer;
