import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ParagraphBuildTypeEnum } from "../../enums/paragraph-build-type-enum";
import { ParentTypeEnum } from "../../enums/parent-type-enum";
import { resetPreviewChildren } from "../../redux-state/reducers/paragraph-build-reducer";
import { DocumentBuildService } from "../../services/document-build-service";
import "./paragraph-build-preview-component.scss";


const ParagraphBuildPreviewComponent = () => {

    const dispatch = useAppDispatch();
    const previewChildren = useAppSelector(state => state.paragraphBuildReducer.previewChildren);
    const paragraphMetadata = useAppSelector(state => state.documentBuildReducer.paragraphMetadata);
    const buildType = useAppSelector(state => state.paragraphBuildReducer.buildType);

    const handleCleanParagraphClick = () => {
        dispatch(resetPreviewChildren());
    }

    return (
        <article className="paragraph-build-preview">

            {
                paragraphMetadata?.parentType === ParentTypeEnum.title &&
                <div className="subheader">
                    <div className="circle position-container"><p>{DocumentBuildService.convertIntToRoman(paragraphMetadata!.firstLevelParentPositionId + 1)}</p></div>
                    <div className="cuadro"></div>
                    <h2>{paragraphMetadata?.parentText}</h2>
                </div>
            }

            {
                paragraphMetadata?.parentType === ParentTypeEnum.subTitle &&
                <div className="subheader--subtitle">
                    <div className="cuadro"></div>
                    <h2>{paragraphMetadata?.parentText}</h2>
                </div>
            }

            { buildType === ParagraphBuildTypeEnum.creation && <span className="prview">Previsualización párrafo #{paragraphMetadata!.selfPositionId + 1}</span> }
            { buildType === ParagraphBuildTypeEnum.edition && <span className="prview">Edición párrafo #{paragraphMetadata!.selfPositionId + 1}</span> }
            
            <div className="paragraph-build-preview__main">
                {previewChildren}
            </div>
            
            <button className="clear" onClick={handleCleanParagraphClick}>Limpiar parrafo</button>
        </article>
    )
}

export default ParagraphBuildPreviewComponent;