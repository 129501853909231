import React, { useState, useContext } from "react";
import { Layout } from "../../common/Layout";
import "./preview-template-view.scss";
import { FieldService } from "../../../services/FieldService";
import { GLOBALS } from "../../../utils/Globals";
import { Context } from "../../../Context";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormManagement from "../Form/FormManagement";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { AsyncStatusEnum } from '../../../commons/enums/async-status-enum';
import { updateCurrentDocumentPreviewFieldValue } from "../../../commons/redux-state/reducers/common-reducer";
import { v4 as uuidv4 } from 'uuid';
import { getDocumentTemplatePreviewEditThunk } from "../../../preview-document/thunks/get-document-template-preview-edit-thunk";
import { CurrentDocumentPreviewService } from "../../../commons/services/current-document-preview-service";
import { CurrentDocumentPreviewEditRequestModel } from "../../../preview-document/models/current-document-preview-edit-request-model";
import { updateDocumentTemplatePreviewEdit } from '../../../preview-document/reducers/preview-document-reducer';
import FooterComponent from "../../common/footer-component/footer-component";
import EditTemplateModalComponent from "../../../lawyer/components/edit-template-modal-component/edit-template-modal-component";
import { updateDocumentList } from "../../../dashboard/redux-state/reducers/dashboard-reducer";
import { FieldModel, TypeFieldData } from "../../../commons/redux-state/models/field-model";
import { PreviewDocumentService } from "../../../preview-document/services/typeFieldService";

/**
 * Inicializa valueMap, para manejo de estados
 * @returns valueMap
 */
function init() {
  return { valueMap: {} };
}

export default function PreviewTemplateView() {

  /* Variables de colores */
  const blue = "#004571";

  const currentDocumentPreviewResponse = useAppSelector((state) => state.commonReducer);
  const isStructureChange = currentDocumentPreviewResponse.documentTemplatePreviewResponseData.documentTemplatePreviewResponse?.data.documentStructureChange;
  const { status } = useAppSelector((state) => state.commonReducer.documentTemplatePreviewResponseData);
  const { status: documentPreviewStatus } = useAppSelector((state) => state.previewDocumentReducer.documentPreviewEditRequestTemplateData);
  const dispatch = useAppDispatch();

  const { isAuth: token, userLogged } = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [fieldModelOpen, setFieldModelOpen] = useState(false);
  const [fieldDefaultValue, setfieldDefaultValue] = useState("");
  const [classNameOriginal, setClassNameOriginal] = useState(userLogged.profile.role === GLOBALS.roles.lawyer.roleName ? "col-md-6" : "col-md-12");
  const [fieldJSX, setfieldJSX] = useState(<></>);
  const [propsElementReducer, setPropsElementReducer] = useState(null)
  const [redirectTo, setRedirectTo]: any = useState(null);
  const [showEDitTemplateModal, setShowEDitTemplateModal] = useState<boolean>(false);
  const navigate = useNavigate();

  //Crea un elemento HTML SPAN para mostrar el valor del campo
  const Texto = (props: any) => {
    if (props.field.typeFieldId === 5) {
      return (
        <span key={uuidv4()}>
          {props.field.label + "     __________________"}
        </span>
      )
    } else {
      return (
        <span onClick={(event) => createElement(props.field, event, props)} key={uuidv4()} style={{ color: "red", cursor: "pointer" }}>
          {props.field.fieldValue}
        </span>
      )
    }

  };


  //Envia valores de campos, posiciones de cada uno actualizados al reducer
  const setTextInClause = (props: any) => {
    props.preventDefault();
    const propsElement: any = propsElementReducer
    const elementProps = {
      sectionIndex: propsElement.indexSection,
      clauseIndex: propsElement.indexClause,
      fieldIndex: propsElement.indexField,
      subsectionIndex: propsElement.indexSubSection,
      valueField: props.target[0].value
    }
    dispatch(updateCurrentDocumentPreviewFieldValue(elementProps));
    setFieldModelOpen(false);
  };

  //Set de valores para formar un elemento JSX
  const createElement = async (fieldElement: any, event: any, propsElement: any) => {
    event.preventDefault(); 

    setPropsElementReducer(propsElement)
    setfieldDefaultValue(fieldElement.fieldValue);
    let elementJSX = <></>;
    
    if(isStructureChange){
      let options = []

      if(fieldElement.dropdownOptions !== null){
         options = fieldElement.dropdownOptions
      }
      
      const fieldData = await PreviewDocumentService.getTypeFieldById(fieldElement.typeFieldId, token);

      const typeField: TypeFieldData = {
        typeFieldId: fieldElement.typeFieldId,
        typeFieldName: fieldData.data.typeFieldName
      }

      const field:any = {
        field: {        
          fieldId: fieldElement.fieldId,
          typeFieldId: fieldElement.typeFieldId,
          fieldName: "Date",
          typeField: typeField,
          valueField: options, 
        }
      }

     elementJSX = <FormManagement element={field} valueInit={init()} />;
    }else{
      const fieldElementData = await FieldService.getFieldById(fieldElement.fieldId, token);
      const field = { field: fieldElementData.data };
      elementJSX = <FormManagement element={field} valueInit={init()} />;
    }
    setfieldJSX(elementJSX);
    handleOpen();
  };

  //Abre ventana modal campos
  const handleOpen = () => {
    setFieldModelOpen(true);
  };

  //cierra ventana modal campos
  const handleClose = () => {
    setFieldModelOpen(false);
  };

  //Abrir Modal confirmacion
  const handleClickOpen = () => {
    setOpen(true);
  };

  //cerrar Modal confirmacion
  const handleClickClose = () => {
    setOpen(false);
  };

  //Crea objeto con valores de campos para crear la plantilla
  const handleSubmit = async (props: any) => {
    props.preventDefault();
    const documentTemplatePreviewEdited: CurrentDocumentPreviewEditRequestModel = CurrentDocumentPreviewService.generateDocumentPreviewEdit(currentDocumentPreviewResponse.documentTemplatePreviewResponseData.documentTemplatePreviewResponse!)
    dispatch(updateDocumentTemplatePreviewEdit(documentTemplatePreviewEdited))
    dispatch(getDocumentTemplatePreviewEditThunk(token))
    const documentsFilter = {
      pageNumber: 1,
      pageSize: 10,
      sortProperty: "CreationDate",
      sortType: "des",
      filters: [],
      userId: userLogged.profile.sub,
    }
    dispatch(updateDocumentList(documentsFilter))
  };

  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  /**
   * Convierte un Array de secciones en un elemento JSX que incluye dichas secciones y todas sus clausulas/campos
   * @returns elemento JSX
   */
  const DocumentCurrent = () => {
    const elementsJSX = currentDocumentPreviewResponse.documentTemplatePreviewResponseData.documentTemplatePreviewResponse?.data.section.map(
      (u: any, index) => {
        return (
          <div key={uuidv4()} className="row mb-20">
            {userLogged.profile.role === GLOBALS.roles.lawyer.roleName ? (
              <div className={`col-12 col-md-6`}>
                <div className="card-edit">
                  <h6>{u.sectionTitle}</h6>
                  {subSectionsxClausesOriginal(u)}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className={`col-12 ${classNameOriginal}`}>
              <div className="card-edit">
                <h6>{u.sectionTitle}</h6>
                {subSectionsxClauses(u, index)}
              </div>
            </div>
          </div>
        );
      }
    );
    return <>{elementsJSX}</>;
  };

  /**
   * Unifica Elementos clausula y subsecciones en elementoJSX
   * @returns elemento JSX
   */
  const subSectionsxClauses = (u: any, indexSection: number) => {
    let showSections = <></>;
    const clause = clauses(u, indexSection);
    const subsection = subSections(u.section, indexSection);
    if (u.section.length > 0 && u.clause.length > 0) {
      showSections = (
        <span key={uuidv4()}>
          {clause}
          {subsection}
        </span>
      );
    } else
      if (u.section.length === 0 && u.clause.length > 0) {
        showSections = <span key={uuidv4()}>{clause}</span>;
      } else {
        showSections = <span key={uuidv4()}>{subsection}</span>;
      }

    return showSections;
  };

  /**
   * Unifica Elementos clausula y subsecciones en elementoJSX
   * @returns elemento JSX
   */
  const subSectionsxClausesOriginal = (u: any) => {
    let showSections = <></>;
    const clause = clausesOriginal(u.clause);
    const subsection = subSectionsOriginal(u.section);
    if (u.section.length > 0 && u.clause.length > 0) {
      showSections = (
        <>
          {clause}
          {subsection}
        </>
      );
    } else
      if (u.section.length === 0 && u.clause.length > 0) {
        showSections = <>{clause}</>;
      } else {
        showSections = <>{subsection}</>;
      }
    return showSections;
  };

  /**
   * Convierte un Array de clausulas en un elemento JSX
   * @returns elemento JSX
   */
  const clauses = (clauses: any, indexSection: number, indexSubSection = null) => {
    const sectionsProps = clauses;
    const clausesJSX = Object.values(clauses.clause).map((u: any, index) => {
      const indexClause = index
      const clauseProps = u;
      const fieldsJSX = Object.values(u.fieldList).map((u: any, index) => {
        return (
          <Texto
            key={uuidv4()}
            field={u}
            indexSubSection={indexSubSection}
            indexField={index}
            indexClause={indexClause}
            indexSection={indexSection}
            clauseProps={clauseProps}
            sectionsProps={sectionsProps}
          />
        );
      });

      const deleteHtmlTags = u.clauseText.replace(/(<([^>]+)>)/ig, '');
      let clausulatexto = deleteHtmlTags.split("{##}");
      let resultado = <></>;
      for (let index = 0; index < clausulatexto.length; index++) {
        resultado = (
          <span key={uuidv4()}>
            {resultado}
            {clausulatexto[index]}
            {" "}
            {fieldsJSX[index]}
            {" "}
          </span>
        );
      }
      return <span key={uuidv4()}>{resultado}</span>;
    });

    return <span key={uuidv4()}>{clausesJSX}</span>;
  };

  /**
   * Convierte un Array de clausulas en un elemento JSX
   * @returns elemento JSX
   */
  const clausesOriginal = (clauses: any) => {
    const clausesJSX = Object.values(clauses).map((u: any, index) => {
      const fieldsJSX = Object.values(u.fieldList).map((u: any, index) => {
        if (u.typeFieldId === 5) {
          return (
            <span key={uuidv4()}>
              {u.label + "     __________________"}
            </span>
          )
        } else {
          return (
            <span key={uuidv4()}>
              {"________"}
            </span>
          )
        }
      });
      const deleteHtmlTags = u.clauseText.replace(/(<([^>]+)>)/ig, '');
      let clausulatexto = deleteHtmlTags.split("{##}");
      let resultado = <></>;
      for (let index = 0; index < clausulatexto.length; index++) {
        resultado = (
          <span key={uuidv4()}>
            {resultado}
            {clausulatexto[index]}
            {" "}
            {fieldsJSX[index]}
            {" "}
          </span>
        );
      }
      return <span key={uuidv4()}>{resultado}</span>;
    });

    return <span key={uuidv4()}>{clausesJSX}</span>;
  };

  /**
   * Convierte un Array de subsectiones en un elemento JSX
   * @returns elemento JSX
   */
  const subSectionsOriginal = (subSection: any) => {
    const subSectionsJSX = Object.values(subSection).map((u: any) => {
      return (
        <div key={uuidv4()}>
          <p className="sub-indice" key={uuidv4()}>
            {u.sectionTitle}
          </p>
          {clausesOriginal(u.clause)}
        </div>
      );
    });
    return subSectionsJSX;
  };

  /**
   * Convierte un Array de subsectiones en un elemento JSX
   * @returns elemento JSX
   */
  const subSections = (subSection: any, indexSection: number) => {
    const subSectionsJSX = Object.values(subSection).map((u: any, index: any) => {
      const indexSubsection = index
      return (
        <div key={uuidv4()}>
          <p className="sub-indice" key={uuidv4()}>
            {u.sectionTitle}
          </p>
          {clauses(u, indexSection, indexSubsection)}
        </div>
      );
    });
    return subSectionsJSX;
  };

  return (
    <Layout>
      <section className="preview">
        <div className="container">
          <h3 className="title green">{currentDocumentPreviewResponse.documentTemplatePreviewResponseData.documentTemplatePreviewResponse?.data.documentName}</h3>
          <div className="row mt-50">
            {userLogged.profile.role === GLOBALS.roles.lawyer.roleName ? (
              <div className="col-12 col-md-6">
                <div className="t-section blue">
                  <h6>Original</h6>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className={`col-12 ${classNameOriginal}`}>
              <div className="t-section blue">
                <h6>Editado</h6>
              </div>
            </div>
          </div>
          {status === AsyncStatusEnum.loading && (<div className="spinner"></div>)}
          {status === AsyncStatusEnum.completed && <DocumentCurrent />}
          <div className="templateEdit">
            <button onClick={() => navigate("/")} className="back">Cancelar</button>
            <button className="end-edit" onClick={handleClickOpen}>Aprobar</button>
          </div>

          <Dialog
            open={open}
            onClose={handleClickClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"sm"}
            fullWidth={true}>
            <DialogTitle id="alert-dialog-title" sx={{ background: blue, color: "white" }}>
              {"Atención!"}
            </DialogTitle>
            <DialogContent>
              <div className="delete-paragraph-modal__message">
                <p>{"Está seguro que quieres aprobar el documento actual?"}</p>
              </div>
            </DialogContent>
            <div className="delete-paragraph-modal">

              <DialogActions>
                <div className="delete-paragraph-modal__button-bar">
                  <button className="delete-paragraph-modal__back-button" onClick={handleClickClose}>Cancelar</button>
                  <Link to={`${GLOBALS.menu.dashboard.link}`}>
                    <button className="delete-paragraph-modal__forward-button" onClick={handleSubmit}>Aceptar</button>
                  </Link>
                </div>
              </DialogActions>

            </div>
          </Dialog>


          <Dialog
            open={fieldModelOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth={"sm"}
            fullWidth={true}>
            <DialogTitle id="alert-dialog-title" sx={{ background: blue, color: "white" }}>
              Atención!
            </DialogTitle>
            <DialogContent>
              <div>
                <span>
                  Editar campo{" "}
                  <span style={{ color: "red" }}>
                    {fieldDefaultValue}
                  </span>{" "}
                  por{" "}
                </span>
                <br />
                <form onSubmit={setTextInClause}>
                  {fieldJSX}
                  <DialogActions>
                    <div className="delete-paragraph-modal__button-bar">
                      <button
                        className="delete-paragraph-modal__back-button"
                        id="saveButton"
                        type="submit">
                        Editar
                      </button>
                    </div>
                  </DialogActions>
                </form>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </section>

      {
        documentPreviewStatus === AsyncStatusEnum.loading &&
        <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
          <div className="dashboard-advice-modal">
            <div className="dashboard-advice-modal__message">
              <div className="spinner"></div>
            </div>
          </div>
        </Dialog>
      }

      {documentPreviewStatus === AsyncStatusEnum.completed && (window.location.href = "/")}

      {
        showEDitTemplateModal && <EditTemplateModalComponent setShowEDitTemplateModal={setShowEDitTemplateModal}/>
      }

      <FooterComponent />
    </Layout>
  );
}


