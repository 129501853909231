import React from "react";
import { useNavigate } from "react-router-dom";
import BuildBlockComponent from "../../../lawyer/components/build-block-component/build-block-component";
import { FormParentComponent } from "../../../lawyer/components/build-block-form-component/build-block-form-component";
import ParagraphBuildPreviewComponent from "../../../lawyer/components/paragraph-build-preview-component/paragraph-build-preview-component";
import { ParagraphBuildTypeEnum } from "../../../lawyer/enums/paragraph-build-type-enum";
import { addParagraph, editFirstLevelParagraph, updateParagraphMetadata } from "../../../lawyer/redux-state/reducers/document-build-reducer";
import { resetPreviewChildren } from "../../../lawyer/redux-state/reducers/paragraph-build-reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Layout } from "../../common/Layout";
import "./dynamic-paragraph-build-view.scss";
import FooterComponent from "../../common/footer-component/footer-component";

//view principal para crear o editar parrafos de forma dinámica
const  DynamicParagraphBuildView = () => {

  const previewChildren = useAppSelector(state => state.paragraphBuildReducer.previewChildren); 
  const paragraphMetadata = useAppSelector(state => state.documentBuildReducer.paragraphMetadata!);
  const buildType = useAppSelector(state => state.paragraphBuildReducer.buildType);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const handleAddParagraphButtonClick = () => {
    dispatch(addParagraph({
      children: previewChildren,
      metadata: paragraphMetadata
    }))
    dispatch(updateParagraphMetadata(null));
    dispatch(resetPreviewChildren());
    navigate("/edit-document");
  }

  const handleEditParagraphButtonClick = () => { 
    dispatch(editFirstLevelParagraph({
      children: previewChildren,
      metadata: paragraphMetadata
    }))
    dispatch(updateParagraphMetadata(null));
    dispatch(resetPreviewChildren());
    navigate("/edit-document");
  }

  const handleCancelButtonClick = () => {
    dispatch(updateParagraphMetadata(null));
    dispatch(resetPreviewChildren());
    navigate("/edit-document");
  }

  return (
    <Layout>
        <section className="dynamic-paragraph">
          <div className="container">

            <div className="dynamic-paragraph__container">
              <ParagraphBuildPreviewComponent />
              <BuildBlockComponent />
              <FormParentComponent />

              <div className="dynamic-paragraph__action-buttons-bar">
                <button onClick={handleCancelButtonClick} className="dynamic-paragraph__cancel-button">Cancelar</button>
                { buildType === ParagraphBuildTypeEnum.creation && <button className="dynamic-paragraph__save-button" disabled={previewChildren.length === 0} onClick={handleAddParagraphButtonClick}>Agregar párrafo</button> }
                { buildType === ParagraphBuildTypeEnum.edition && <button className="dynamic-paragraph__save-button" disabled={previewChildren.length === 0} onClick={handleEditParagraphButtonClick}>Guargar edición</button> }
              </div>

            </div>

          </div>
        </section>
        <FooterComponent />
    </Layout>
  );
}


export default DynamicParagraphBuildView;