import React from "react";
import "./header-login-component.scss";

import Logo from "../../../assets/images/logo.svg";

function HeaderLoginComponent() {
  return (
    <header id="headerLogin">
      <div className="container">
        <div className="row justify-content-center">
          <img src={Logo} alt="Anuwa" width="196" height="46" />
        </div>
      </div>
    </header>
  );
}
export default HeaderLoginComponent;
