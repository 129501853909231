import { createAsyncThunk } from "@reduxjs/toolkit";
import { CurrentDocumentPreviewService } from "../../commons/services/current-document-preview-service";
import { RootState } from "../../redux/store";

export const getDocumentTemplatePreviewEditThunk = createAsyncThunk(
  "preview-document/get-document-template-preview-edit-thunk",
  async (payload:string, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const documentTemplatePreviewEditResponse = await CurrentDocumentPreviewService.getPreviewEditDocumentTemplate( state.previewDocumentReducer.documentPreviewEditRequestTemplateData.documentTemplatePreviewEdit!, payload);
    if (documentTemplatePreviewEditResponse){
      return documentTemplatePreviewEditResponse;
    }else return rejectWithValue(null); 
  }
);


