import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";
import { DocumentTypeModel } from "../redux-state/models/document-type-model";

export class DocumentTypeService {
  /**
  * Para obtener tipos de un documento
  * @param token {token de acceso del usuario}
  * @returns tipos de documento
  */
  static async getAllDocumentTypeStatus(token: string): Promise<DocumentTypeModel |null> {
    try 
    {
        const response = await fetch(`${process.env.REACT_APP_API_URL}DocumentTemplate/getAll`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const documentType: DocumentTypeModel = await response.json();
      return documentType;
    } catch (error) {
        return null
    }
  }
}