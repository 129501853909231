import { createAsyncThunk } from "@reduxjs/toolkit";
import { CurrentDocumentPreviewService } from "../../services/current-document-preview-service";
import { RootState } from "../../../redux/store";
import { DocumentTemplateService } from "../../services/document-template-service";

interface payload {
  id: number,
  token: string,
}

//Crea un nuevo documento y retorna la previsualizacion del mismo
export const getDocumentTemplatePreviewThunk = createAsyncThunk(
  "common/get-document-template-preview-thunk",
  async (payload:string, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const documentTemplatePreviewResponse = await CurrentDocumentPreviewService.getPreviewDocumentTemplate(state.commonReducer.currentDocumentPreview!, payload);
    if (documentTemplatePreviewResponse){
      return documentTemplatePreviewResponse;
    }else return rejectWithValue(null); 
  }
);

//Obtiene la previsualizacion de un documento por Id
export const getDocumentPreviewThunk = createAsyncThunk(
  "common/get-document-preview-thunk",
  async (payload:payload, { rejectWithValue, dispatch, getState }) => {
    const documentPreviewResponse = await DocumentTemplateService.getDocumentPreview(payload.id, payload.token);
    if (documentPreviewResponse){
      return documentPreviewResponse;
    }else return rejectWithValue(null); 
  }
);
