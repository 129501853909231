import { Dialog, DialogTitle } from "@mui/material";
import { FC } from "react";
import Calendar from "../../../commons/components/calendar/calendar";
import "./calendar-advice-model-component.scss"
import {useState} from 'react';
import { Filter } from "../../redux-state/models/dashboard-admin-filter-users-request-model";

interface CalendarAdviceProps {
    setShowCalendarModal: any;
    searchRangeDate:any
}

//Implementa modal de informacion del estado del usuario
const CalendarAdviceModal: FC<CalendarAdviceProps> = ({ setShowCalendarModal, searchRangeDate }) => {

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    //Agrega 0 inicial si solo tienen un digito
    const padTo2Digits = (num:number) => {
        return num.toString().padStart(2, '0');
      }
    
    //Formatea los rangos de fechas seleccionados a dd/mm/yyyy
    const formatDate = (date:any) => {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    //Eviar arreglo de fechas para el filtro
    const handleAceptButtonClick = () => {

        const rangeDatesArray:Array<Filter> = []

        const filterStartDate: Filter = {
            "operator": "DateFrom",
            "propertyName": "NextBillingDate",
            "value": formatDate(startDate)
        };
        
        const filterEndDate: Filter = {
            "operator": "DateTo",
            "propertyName": "NextBillingDate",
            "value": formatDate(endDate)
        };

        rangeDatesArray.push(filterStartDate, filterEndDate)

        searchRangeDate(rangeDatesArray)
        setShowCalendarModal(false)
    }

    //Setea fechas completas a los hook para su transformacion
    const handleCalendarDateSelectedClick = (props:any) => {
        setStartDate(props.startDate);
        setEndDate(props.endDate);
    }

    return (
        <Dialog open={true} maxWidth={"xs"} fullWidth={true}>
            <DialogTitle sx={{ background: "#D3A237", color: "white" }}>Seleccione rango de fechas</DialogTitle>

            <div className="calendar-active-modal">
                
                <div className="calendar-active-modal__message">
                    <Calendar onChange={handleCalendarDateSelectedClick} />
                </div>

                <div className="calendar-active-modal__button-bar">
                    <button onClick={handleAceptButtonClick} className="calendar-active-modal__forward-button">Aceptar</button>
                </div>

            </div>
        </Dialog>
    )
}

export default CalendarAdviceModal