import { Layout } from "../../common/Layout";
import "./terms-conditions.scss";

function TermsConditions() {
  return (
    <div>
      <header id="headerLogin">
        <div className="container">
          <div className="row justify-content-center">
            <svg
              width="193"
              height="40"
              viewBox="0 0 419 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_22)">
                <path
                  d="M94.6006 20.4944H108.866V25.6303C113.31 23.1433 117.344 21.3774 120.962 20.3423C124.579 19.3073 128.403 18.7874 132.436 18.7874C138.948 18.7874 144.067 20.0873 147.787 22.6773C151.508 25.2722 154.143 29.2897 155.697 34.7346C157.247 40.1795 158.025 47.5718 158.025 56.9067V98.2929H143.602V57.2157C143.602 50.6818 143.216 45.6244 142.439 42.0435C141.661 38.4675 140.214 35.8726 138.097 34.2637C135.976 32.6547 132.9 31.8502 128.872 31.8502C123.391 31.8502 117.652 33.0422 111.658 35.4311L109.023 36.6771V98.288H94.6006V20.4944Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M175.234 96.1885C171.616 93.6475 169.083 89.6006 167.636 84.0527C166.189 78.5048 165.465 70.8525 165.465 61.1056V20.4944H179.888V61.1056C179.888 68.2625 180.25 73.6289 180.973 77.2098C181.697 80.7858 183.066 83.3022 185.085 84.7542C187.099 86.2061 190.174 86.9321 194.31 86.9321C197.723 86.9321 200.949 86.5201 204 85.6862C207.046 84.8572 210.331 83.6652 213.846 82.1102V20.4944H228.269V98.2929H213.846V93.157C209.505 95.644 205.599 97.4099 202.137 98.4449C198.671 99.48 194.872 99.9999 190.741 99.9999C184.019 99.9999 178.851 98.7294 175.234 96.1885Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M237.259 20.4944H251.682L265.327 85.8432H268.275L284.555 22.0543H299.598L316.034 85.8432H318.982L332.627 20.4944H346.893L329.679 98.288H306.11L292.152 41.499L278.043 98.288H254.473L237.259 20.4944Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M355.889 93.78C352.061 89.6301 350.149 83.6652 350.149 75.8853C350.149 68.2085 352.11 62.5821 356.04 59.0061C359.966 55.4301 366.219 53.2227 374.804 52.3937L396.824 50.3727V44.3048C396.824 39.7429 395.841 36.4465 393.876 34.4255C391.91 32.4045 389.016 31.394 385.193 31.394C376.51 31.394 366.478 32.017 355.112 33.2629L354.647 22.6822C365.603 20.0921 375.787 18.7922 385.198 18.7922C394.291 18.7922 400.911 20.8427 405.047 24.9386C409.183 29.0395 411.251 35.4949 411.251 44.3097V81.8061C411.456 83.881 412.155 85.4605 413.343 86.5495C414.531 87.6385 416.419 88.3939 419.005 88.806L418.54 100.01C414.096 100.01 410.322 99.5143 407.223 98.5332C404.123 97.5473 401.38 96.0168 399.004 93.9418C390.214 97.9887 381.429 100.01 372.643 100.01C365.3 100.01 359.717 97.9348 355.894 93.7849L355.889 93.78ZM393.573 84.9111L396.829 83.8222V60.9486L376.672 62.8175C372.638 63.2345 369.666 64.451 367.754 66.472C365.838 68.493 364.884 71.4755 364.884 75.4193C364.884 83.7191 368.346 87.8642 375.273 87.8642C381.58 87.8642 387.682 86.8782 393.573 84.9062V84.9111Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M22.1957 84.0626L67.3155 68.3852L67.8973 68.8659L63.8835 98.2929H90.4059L53.1817 0H37.2291L28.4437 23.1924L28.5464 23.6682L65.228 59.4918L65.0666 60.2276L37.2927 69.8764L36.7305 69.6017L24.9482 34.6267L24.117 34.612L0 98.2929H26.1117L21.9219 84.612L22.2006 84.0626H22.1957Z"
                  fill="#F5F5F5"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_22">
                  <rect width="419" height="100" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </header>
      <div>&nbsp;</div>
      <div className="container polices-aling">
        <div>
          <div>
            <p><span>TÉRMINOS Y CONDICIONES</span></p>
          </div>
          <div></div>
          <div>
            <p>
              El presente documento describe los términos y condiciones generales (en adelante “TÉRMINOS Y CONDICIONES”) aplicables al uso de los contenidos, productos y servicios ofrecidos a través del sitio web:
              <a
                href="http://www.anuwa.legal/"
                rel="noreferrer noopener"
                target="_blank"
              >
                www.anuwa.legal
              </a>{" "}
              www.anuwa.legal y la App “ANUWA” (en adelante la “Plataforma”) y sus subdominios, del cual es titular la sociedad QUARTA DIGITAL LAW LLC (en adelante la “Compañía”), sociedad constituida de conformidad con las leyes de la Florida/Estados Unidos, e identificada con EIN: 88-3679156.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={1}>
              <li>
                <p><span>I.	OBJETO</span></p>
              </li>
            </ol>
          </div>
        </div>
        <div></div>
        <div>
          <p>
            La App “ANUWA”, es una plataforma virtual por medio la cual la Compañía otorga a sus usuarios (en adelante los “Usuarios”) la facultad de crear autónomamente documentos legales de diversas clases y que se requieran en distintos contextos empresariales, comerciales, y particulares (en adelante el “Software como Servicio”), lo cual se hará sujetándose a los presentes TÉRMINOS Y CONDICIONES, así como a las políticas y principios incorporados al presente documento.
          </p>
        </div>
        <div>
          <p>
            Para todos los efectos de los TÉRMINOS Y CONDICIONES, se entenderá que los Usuarios de la Plataforma crearán de forma autónoma distintos documentos legales una vez se incorporen los datos necesarios para la generación de los mismos. Para efectos de los presentes TÉRMINOS Y CONDICIONES, la Compañía y los Usuarios se denominarán conjuntamente como las “Partes” e individualmente como la “Parte”.
          </p>
        </div>
        <div></div>
        <div>
          <div>
            <p>
              En todo caso, cualquiera de los Usuarios que no acepte los presentes TÉRMINOS Y CONDICIONES, así como las políticas y principios de la Compañía, deberá abstenerse de hacer uso de la Plataforma.
            </p>
          </div>
          <div></div>
          <div>
            <p>
              De igual forma se entiende que al utilizar la Plataforma, los Usuarios están aceptando los TÉRMINOS Y CONDICIONES aquí estipulados.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={2}>
              <li>
                <p><span>II.	DEFINICIONES.</span></p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
              Las Partes acuerdan que, para efectos de los TÉRMINOS Y CONDICIONES, con el objeto de no incurrir en errores y en aras de la brevedad de los conceptos, cuando los siguientes términos sean escritos con letras mayúsculas o en minúsculas (ya fuere que se utilicen en femenino o masculino, singular o plural), tendrán el significado que a continuación se les asigna:
            </p>
          </div>
          <div></div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  a)	<span>Administrador:</span> Usuario con acceso a todas las funciones del manejo de la Plataforma, quien para los presentes TÉRMINOS Y CONDICIONES siempre será la Compañía.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  b)	<span>Usuario Abogado:</span> Persona natural Usuaria que registra su perfil en la Plataforma, obteniendo la posibilidad de realizar todo tipo de ediciones para la generación de documentos legales, al tener total accesibilidad en el manejo de las plantillas. El Usuario Abogado deberá proporcionar la información de su documento de identidad en la Plataforma, lo cual permitirá validar que tiene tarjeta profesional vigente y registrada en el Consejo Superior de la Judicatura. La validación anteriormente mencionada se hará a través de la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  c)	<span>Usuario No Abogado:</span> Persona natural Usuaria que registra su perfil en la Plataforma, y que tiene la posibilidad de generar de forma autónoma sus propios documentos legales. Sin embargo, las acciones, modificaciones y/o ediciones de las plantillas serán restringidas, entendiendo que la Plataforma busca procurar la eficacia y/o validez del documento legal generado.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={4}>
              <li>
                <p>
                  d)	<span>Usuario Estudiante:</span> Persona natural estudiante del pregrado en Derecho que registra su perfil en la Plataforma por medio de convenio que se suscribe con la respectiva institución universitaria.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={5}>
              <li>
                <p>
                  e)	<span>Plan Corporativo:</span> es un tipo de plan que suscribe un Usuario Abogado o Usuario No Abogado para su empresa, con el fin de tener los medios e instrumentos necesarios para generar de forma autónoma los documentos legales que requieran para desarrollar la actividad económica de su compañía. Este plan permitirá la concurrencia de Usuarios Abogados y no Abogados que podrán acceder dentro del mismo plan a la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={6}>
              <li>
                <p>
                  f)	<span>Plan Personas:</span> tipo de plan que suscribe un Usuario Abogado o No Abogado para sí mismo, con el fin de crear los documentos legales requeridos por él.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={7}>
              <li>
                <p>
                  g)	<span>Información Confidencial:</span> Toda la información suministrada por la Parte Reveladora a la Parte Receptora, recibida antes o después de la firma de estos TÉRMINOS Y CONDICIONES y/o contrato entre las Partes , además de todos los resultados generados por las Partes, en particular , pero no limitado a los correos electrónicos, archivos, borradores, diseños, planos, manuales, información de los usuarios, cartas, memos, notas, cuadernos, informes, documentos, hojas de cálculo, información sobre especificaciones comerciales/ financieras/ técnicas, desarrollos tecnológicos, innovaciones, mejoras, presentaciones, fotos y otros papeles impresos y datos en general.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={8}>
              <li>
                <p>
                  h)	<span>Registro:</span> Suscripción en la Plataforma, suministrando toda la información requerida por la Compañía, y comprometiéndose al pago de conformidad con lo establecido en el presente documento.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={9}>
              <li>
                <p>
                  i)	<span>Software como Servicio:</span> El uso de la Plataforma que permite a los Usuarios, la creación, edición y firma de documentos legales de forma autónoma y digital.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
              <span>PARÁGRAFO:</span> Las Partes que se encuentran definidas en los literales b), c) y d) se entenderán en conjunto como los “Usuarios”.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={3}>
              <li>
                <p><span>III.	ESTIPULACIONES</span></p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <p>
            Por medio de la aceptación y suscripción de los presentes TÉRMINOS Y CONDICIONES, los Usuarios facultan a la Compañía a obtener y replicar: los documentos legales comerciales y corporativos, informes financieros, imágenes y demás información similar que sea suministrada a la Compañía, en el marco del uso de la Plataforma.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            Sin embargo, en el ejercicio de la obtención y replica de los documentos, informes financieros, imágenes y demás información similar suministrados por los Usuarios, la Compañía tendrá expresamente prohibido divulgar esta información a terceros sin el consentimiento previo del Usuario en cumplimiento del uso de la Información Confidencial.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            Los Usuarios autorizan el envío de mensajes a través de mensajes de texto y/o correos electrónicos, con el fin de que le notifiquen e informen ofertas de Servicios y productos por parte de la Compañía y sus aliados, mensajes de requerimiento de información, fechas de eventos, transmisión y demás novedades de la Plataforma.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            Así mismo, los Usuarios autorizan a la Compañía a utilizar los documentos legales generados por ellos con el fin de mejorar las plantillas de la Plataforma, y de esta manera el servicio suministrado. La Compañía, correlativamente se obliga a mantener de manera estricta la confidencialidad de las partes que celebran estas cláusulas.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            Por otra parte, la Compañía no se hace responsable respecto a la veracidad de la información o de la eficacia y/o validez de los documentos legales que expidan o compartan los Usuarios y de la demás información que no sea gestionada directamente por el Administrador. La Compañía no garantiza, ni se responsabiliza del funcionamiento o accesibilidad a la Plataforma. Por lo tanto, la Compañía tampoco se hace responsable del resultado obtenido por los documentos legales que expidan los Usuarios, a través del servicio de creación autónoma de documentos legales. Por tanto, los Usuarios no podrán reclamar perjuicios o pérdidas relacionadas por la eficacia del documento generado en la Plataforma. En este sentido, los Usuarios exoneran de total responsabilidad a la Compañía por cualquier perjuicio que se pueda generar con el uso de la Plataforma.
          </p>
        </div>
        <div></div>
        <div>
          <div>
            <p>
              El acceso para los Usuarios tampoco implica que la Compañía dé garantía de la veracidad de la información jurídica suministrada por otros Usuarios.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={4}>
              <li>
                <p><span>IV.	REQUISITOS PARA EL USO DE LA PLATAFORMA.</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
              Los Usuarios, deben contar con un dispositivo móvil inteligente (Smartphone) o Tableta con sistema operativo Android o IOS, o portátil (ordenador) con acceso a internet seguro y confiable. La Compañía no se hace responsable por perdidas de internet ni daños en los dispositivos móviles o computadoras que pudieran llegar a ocurrir debido al uso de la Plataforma o la mala conexión a internet.
            </p>
          </div>
        </div>
        <div></div>
        <div>
          <p>
            <span>PARÁGRAFO.</span> Para poder acceder a la Plataforma, los Usuarios deben crear un nombre de usuario y contraseña que le permite a la Compañía identificarles y dar seguridad de su identidad al momento de ingresar a la Plataforma. Así mismo, el Usuario deberá suministrar su correo electrónico, al cual llegará el código de seguridad que le permitirá el ingreso en la Plataforma.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            En todo caso, la Compañía no se hará responsable por suplantaciones que puedan presentarse tras el control de seguridad implementado para el ingreso a la Plataforma.
          </p>
        </div>
        <div></div>
        <div>
          <div>
            <ol start={5}>
              <li>
                <p><span>V.	DEBERES DE LOS USUARIOS.</span></p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  a)	Mantener discreción con la Información Confidencial y las demás metodologías, procesos y actividades ejecutadas durante la duración de los presentes TÉRMINOS Y CONDICIONES.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  b)	Facilitar de forma oportuna la información solicitada por la Compañía para el registro.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  c)	Enviar información veraz y exacta para cumplir con el objeto del presente documento siempre que le sea requerida por la Compañía.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={4}>
              <li>
                <p>
                  d)	No emplear contenidos y, en particular, la información de cualquier otra cosa obtenida a través de la Compañía para emitir publicidad sin contar con su expresa autorización.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={5}>
              <li>
                <p>
                  e)	Mantener informada a la Compañía sobre cualquier alteración o cambio en la información suministrada.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={6}>
              <li>
                <p>
                  f)	No alterar, bloquear o realizar cualquier otro acto que impida mostrar o acceder a cualquier contenido, información o servicio de la Plataforma o que estén incorporados en páginas web vinculadas.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={7}>
              <li>
                <p>
                  g)	Tener la mayor diligencia con el fin de evitar que terceros hagan uso de su nombre de usuario y contraseña de acceso a la Plataforma.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={8}>
              <li>
                <p>
                  h)	Ingresar sus Datos Personales de manera veraz al momento de registrarse en la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={9}>
              <li>
                <p>
                  i)	Abstenerse de transferir a terceros los datos de validación de su cuenta en la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={10}>
              <li>
                <p>
                  j)	Notificar a la Compañía respecto de cualquier uso no autorizado de su cuenta al interior de la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={11}>
              <li>
                <p>
                  k)	Abstenerse de utilizar la Plataforma para realizar actos contrarios a la moral, la ley, el orden público, y las buenas costumbres en contra de la Compañía, otros Usuarios de la Plataforma, y/o de terceros.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={12}>
              <li>
                <p>
                  l)	Abstenerse de registrar métodos de pago de propiedad de terceros sin autorización de éstos.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={13}>
              <li>
                <p>
                  m)	Pagar cualquier deuda que se genere en virtud del uso de la Plataforma por parte del Usuario.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={14}>
              <li>
                <p>
                  n)	Informarse sobre las instrucciones de uso de la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={15}>
              <li>
                <p>
                  o)	Abstenerse de realizar conductas que atenten en contra del funcionamiento de la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={16}>
              <li>
                <p>
                  p)	Abstenerse de descifrar, descompilar o desensamblar cualquier elemento de la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={17}>
              <li>
                <p>
                  q)	Mantener custodia del dispositivo móvil o portátil por medio del cual accede a la Plataforma, garantizando que terceros no tengan acceso indebido a la Plataforma por acciones u omisiones del Usuario de la Plataforma. Se aclara que, en caso de que se evidencie que el Usuario de la Plataforma no ha custodiado en debida forma su dispositivo móvil o portátil, la Compañía no tendrá ningún tipo de responsabilidad por accesos no autorizados por parte de terceros a la Plataforma.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={18}>
              <li>
                <p>
                  r)	Abstenerse de usar la propiedad intelectual de la Compañía y/o los otros Usuarios para fines no autorizados expresamente por éstos.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={19}>
              <li>
                <p>
                  s)	 El Usuario de la Plataforma reconoce y acepta que la Compañía se reserva el derecho de desactivar las cuentas que no cumplan con estos parámetros.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
              <span>Parágrafo primero:</span> Ante el incumplimiento de cualquiera de las obligaciones contenidas en la presente sección, la Compañía se reserva el derecho de bloquear definitivamente la cuenta del Usuario de la Plataforma en la App.
            </p>
          </div>
          <div></div>
          <div></div>
          <div>
            <ol start={6}>
              <li>
                <p><span>VI.	CENTRO DE AYUDA</span></p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
              El Usuario de la Plataforma dispone del Centro de Ayuda al interior de la Plataforma para presentar PQRs respecto de los Servicios prestados a través de la Plataforma. Se aclara que las PQRs se tramitarán de conformidad con los términos establecidos por la Compañía, los cuales serán informados a los Usuarios.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={7}>
              <li>
                <p><span>VII.	DERECHO DE RETRACTO</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
              En el caso en el que el Usuario desee ejercer el derecho de retracto respecto de los servicios adquiridos a través de la Plataforma, el Usuario de la Plataforma deberá acercarse con la factura de venta directamente a la Compañía través de la Plataforma, en los casos donde sea procedente y exigible según lo establecido en el artículo 47 de la Ley 1480 de 2011.
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={8}>
              <li>
                <p><span>VIII.	DEBERES DE LA COMPAÑÍA.</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={1}>
              <li>
                <p>
                  a)	Usar toda la diligencia razonable para proveer el uso de la Plataforma a los Usuarios las 24 horas del día, siete días de la semana y 365 días del año.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={2}>
              <li>
                <p>
                  b)	Realizar periódicamente mantenimiento y actualizaciones de la Plataforma, lo cual podrá representar para los Usuarios interrupciones, retrasos o errores. La Compañía hará su máximo esfuerzo para comunicar con antelación a los Usuarios cualquier mantenimiento previsto.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={3}>
              <li>
                <p>
                  c)	La Compañía mantendrá actualizada la información que emane de las leyes, reglamentos y disposiciones de las cuales dependen el funcionamiento de la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={4}>
              <li>
                <p>
                  d)	Mantener el cuidado en el uso de la marca de los Usuarios para los fines únicamente relacionados con los Servicios que provee la Plataforma.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={5}>
              <li>
                <p>
                  e)	Asegurar la confidencialidad de la información que se reciba por parte de los Usuarios así como del debido tratamiento de los datos personales que se reciban en el diligenciamiento y registro de la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={6}>
              <li>
                <p>
                  f)	Informar suficientemente a los Usuarios sobre los métodos de pago habilitados al interior de la Plataforma.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={7}>
              <li>
                <p>
                  g)	Poner a disposición del Usuario de la Plataforma los Términos y Condiciones de uso de la Plataforma de forma actualizada.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={9}>
              <li>
                <p><span>IX.	INDEMNIDAD</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
              Los Usuarios aceptan de manera voluntaria, expresa y libre exonerar a la Compañía de toda y cualquier responsabilidad por cualquier y cada uno de los perjuicios que pueda sufrir, incluyendo, pero sin limitarse a: daños, pérdidas, demandas, juicios, acciones judiciales y/o administrativas, multas, penalidades, costos, gastos o reclamos de cualquier tipo (incluyendo gastos y honorarios legales) como consecuencia o con ocasión de las Servicios entre los distintos Usuarios.
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
              La Compañía no será responsable frente a los Usuarios, quienes, por medio de la aceptación de los presentes TÉRMINOS Y CONDICIONES, liberan a la Compañía de cualquier daño o perdida indirecta, incidental y/o especial, incluyendo sin limitación, los daños o pérdidas por lucro cesante, que sufra en virtud de los Servicios la información suministrada a través de la Plataforma.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={10}>
              <li>
                <p><span>X.	CONFIDENCIALIDAD</span></p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <p>
            Las Partes acuerdan guardar absoluta reserva de la Información Confidencial y que conozca de la otra Parte con ocasión de relación contractual, así como a tomar las medidas necesarias a fin de evitar los riesgos de pérdida, destrucción, alteración y uso no autorizado o fraudulento de la misma. Las Partes responderán por los eventuales perjuicios que se causen a la otra Parte. En todo caso, la información sólo podrá ser utilizada para la correcta ejecución de la Plataforma.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            Asimismo, las Partes se obligan a no efectuar anuncios públicos respecto del contenido de los TÉRMINOS Y CONDICIONES, sin previa autorización por escrito de la otra Parte. Lo anterior, sin perjuicio de poder informar la existencia de los mismos.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            La Información Confidencial no incluye a) La información de dominio público al momento de ser recibida; b) La información que llegue a poder de una Parte antes de que la misma le sea comunicada por la otra; c) La información que se convierta en disponible para el público en general sin que ello sea resultado de incumplimiento de sus obligaciones contractuales; d) La información revelada en cumplimiento de un mandato judicial, salvo que se pueda predicar el secreto profesional para abstenerse de realizar dicha revelación.
          </p>
        </div>
        <div>
          <div></div>
          <div>
            <p>
              <span>PARÁGRAFO.</span> Ningún contenido de la Plataforma podrá ser reproducido, transmitido o almacenado en otro sitio web o en otra forma de sistema de recuperación electrónico por alguno de los Usuarios.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={11}>
              <li>
                <p><span>XI.	PROPIEDAD INTELECTUAL.</span></p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
              Todo el material documental, fotográfico, de multimedia, audiovisual, informático y de diseño, así como todos los contenidos, textos, y bases de datos que se encuentran en la Plataforma están protegidos por derechos de autor y/o propiedad industrial cuyo titular es la Compañía o en algunos casos terceros que han autorizado su uso y explotación, única y exclusivamente a Quarta Digital Law LLC.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={12}>
              <li>
                <p><span>XII.	VALOR Y FORMA DE PAGO</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
              Los Usuarios tendrán acceso a la misma por un valor de [-] [$-] los cuales se debitarán del medio de pago con que realice el Registro en la Plataforma.
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
            El débito se hará de manera automática a los [-] días de cada mes.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={13}>
              <li>
                <p><span>XIII.	NO EXCLUSIVIDAD DE PLANTILLAS COMPARTIDAS.</span></p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
            La Compañía permitirá que los Usuarios compartan sus propias plantillas a través de la App. Quarta Digital Law LLC no se hace responsable por controversias relativas a la propiedad intelectual; tampoco se obliga por garantizar la eficacia jurídica de dichas plantillas de documentos legales que fueron compartidas sin intervención del Administrador: 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={14}>
              <li>
                <p><span>XIV.	TERMINACIÓN.</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Se entenderán por terminados los presentes TÉRMINOS Y CONDICIONES en los siguientes casos: 
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={1}>
              <li>
                <p>a)	Por mutuo acuerdo de las Partes.</p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={2}>
              <li>
                <p>
                b)	Por cese del uso de la aplicación por parte de los Usuarios. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={3}>
              <li>
                <p>
                c)	Por incumplimiento de los Usuarios en cualquiera de sus obligaciones.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={4}>
              <li>
                <p>
                d)	Cuando los Usuarios hayan suministrado a la Compañía o a otros Usuarios información inexacta, fraudulenta, no actualizada o incompleta. 
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={5}>
              <li>
                <p>
                e)	Cuando cualquiera de las Partes haya vulnerado la ley o normativas aplicables a estos TÉRMINOS Y CONDICIONES.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            <span>PARÁGRAFO PRIMERO.</span> Los Usuarios podrán desactivar su cuenta en todo momento, sin previo aviso y mediante la Plataforma, en caso de que ya no desee que su perfil sea visible  
            </p>
          </div>
          <div></div>
          <div>
            <p>
            <span>PARÁGRAFO SEGUNDO.</span> Los Usuarios podrán desinstalar en todo momento sin previo aviso la Plataforma. Al desinstalar la Plataforma sin desactivar la cuenta, sus datos permanecerán visibles en la Plataforma.  
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={15}>
              <li>
                <p><span>XV.	IMPUESTOS.</span>;</p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Cada parte deberá pagar todos los impuestos, tasas o aranceles que le corresponda y que sean establecidos por cualquier entidad estatal de orden municipal, estatal o nacional.
            </p>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={16}>
              <li>
                <p><span>XVI.	PROTECCIÓN DE DATOS PERSONALES</span>.</p>
              </li>
            </ol>
          </div>
          <div>
            <p>
            Se entiende para todos los efectos, que, con la suscripción de los TÉRMINOS Y CONDICIONES, las Partes se autorizan entre sí para realizar el tratamiento de los datos personales, en cumplimiento de la normativa de Protección de Datos Personales, especialmente la Ley 1581 de 2012 y los Decretos 1377 de 2013, 886 de 2014, 1074 de 2015 y/o demás normas que los modifiquen y/o adicionen y/o sustituyan y la Política de Protección de Datos Personales divulgada en la página web:
              <a
                href="http://www.anuwa.legal/"
                rel="noreferrer noopener"
                target="_blank"
              >
                www.anuwa.legal.
              </a>
              Por tratamiento de datos se entiende cualquier operación o conjunto de operaciones sobre datos personales, tales como, la recolección, almacenamiento, uso, circulación, transmisión, transferencia o supresión, directamente o a través de terceros contratados para tal fin. 
            </p>
          </div>
          <div>
            <p>
            La Compañía podrá entregar, revelar, transferir o sub-transmitir los Datos Personales a terceros de acuerdo con sus finalidades y guardará estricta confidencialidad de los Datos Personales transferidos de acuerdo con las obligaciones de confidencialidad establecidas en este documento adoptando mecanismos para evitar la divulgación, acceso no autorizado, pérdida o adulteración. La Compañía responderá dentro de los términos legales todas las solicitudes de consulta y reclamo de los titulares, de los que tenga conocimiento y sean formulados de manera directa contra la Compañía por los titulares de Datos Personales que la Compañía reciba en virtud de esta relación contractual.
            </p>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
            Los Usuarios garantizan que han obtenido todas las autorizaciones necesarias para el Tratamiento y la transferencia de los Datos Personales que le entrega a la Compañía con ocasión del Registro y uso de la Plataforma. En tal sentido los Usuarios, se obligan a defender y mantener indemne a la Compañía y a sus representantes, accionistas, empleados y directores o terceros vinculados con respecto a cualquier reclamación, acción judicial o extrajudicial, perjuicio, daño y/o pasivo (incluyendo honorarios razonables de abogado), que surjan para la Compañía en relación con el Tratamiento y la transferencia no autorizada de los Datos Personales efectuada con ocasión o como consecuencia de la ejecución del presente documento.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={17}>
              <li>
                <p><span>XVII.	FUERZA MAYOR</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            En caso de presentarse un evento de fuerza mayor o caso fortuito durante la ejecución de la Plataforma, que impida a la Compañía la ejecución de sus obligaciones, pondrá en conocimiento de los Usuarios dicho evento dentro de las 48 horas siguientes al conocimiento del mismo y prestará toda su colaboración en la solución de la situación y en la limitación de la extensión de un eventual perjuicio derivado de la situación.
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={18}>
              <li>
                <p>
                <span>XVIII.	LISTAS DE CONTROL DE LAVADO DE ACTIVOS Y FINANCIACIÓN DEL TERRORISMO.</span>
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            La Compañía podrá cancelar el Registro en la Plataforma de manera unilateral e inmediatamente, en caso de que los Usuarios llegaren a ser: (i) incluidos en listas para el control de lavado de activos y financiación del terrorismo administradas por cualquier autoridad nacional o extranjera, tales como la lista de la Oficina de Control de Activos en el Exterior – OFAC emitida por la Oficina del Tesoro de los Estados Unidos de Norte América, la lista de la Organización de las Naciones Unidas, así como cualquier otra lista pública relacionada con el tema del lavado de activos y financiación del terrorismo, o (ii) condenados por parte de las autoridades  competentes  en cualquier tipo de proceso judicial relacionado con la comisión de los anteriores delitos. En ese sentido, las partes se autorizan mutuamente de forma irrevocable para que puedan consultar tal información en dichas listas y/o listas similares. Los Usuarios se comprometen a notificar a la Compañía de forma inmediata cualquier acto o sospecha de corrupción, soborno, lavado de activos o financiación del terrorismo, que se presente o se pudiere presentar mediante el uso de la Plataforma. 
            </p>
          </div>
        </div>
        <div>
          <div>
            <p>
            En caso de terminación unilateral del contrato por cualquiera de las Partes por los eventos antes descritos, la parte terminada no tendrá derecho a reconocimiento económico alguno.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={19}>
              <li>
                <p><span>XIX.	CONSERVACIÓN DE DERECHOS</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Ninguna demora por parte de cualquiera de las Partes en el ejercicio de cualquiera de sus respectivos derechos o la falta de ejercicio de los mismos, ni la falta de acción frente a un incumplimiento de cualquier término, disposición o condición de estos TÉRMINOS Y CONDICIONES, se considerará o será interpretada como una renuncia a dichos derechos, por lo que las partes se reservan en todos los casos, el ejercicio de los mismos.
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={20}>
              <li>
                <p>Para eliminar el Registro de la Plataforma de la Compañía los Usuarios deberán enviar un correo electrónico a la siguiente dirección:  <span>auxiliar@quarta.co</span>. La Compañía verificará que se encuentre al día el pago de los servicios causados hasta la fecha por el uso de la Plataforma y procederá posteriormente con a la desactivación del Registro. </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={21}>
              <li>
                <p><span>XXI.	AUTORIZACIONES</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Los Usuarios se obligan durante la vigencia de los TÉRMINOS Y CONDICIONES a mantener vigentes las autorizaciones y permisos que la ley y las autoridades requieran para la prestación de los Servicios. 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={22}>
              <li>
                <p><span>XXII.	MODIFICACIONES</span></p>
              </li>
            </ol>
          </div>
        </div>
        <div></div>
        <div>
          <p>
          La Compañía se reserva el derecho de modificar los TERMINOS Y CONDICIONES en cualquier momento. Toda modificación se comunicará de forma oportuna a los Usuarios a través de los medios habituales de contacto y/o a través de la página web: 
            <a
              href="http://www.anuwa.legal/"
              rel="noreferrer noopener"
              target="_blank"
            >
              www.anuwa.legal
            </a>{" "}
            con quince (15) días hábiles de antelación a su entrada en vigencia.  
          </p>
        </div>
        <div></div>
        <div>
          <p>
          En caso de no estar de acuerdo por razones válidas y que se constituyan en una justa causa con los nuevos TERMINOS Y CONDICIONES, los Usuarios pueden solicitar la cancelación del Registro en la Plataforma. Sin embargo, no se podrá solicitar la cancelación del Registro mientras se mantenga un vínculo de cualquier orden con la entidad u obedezca a una obligación de carácter legal. 
          </p>
        </div>
        <div></div>
        <div>
          <div>
            <ol start={23}>
              <li>
                <p><span>XXIII.	MÉRITO EJECUTIVO</span></p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Los presentes TÉRMINOS Y CONDICIONES prestarán mérito ejecutivo para todos los efectos legales, y las Partes renuncian a tener que ser constituidas en mora.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={24}>
              <li>
                <p><span>XXIV.	VIGENCIA</span></p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
            Los presentes TÉRMINOS Y CONDICIONES tendrán una vigencia de [-]  años a partir de la firma de aceptación de los mismos, los cuales se renovarán automáticamente en caso de que ninguna de las Partes manifieste de manera escrita lo contrario a la otra Parte con una antelación no menor a treinta (30) días. 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={25}>
              <li>
                <p>
                  <span>XXV.	LEGISLACIÓN APLICABLE Y PERFECCIONAMIENTO</span>
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <p>
          Los presentes TÉRMINOS Y CONDICIONES se entienden perfeccionados para todos los efectos legales en el momento en que sean suscritos por los Usuarios a través de la manifestación de la aceptación de los mismos. 
          </p>
        </div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-3">
              <svg
                width="197"
                height="47"
                viewBox="0 0 419 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2_36)">
                  <path
                    d="M94.6006 20.4944H108.866V25.6303C113.31 23.1433 117.344 21.3774 120.962 20.3423C124.579 19.3073 128.403 18.7874 132.436 18.7874C138.948 18.7874 144.067 20.0873 147.787 22.6773C151.508 25.2722 154.143 29.2897 155.697 34.7346C157.247 40.1795 158.025 47.5718 158.025 56.9067V98.2929H143.602V57.2157C143.602 50.6818 143.216 45.6244 142.439 42.0435C141.661 38.4675 140.214 35.8726 138.097 34.2637C135.976 32.6547 132.9 31.8502 128.872 31.8502C123.391 31.8502 117.652 33.0422 111.658 35.4311L109.023 36.6771V98.288H94.6006V20.4944Z"
                    fill="#3641E4"
                  />
                  <path
                    d="M175.234 96.1885C171.616 93.6475 169.083 89.6006 167.636 84.0527C166.189 78.5048 165.465 70.8525 165.465 61.1056V20.4944H179.888V61.1056C179.888 68.2625 180.25 73.6289 180.973 77.2098C181.697 80.7858 183.066 83.3022 185.085 84.7542C187.099 86.2061 190.174 86.9321 194.31 86.9321C197.723 86.9321 200.949 86.5201 204 85.6862C207.046 84.8572 210.331 83.6652 213.846 82.1102V20.4944H228.269V98.2929H213.846V93.157C209.505 95.644 205.599 97.4099 202.137 98.4449C198.671 99.48 194.872 99.9999 190.741 99.9999C184.019 99.9999 178.851 98.7294 175.234 96.1885Z"
                    fill="#3641E4"
                  />
                  <path
                    d="M237.259 20.4944H251.682L265.327 85.8432H268.275L284.555 22.0543H299.598L316.034 85.8432H318.982L332.627 20.4944H346.893L329.679 98.288H306.11L292.152 41.499L278.043 98.288H254.473L237.259 20.4944Z"
                    fill="#3641E4"
                  />
                  <path
                    d="M355.889 93.78C352.061 89.6301 350.149 83.6652 350.149 75.8853C350.149 68.2085 352.11 62.5821 356.04 59.0061C359.966 55.4301 366.219 53.2227 374.804 52.3937L396.824 50.3727V44.3048C396.824 39.7429 395.841 36.4465 393.876 34.4255C391.91 32.4045 389.016 31.394 385.193 31.394C376.51 31.394 366.478 32.017 355.112 33.2629L354.647 22.6822C365.603 20.0921 375.787 18.7922 385.198 18.7922C394.291 18.7922 400.911 20.8427 405.047 24.9386C409.183 29.0395 411.251 35.4949 411.251 44.3097V81.8061C411.456 83.881 412.155 85.4605 413.343 86.5495C414.531 87.6385 416.419 88.3939 419.005 88.806L418.54 100.01C414.096 100.01 410.322 99.5143 407.223 98.5332C404.123 97.5473 401.38 96.0168 399.004 93.9418C390.214 97.9887 381.429 100.01 372.643 100.01C365.3 100.01 359.717 97.9348 355.894 93.7849L355.889 93.78ZM393.573 84.9111L396.829 83.8222V60.9486L376.672 62.8175C372.638 63.2345 369.666 64.451 367.754 66.472C365.838 68.493 364.884 71.4755 364.884 75.4193C364.884 83.7191 368.346 87.8642 375.273 87.8642C381.58 87.8642 387.682 86.8782 393.573 84.9062V84.9111Z"
                    fill="#3641E4"
                  />
                  <path
                    d="M22.1957 84.0626L67.3155 68.3852L67.8973 68.8659L63.8835 98.2929H90.4059L53.1817 0H37.2291L28.4437 23.1924L28.5464 23.6682L65.228 59.4918L65.0666 60.2276L37.2927 69.8764L36.7305 69.6017L24.9482 34.6267L24.117 34.612L0 98.2929H26.1117L21.9219 84.612L22.2006 84.0626H22.1957Z"
                    fill="#3641E4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2_36">
                    <rect width="419" height="100" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="col-12 col-md-3">
              <ul className="menuFooter">
                <a href="#">
                  <li>Home</li>
                </a>
                <a href="#">
                  <li>Mi perfil</li>
                </a>
                <a href="#">
                  <li>Mis documentos</li>
                </a>
              </ul>
            </div>
            <div className="col-12 col-md-3">
              <ul className="menuFooter">
                <li>Contáctanos</li>
                <a href="tel:">
                  <li>01 8000 123 123</li>
                </a>
                <a href="tel:">
                  <li>+57 300 123 45 67</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <nav className="footerNav">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-3">
              <p>© Copyright 2023 all rights reserved</p>
            </div>
            <div className="col-12 col-md-4">
              <p>
                <a href="/termnsandconditions">Términos y condiciones</a> |{" "}
                <a href="/dataprocessingpolicy">Política de tratamiento de datos</a>
              </p>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default TermsConditions;
