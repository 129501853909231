import { useState } from "react";
import { Context } from "../../Context";

export const AuthProvider = ({ children }) => {
  const oidcUser = JSON.parse(
    sessionStorage.getItem(
      `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
    )
  );
  const token = oidcUser.access_token;
  const [isAuth, setIsAuth] = useState(() => {
    return token;
  });
  const [userLogged, setUserLogged] = useState(() => {
    const user = oidcUser;
    if (user) {
      return user;
    }
    return null;
  });

  const user = {
    isAuth,
    userLogged,
    activateAuth: (token, user) => {
      setIsAuth(token);
      setUserLogged(user);
    },
    removeAuth: () => {
      setIsAuth(null);
      setUserLogged(null);
    },
  };
  return <Context.Provider value={user}>{children}</Context.Provider>;
};
