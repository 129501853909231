import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../../commons/enums/async-status-enum";
import { Section } from "../../../commons/redux-state/models/document-template-response-model";
import { LawyerInitialStateModel } from "../models/lawyer-initial-state";

export const lawyerInitialState: LawyerInitialStateModel = {
  documentTemplateData: {
    status: AsyncStatusEnum.idle, 
    documentTemplate: null,
    error: {
      error: false,
      message: "",
    },
  },
  documentSections:[]
};

//Reducer para hacer una copia del documento que se editara
export const lawyerReducer = createSlice({
  name: "lawyer-reducer",
  initialState: lawyerInitialState ,
  reducers: {
    copyDocumentTemplate:(state, action: PayloadAction<Section[]>) =>{
      state.documentSections = action.payload;
    }
  }
});

export const { copyDocumentTemplate } = lawyerReducer.actions;
export default lawyerReducer.reducer;
