import "./data-processing-policy.scss";

function DataProcesing() {
  return (
    <div className="">
      <header id="headerLogin">
        <div className="container">
          <div className="row justify-content-center">
            <svg
              width="193"
              height="40"
              viewBox="0 0 419 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2_22)">
                <path
                  d="M94.6006 20.4944H108.866V25.6303C113.31 23.1433 117.344 21.3774 120.962 20.3423C124.579 19.3073 128.403 18.7874 132.436 18.7874C138.948 18.7874 144.067 20.0873 147.787 22.6773C151.508 25.2722 154.143 29.2897 155.697 34.7346C157.247 40.1795 158.025 47.5718 158.025 56.9067V98.2929H143.602V57.2157C143.602 50.6818 143.216 45.6244 142.439 42.0435C141.661 38.4675 140.214 35.8726 138.097 34.2637C135.976 32.6547 132.9 31.8502 128.872 31.8502C123.391 31.8502 117.652 33.0422 111.658 35.4311L109.023 36.6771V98.288H94.6006V20.4944Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M175.234 96.1885C171.616 93.6475 169.083 89.6006 167.636 84.0527C166.189 78.5048 165.465 70.8525 165.465 61.1056V20.4944H179.888V61.1056C179.888 68.2625 180.25 73.6289 180.973 77.2098C181.697 80.7858 183.066 83.3022 185.085 84.7542C187.099 86.2061 190.174 86.9321 194.31 86.9321C197.723 86.9321 200.949 86.5201 204 85.6862C207.046 84.8572 210.331 83.6652 213.846 82.1102V20.4944H228.269V98.2929H213.846V93.157C209.505 95.644 205.599 97.4099 202.137 98.4449C198.671 99.48 194.872 99.9999 190.741 99.9999C184.019 99.9999 178.851 98.7294 175.234 96.1885Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M237.259 20.4944H251.682L265.327 85.8432H268.275L284.555 22.0543H299.598L316.034 85.8432H318.982L332.627 20.4944H346.893L329.679 98.288H306.11L292.152 41.499L278.043 98.288H254.473L237.259 20.4944Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M355.889 93.78C352.061 89.6301 350.149 83.6652 350.149 75.8853C350.149 68.2085 352.11 62.5821 356.04 59.0061C359.966 55.4301 366.219 53.2227 374.804 52.3937L396.824 50.3727V44.3048C396.824 39.7429 395.841 36.4465 393.876 34.4255C391.91 32.4045 389.016 31.394 385.193 31.394C376.51 31.394 366.478 32.017 355.112 33.2629L354.647 22.6822C365.603 20.0921 375.787 18.7922 385.198 18.7922C394.291 18.7922 400.911 20.8427 405.047 24.9386C409.183 29.0395 411.251 35.4949 411.251 44.3097V81.8061C411.456 83.881 412.155 85.4605 413.343 86.5495C414.531 87.6385 416.419 88.3939 419.005 88.806L418.54 100.01C414.096 100.01 410.322 99.5143 407.223 98.5332C404.123 97.5473 401.38 96.0168 399.004 93.9418C390.214 97.9887 381.429 100.01 372.643 100.01C365.3 100.01 359.717 97.9348 355.894 93.7849L355.889 93.78ZM393.573 84.9111L396.829 83.8222V60.9486L376.672 62.8175C372.638 63.2345 369.666 64.451 367.754 66.472C365.838 68.493 364.884 71.4755 364.884 75.4193C364.884 83.7191 368.346 87.8642 375.273 87.8642C381.58 87.8642 387.682 86.8782 393.573 84.9062V84.9111Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M22.1957 84.0626L67.3155 68.3852L67.8973 68.8659L63.8835 98.2929H90.4059L53.1817 0H37.2291L28.4437 23.1924L28.5464 23.6682L65.228 59.4918L65.0666 60.2276L37.2927 69.8764L36.7305 69.6017L24.9482 34.6267L24.117 34.612L0 98.2929H26.1117L21.9219 84.612L22.2006 84.0626H22.1957Z"
                  fill="#F5F5F5"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_22">
                  <rect width="419" height="100" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </header>
      <div>&nbsp;</div>
      <div className="container polices-aling">
        <div>
          <div>
            <p>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</p>
          </div>
          <div></div>
          <div>
            <p>
            QUARTA DIGITAL LAW LLC (en adelante la “Compañía”), sociedad constituida de conformidad con las leyes de la Florida/Estados Unidos, e identificada con Employer Identification Number: 88-3679156, en cumplimiento de lo dispuesto por la Ley 1581 de 2012 la cual tiene por objeto desarrollar el derecho constitucional de todas las personas a conocer, actualizar y rectificar la información que se haya recogido sobre ellas en bases de datos o archivos y  que sea susceptible de tratamiento  y los demás derechos, libertades y garantías constitucionales  a que se refiere el artículo 15 de la Constitución Política de 1991; así como el derecho a la información consagrado en el artículo 20 de la misma norma; y los Decretos 1377 de 2013, 886 de 2014, 1074 de 2015 y/o demás normas que los modifiquen y/o adicionen y/o sustituyan, los cuales regulan la protección de datos personales y establecen las garantías legales que deben cumplir todas las personas en Colombia para el debido tratamiento de dicha información, desarrolla la siguiente política para el tratamiento de datos personales ( en adelante la “Política”) que le asiste tanto a clientes, accionistas, proveedores y empleados dentro de la Compañía, se permite formular la siguiente Política: 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={1}>
              <li>
                <p>I.	DEFINICIONES </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
            Para los efectos de la presente Política, se entiende por:
            </p>
          </div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                  a) Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                  b) Base de datos: Conjunto Organizado de Datos Personales que sea objeto de Tratamiento.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                c)	Clientes: Son aquellos Usuarios que utilizan la Plataforma “ANUWA”, por medio de la cual se prestará el software como servicio de generación autónoma de documentos legales y que se encuentra en el sitio web:  
                  <a
                    href="http://www.anuwa.legal/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    www.anuwa.legal
                  </a>
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                d)	Datos Personales: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                e)	Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los datos.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                f)	Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del Responsable del Tratamiento.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                g)	Titular: Persona natural cuyos Datos Personales sean objeto de Tratamiento.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                h)	Tratamiento: Cualquier operación o conjunto de operaciones sobre Datos Personales, tales como la recolección, almacenamiento, uso, circulación o supresión.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                i)	Dato público: Es aquel dato calificado como tal según los mandatos de la ley o de la Constitución. Son públicos entre otros, los datos contenidos en documentos públicos, sentencias judiciales ejecutoriadas que no estén sometidas a reserva y los relativos al estado civil de las personas.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                j)	Dato semiprivado: Es semiprivado el dato que no tiene naturaleza intima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su Titular sin a cierto sector o grupo de personas o a la sociedad en general, tales como datos financieros y crediticios de actividades comerciales.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                k)	Dato privado: Es el dato que por su naturaleza íntima o reservada sólo es relevante para el Titular.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                l)	Datos sensibles: aquellos relacionados con el origen racial o étnico, la pertenencia a sindicados, organizaciones sociales o de derechos humanos, convicciones políticas, religiosas, de la vida sexual, biométricos o datos de la salud. Esta información podrá ser otorgada por el Titular de estos datos.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                m)	Aviso de privacidad: documento físico o electrónico generado por el Responsable del Tratamiento que es puesto a disposición del Titular con la información relativa a la existencia de la Política de Tratamiento de la información, que le será aplicable. De igual forma contiene la forma de acceder a la misma y las características del Tratamiento que se pretende dar a los datos personales.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={2}>
              <li>
                <p>
                II.	PRINCIPIOS APLICABLES AL TRATAMIENTO DE DATOS PERSONALES 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            En el desarrollo, interpretación y aplicación de la ley de protección de Datos Personales, la protección de Datos Personales en la Compañía estará sometida a los siguientes principios: 
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Legalidad: El Tratamiento de datos en Colombia es una actividad reglada, la cual debe sujetarse a lo establecido en las normas anteriormente citadas y en las demás disposiciones que la desarrollen. Por ende, los procesos de negocios y destinatarios de esta norma deben sujetarse a lo dispuesto acá.
                </p>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Finalidad: El Tratamiento de datos debe obedecer a una finalidad legítima de acuerdo con la Constitución y la Ley, la cual debe ser informada al Titular de manera concreta y precisa.
                </p>
              </li>
            </ul>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ul>
              <li>
                <p>
                Limitaciones temporales al Tratamiento de datos: Una vez cumplida la finalidad del Tratamiento, la Compañía deberá proceder a la supresión de los Datos Personales. Sin perjuicio de lo anterior, la Compañía deberá cumplir con todas las obligaciones legales y contractuales en materia de Tratamiento de Datos Personales.
                </p>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Libertad: El Tratamiento de datos solo puede ejercerse con el consentimiento previo, expreso e informado del Titular. Por ende, los Datos Personales no podrán ser obtenidos o divulgados sin previa Autorización, o en ausencia de mandato legal o judicial que releve el consentimiento. 
                </p>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Veracidad: La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error. 
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Transparencia: En el Tratamiento de datos se debe garantizar el derecho al Titular a obtener del Responsable de Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernen. 
                </p>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Acceso y circulación restringida: El Tratamiento de datos se sujeta a los límites que se derivan de la naturaleza de los Datos Personales y de los límites establecidos por la Ley. Por ende, el Tratamiento solo podrá hacerse por persona autorizadas por el Titular y por las demás personas que autorice la Ley. Para estos propósitos la obligación de la Compañía será de medios. Los datos proporcionados por el Titular no podrán estar disponible en internet u otros medios de la comunicación masiva, salvo que sea información pública o salvo que el acceso a dicha información sea técnicamente controlable para brindar un conocimiento restringido solo a los Titulares o terceros autorizados por la Ley. 
                </p>
              </li>
            </ul>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ul>
              <li>
                <p>
                Seguridad: La información sujeta a Tratamiento por la Compañía se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. 
                </p>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Confidencialidad: Todas las personas que en la Compañía intervengan en el Tratamiento de Datos Personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprenden el Tratamiento, pudiendo sólo realizar suministro o comunicación de Datos Personales cuando ello corresponda al desarrollo de las actividades autorizadas en la Ley.
                </p>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Datos Sensibles: La Compañía no recolectará aquellos Datos sensibles, los cuales son aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, a la vida sexual y los datos biométricos.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={3}>
              <li>
                <p>III.	ALCANCE </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            La presente Política aplica a los clientes personas naturales o jurídicas, sus representantes legales y empleados, trabajadores, proveedores y a aliados de la Compañía e incluye la información de Datos Personales de Clientes, proveedores, empleados, aliados estratégicos, vinculados y socios. 
            </p>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={4}>
              <li>
                <p>IV.	DERECHOS DE LOS TITULARES DE LOS DATOS</p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>Los derechos del Titular son:</p>
          </div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                a)	Conocer, actualizar y rectificar sus datos personales frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales inexactos, incompletos fraccionados, que induzcan al error, o aquellos cuyo Tratamiento se encuentre prohibido o no haya sido autorizado.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                b)	Solicitar prueba de la Autorización otorgada al Responsable del Tratamiento salvo cuando expresamente lo exceptué la ley. 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del uso que les ha dado a sus datos personales. 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen. 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                e)	Revocar la Autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o el Encargado han incurrido en conductas contrarias a la ley y a la Constitución.
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                Acceder en forma gratuita a sus Datos Personales que hayan sido objeto de Tratamiento
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
            Para consultar, conocer, actualizar, rectificar, suprimir o revocar cualquier tipo de información, el Titular debe presentar por escrito una solicitud al Responsable o al Encargado del Tratamiento de datos personales, mediante la cual se manifieste la razón por la cual se desea realizar alguno de los trámites anteriormente mencionados.
            </p>
          </div>
          <div></div>
          <div>
            <ol start={5}>
              <li>
                <p>
                V.	DEBERES QUE TIENE EL RESPONSABLE CON LOS TITULARES DE LOS DATOS
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
            La Compañía, en su calidad de Responsable asume los siguientes deberes, sin perjuicio de aquellos otros previstos en las disposiciones que regulen o pudiesen llegar a regular esta materia:
            </p>
          </div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                a)	Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                b)	Solicitar y conservar, en las condiciones previstas en la ley, copia de la respectiva Autorización otorgada por el Titular.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                c)	Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la Autorización otorgada.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={5}>
              <li>
                <p>
                e)	Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                f)	 Actualizar la información, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este, se mantenga actualizada.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                g)	Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                h)	Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo tratamiento esté previamente autorizado por la ley.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                i)	 Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                j)	Tramitar las consultas y reclamos formulados en los términos que trata la ley.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                k)	Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley y en especial, para la atención de consultas y reclamos.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                l)	Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                m)	Informar a solicitud del Titular sobre el uso dado a sus datos. 
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                n)	Informar a la autoridad de protección de datos cuando se presente violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                o)	 Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                p)	 Usar únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad con lo previsto en la ley 1581 de 2012 y/o aquellos decretos o normas que lo modifiquen o complementen.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                q)	Abstenerse de circular información que esté siendo controvertida por el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                r)	Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                s)	Usar los datos personales del Titular únicamente para aquellas finalidades para las que se encuentre debidamente facultada y respetando en todo caso la normatividad vigente sobre protección de datos personales. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>VI.	DEBERES DEL ENCARGADO DEL TRATAMIENTO </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Los Encargados del Tratamiento deberán cumplir los siguientes deberes, sin perjuicios de las demás disposiciones previstas en la Ley: 
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                a)	Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                b)	Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                c)	Realizar oportunamente la actualización, rectificación o supresión de los datos en los términos de la ley 1581 de 2012.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                d)	 Actualizar la información reportada por los Responsables del Tratamiento dentro de los cinco (5) días hábiles contados a partir de su recibo.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                e)	 Tramitar las consultas y los reclamos formulados por los Titulares en los términos señalados en la presente Política. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                f)	Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la ley 1581 de 2012 y, en especial, para la atención de consultas y reclamos por parte de los titulares.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                g)	Registrar en la Base de Datos la leyenda “reclamo en trámite” en la forma en que se regula en la presente Política.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={8}>
              <li>
                <p>
                h)	Insertar en la Base de Datos la leyenda “información en discusión judicial” una vez notificado por parte de la autoridad competente sobre procesos judiciales relacionados con la calidad del Dato Personal.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                i)	Abstenerse de circular información que este siendo controvertida por el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio.
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                j)	Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella; 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                k)	Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                l)	 Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio. 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Parágrafo Primero: En el evento en que concurran calidades de Responsable del Tratamiento y Encargado del Tratamiento en la misma persona le será exigible el cumplimiento de los deberes previstos para cada uno. 
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
            Parágrafo Segundo: Cuando la Compañía delegue su función de Encargado del Tratamiento de Datos Personales en un tercero, el tercero que asuma está responsabilidad, deberá cumplir con todas las obligaciones que se encuentran estipuladas en la presente Política y respetar todos los derechos del Titular.  
            </p>
          </div>
          <div></div>
          <div>
            <ol start={7}>
              <li>
                <p>
                VII.	CASOS EN LOS CUALES LA COMPAÑÍA NO REQUIERE AUTORIZACIÓN PARA EL TRATAMIENTO DE LOS DATOS QUE TENGA EN SU PODER 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
            La Autorización del Titular no será necesaria cuando se trate de: 
            </p>
          </div>
          <div></div>
          <div>
            <ol>
              <li>
                <p>
                a)	Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                b)	Datos de naturaleza pública, es decir, aquellos datos que no sean semiprivados, privados o sensibles y pueden estar contenidos, entre otros, en registros y documentos públicos, gacetas y boletines oficiales y sentencias judiciales.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>c)	Casos de urgencia médica o sanitaria.</p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol>
              <li>
                <p>
                d)	Tratamiento de información autorizada por la ley para fines históricos, estadísticos o científicos. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol>
              <li>
                <p>
                e)	Datos relacionados con Registro Civil de las personas. 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={8}>
              <li>
                <p>
                VIII.	TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS Y FINALIDAD DEL MISMO 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
            El Tratamiento de los datos personales de los empleados, proveedores, clientes, accionistas o de cualquier persona con la cual la Compañía tuviere establecida o estableciere una relación, sea permanente u ocasional lo realizará en el marco legal de la materia. 
            </p>
          </div>
          <div></div>
          <div>
            <p>
            En todo caso, los datos personales podrán ser recolectados y tratados para: 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={1}>
              <li>
                <p>
                a)	Realizar el envió de información relacionada con el software como servicio ofrecido por la Compañía. 
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={2}>
              <li>
                <p>
                b)	El desarrollo del objeto de la Compañía;
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={3}>
              <li>
                <p>
                c)	Cumplir las normas aplicables a accionistas, proveedores y contratistas, incluyendo, pero sin limitarse a las tributarias y comerciales; 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={4}>
              <li>
                <p>
                d)	Cumplir lo dispuesto por el ordenamiento jurídico colombiano en material laboral y de seguridad social, entre otras, aplicables a exempleados, empleados actuales y candidatos a futuro empleado. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={5}>
              <li>
                <p>e)	Cumplir todos sus compromisos contractuales.</p>
              </li>
            </ol>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={9}>
              <li>
                <p>
                IX.	POLÍTICAS ESPECÍFICAS PARA EL TRATAMIENTO DE DATOS PERSONALES. 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p>
            Las operaciones que constituyen Tratamiento de Datos Personales por parte de la Compañía, en calidad de Responsable o Encargado del Tratamiento, se regirán por lo dispuesto a continuación:
            </p>
          </div>
          <div></div>
          <div>
            <p>GRUPOS DE INTERÉS</p>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <ol start={1}>
              <li>
                <p>
                a)	Tratamiento de Datos Personales de Clientes:
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            La Compañía almacenará los Datos Personales de quienes decidan contratar el software como servicio que esta provee posterior a la Autorización contenida a través de la aceptación de los Términos y Condiciones de la Plataforma ANUWA, los cuales estarán disponibles en el sitio web: 
              <a
                href="http://www.anuwa.legal/"
                rel="noreferrer noopener"
                target="_blank"
              >
                www.anuwa.legal
              </a>
              , ubicación digital que hará remisión directa a la presente Política. Dichos Datos Personales se almacenarán en carpetas digitales a las que solo podrán acceder aquellos empleados de La Compañia que tengan Autorización por parte del Responsable o del Encargado del Tratamiento de Datos Personales, y que tengan como fin administrar la relación contractual entre la Compañía y el Cliente.
            </p>
          </div>
          <div></div>
          <div>
            <p>
            En el caso que la Compañía contrate los servicios externos de un tercero para realización de su objeto social y que implique el Tratamiento de Datos Personales, éste tercero actuara en calidad de Encargado frente a la recolección de los datos. La Compañía deberá garantizar que la recolección de Datos Personales por parte de terceros se efectúe obteniendo la debida Autorización expresa por parte del titular de los datos donde conste la aprobación de la transferencia de sus datos a ese tercero, quien asumirá las mismas responsabilidades que tiene la Compañía en la presente Política.
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={2}>
              <li>
              b)	Tratamiento de datos personales de los Accionistas:
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Los datos e información personal de las personas naturales que llegaren a tener la condición de accionistas de la Compañía, serán tenidos como información reservada, pues dicha información se encuentra registrada en los libros de comercio y tienen la condición de reservada por disposición de la Ley. Sin embargo, esta información podrá ser revelada en los casos establecido por las normas que regulan el mercado público de valores o por orden de autoridad competente. 
            </p>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
            Para acceder a esta información se deberá proceder de conformidad con lo establecido en el Código de Comercio, la Ley 1258 del 2008 y demás normas que regulen dicha materia. 
            </p>
          </div>
          <div></div>
          <div>
            <p>
            La finalidad que tiene la Compañía con el manejo de la información personal de los accionistas es la siguiente: 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={1}>
              <li>
                <p>
                i.	Permitir el ejercicio de los derechos y deberes que se derivan de la calidad de accionista;
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ol start={2}>
              <li>
                <p>
                ii.	 Envío de invitaciones, convocatoria a eventos y reuniones programados por la Compañía; 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={3}>
              <li>
                <p>
                iii.	Emisión de certificados relativos a la relación del Titular del dato con la Compañía 
                </p>
              </li>
            </ol>
          </div>
          <div>
            <ol start={4}>
              <li>
                <p>
                iv.	Las demás que autorice el Titular- Accionista. 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Los datos de los accionistas serán recolectados y almacenados con la reserva antes mencionada, a través de Autorización expresa de sus titulares, la cual estará contenida en documento escrito y firmado por parte de cada accionista y la cual hace referencia a la presente Política, la cual de igual forma podrá ser consultada en la página web de la Compañía. 
            </p>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <ol start={3}>
              <li>
                <p>
                c)	Tratamiento de Datos Personales de Proveedores y Empleados
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            La Compañía solo recolectará de sus proveedores y empleados, los datos que sean necesarios, pertinentes y útiles, mas no excesivos, con la finalidad de poder seleccionar, evaluar y ejecutar las obligaciones que surjan de cada relación. La obtención de dichos datos se hará a través de un formato de registro de proveedores y empleados, en el cual estará contenida la Autorización por parte del proveedor, y el cual de igual forma hace referencia a la presente Política la cual estará disponible también en la página web de la Compañía.
            </p>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <p>
            Este tipo de datos serán recolectados para el cumplimiento de las siguientes finalidades: 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={1}>
              <li>
                <p>
                i.	Realización de gestiones en las diferentes etapas del contrato (Precontractual, contractual y pos contractual); ii. Las que sean establecidas en el contrato correspondiente y en las autorizaciones que sean otorgadas por los proveedores, cuando estas sean requeridas; iii. Verificar la idoneidad moral y competencia del proveedor y de sus empleados. En este caso, una vez verificada la idoneidad del proveedor, dicha información no será almacenada por la Compañía en ninguna Base de Datos, sino que será devuelta al proveedor, a menos que este autorice que esta información sea almacenada
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ol start={10}>
              <li>
                <p>
                X.	MODIFICACIÓN DE LA POLÍTICA. 
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div></div>
        <div>
          <p>
          La Compañía se reserva el derecho de modificar la Política de Protección de Datos Personales en cualquier momento. Toda modificación se comunicará de forma oportuna a los Titulares de los datos a través de los medios habituales de contacto y/o a través de la página web: 
            <a
              href="http://www.anuwa.legal/"
              rel="noreferrer noopener"
              target="_blank"
            >
              www.anuwa.legal
            </a>{" "}
            con quince (15) días hábiles de antelación a la fecha de entrada en vigencia.  
          </p>
        </div>
        <div></div>
        <div>
          <p>
          En caso de no estar de acuerdo por razones válidas y que se constituyan en una justa causa con las nuevas políticas de manejo de los Datos Personales, los Titulares de la información o sus representantes podrán solicitar a la Sociedad el retiro de su información a través de los medios indicados previamente en esta Política. Sin embargo, no se podrá solicitar el retiro de dicha información mientras se mantenga un vínculo de cualquier orden con la entidad u obedezca a una obligación de carácter legal. 
          </p>
        </div>
        <div></div>
        <div>
          <div>
            <ol start={11}>
              <li>
                <p>XI.	CONSULTAS Y RECLAMOS</p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            Los titulares o sus causahabientes podrán consultar la información personal del Titular que repose en cualquier Base de Datos de la Compañía. El Responsable del Tratamiento o Encargado del Tratamiento deberá suministrar a estos toda la información contenida en el registro individual o que esté vinculada con la identificación del Titular. 
            </p>
          </div>
          <div></div>
          <div>
            <p>
            La consulta se formulará por el medio habilitado por el Responsable del Tratamiento o el Encargado del Tratamiento, siempre y cuando se pueda mantener prueba de esta. 
            </p>
          </div>
        </div>
        <div></div>
        <div>
          <p>
          La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la consulta dentro del dicho término, se informará al interesado, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término. 
          </p>
        </div>
        <div></div>
        <div>
          <p>
          El Titular de los Datos Personales objeto del Tratamiento o sus causahabientes que consideren que la información contenida en una Base de Datos debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la presente Política y en la ley, podrán presentar un reclamo ante el Responsable del Tratamiento o el Encargado del Tratamiento el cual será tramitado bajo las siguientes reglas: 
          </p>
        </div>
        <div></div>
        <div>
          <div>
            <ol start={1}>
              <li>
                <p>
                1.	El reclamo se formulará mediante solicitud dirigida al Responsable del Tratamiento o al Encargado del Tratamiento, con la identificación del Titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y acompañando los documentos que se quieren hacer valer. Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo. 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            En caso de que quien reciba el reclamo no sea competente para resolverlo, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interesado. Una vez recibido el reclamo completo, se incluirá en la Base de Datos, en un término no mayor a dos (2) días hábiles, una leyenda que diga “reclamo en trámite” y el motivo del mismo. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.  
            </p>
          </div>
          <div></div>
          <div>
            <ol start={2}>
              <li>
                <p>
                2.	El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho termino, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. 
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div></div>
          <div>
            <p>
            El Titular o causahabiente sólo podrá elevar queja ante la Superintendencia de Industria y Comercio una vez haya agotado el trámite de consulta o reclamo ante el Responsable del Tratamiento o el Encargado del Tratamiento. 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={12}>
              <li>
                <p>
                XII.	SEGURIDAD DE LA INFORMACIÓN Y MEDIDAS DE SEGURIDAD. 
                </p>
              </li>
            </ol>
          </div>
        </div>
        <div>
          <div>
            <p>
            Dando cumplimiento al principio de seguridad establecido en la normatividad vigente. La Compañía adoptará las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, perdida, consulta, uso o acceso no autorizado o fraudulento. 
            </p>
          </div>
          <div></div>
          <div>
            <ol start={13}>
              <li>
                <p>
                XIII.	UTILIZACIÓN Y TRANSFERENCIA INTERNACIONAL DE DATOS PERSONALES E INFORMACIÓN PERSONAL POR PARTE DE LA COMPAÑÍA. 
                </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            La Compañía, atendiendo a la naturaleza de las relaciones permanentes u ocasionales que cualquier persona Titular de los Datos personales pueda tener con la misma, podrá realizar la transferencia y transmisión, incluso internacional, de la totalidad de los Datos Personales, siempre y cuando se cumplan los requerimientos legales aplicables. En consecuencia, los Titulares con la aceptación de la presente Política, autorizan expresamente para transferir y transmitir, incluso a nivel internacional, los Datos Personales. Los datos serán transferidos, para todas las relaciones que puedan establecerse con la Compañía 
            </p>
          </div>
        </div>
        <div></div>
        <div>
          <p>
          Para la transferencia internacional de Datos Personales de los Titulares, la Compañía tomará las medidas necesarias para que los terceros conozcan y se comprometan a observar la presente Política, bajo el entendido que la información personal que reciban, únicamente podrá ser utilizada para asuntos directamente relacionados con la Compañía y solamente mientras estos duren y no podrá ser usada o destinada para propósito o fin diferente. Para la transferencia internacional de Datos Personales se observará lo previsto en el artículo 26 de la Ley 1581 de 2012. 
          </p>
        </div>
        <div></div>
        <div>
          <p>
          La Compañía, también podrá intercambiar información personal con autoridades gubernamentales o públicas de otro tipo (incluidas, entre otras autoridades judiciales o administrativas, autoridades fiscales y organismos de investigación penal, civil, administrativa, disciplinaria y fiscal), y terceros participantes en procedimientos legales civiles y sus contadores, auditores, abogados y otros asesores y representantes, porque es necesario o apropiado: (a) para cumplir con las leyes vigentes, incluidas las leyes distintas a las de su país de residencia; (b) para cumplir con procesos jurídicos; (c) para responder las solicitudes de las autoridades públicas y del gobierno, y para responder las solicitudes de las autoridades públicas y del gobierno distintas a las de su país de residencia; (d) para hacer cumplir nuestros términos y condiciones; (e) para proteger nuestras operaciones; (f) para proteger nuestros derechos, privacidad, seguridad o propiedad, los suyos o los de terceros; y (g) obtener las indemnizaciones aplicables o limitar los daños y perjuicios que nos puedan afectar.
          </p>
        </div>
        <div></div>
        <div>
          <div>
            <ol start={14}>
              <li>
                <p>XIV.	DATOS DE CONTACTO </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            En caso de presentarse cualquier inquietud, duda o reclamo, el interesado puede dirigir sus inquietudes al correo:  auxiliar@quarta.co o comunicarse al teléfono 604 5899679.
            </p>
          </div>
          <div></div>
          <div>
            <p>
            También podrá enviar comunicación por escrito a la siguiente dirección: Calle 7 Sur No. 42 – 70, Edificio Fórum, Oficina 611.
            </p>
          </div>
        </div>
        <div>
          <div>
            <ol start={15}>
              <li>
                <p>XV.	LEY, JURISDICCIÓN Y VIGENCIA. </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>
                Constitución Política de 1991, artículo 15 y artículo 20. 
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <ul>
              <li>
                <p>Ley 1266 de 2008 </p>
              </li>
              <li>
                <p>Ley 1581 de 2012</p>
              </li>
              <li>
                <p>Decreto reglamentario 1727 de 2009</p>
              </li>
              <li>
                <p>Decreto reglamentario 2952 de 2010</p>
              </li>
              <li>
                <p>Decreto 1377 de 2013&</p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <ul>
              <li>
                <p>Decreto 886 de 2014</p>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <ol start={16}>
              <li>
                <p>XVI.	VIGENCIA. </p>
              </li>
            </ol>
          </div>
          <div></div>
          <div>
            <p>
            La presente Política de Tratamiento de Datos Personales entra en vigencia a partir de _________________________  y las Bases de Datos que contienen la información de los titulares tendrá vigencia de ________  prorrogables por períodos iguales. 
            </p>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-3">
              <svg
                width="197"
                height="47"
                viewBox="0 0 419 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2_36)">
                  <path
                    d="M94.6006 20.4944H108.866V25.6303C113.31 23.1433 117.344 21.3774 120.962 20.3423C124.579 19.3073 128.403 18.7874 132.436 18.7874C138.948 18.7874 144.067 20.0873 147.787 22.6773C151.508 25.2722 154.143 29.2897 155.697 34.7346C157.247 40.1795 158.025 47.5718 158.025 56.9067V98.2929H143.602V57.2157C143.602 50.6818 143.216 45.6244 142.439 42.0435C141.661 38.4675 140.214 35.8726 138.097 34.2637C135.976 32.6547 132.9 31.8502 128.872 31.8502C123.391 31.8502 117.652 33.0422 111.658 35.4311L109.023 36.6771V98.288H94.6006V20.4944Z"
                    fill="#3641E4"
                  />
                  <path
                    d="M175.234 96.1885C171.616 93.6475 169.083 89.6006 167.636 84.0527C166.189 78.5048 165.465 70.8525 165.465 61.1056V20.4944H179.888V61.1056C179.888 68.2625 180.25 73.6289 180.973 77.2098C181.697 80.7858 183.066 83.3022 185.085 84.7542C187.099 86.2061 190.174 86.9321 194.31 86.9321C197.723 86.9321 200.949 86.5201 204 85.6862C207.046 84.8572 210.331 83.6652 213.846 82.1102V20.4944H228.269V98.2929H213.846V93.157C209.505 95.644 205.599 97.4099 202.137 98.4449C198.671 99.48 194.872 99.9999 190.741 99.9999C184.019 99.9999 178.851 98.7294 175.234 96.1885Z"
                    fill="#3641E4"
                  />
                  <path
                    d="M237.259 20.4944H251.682L265.327 85.8432H268.275L284.555 22.0543H299.598L316.034 85.8432H318.982L332.627 20.4944H346.893L329.679 98.288H306.11L292.152 41.499L278.043 98.288H254.473L237.259 20.4944Z"
                    fill="#3641E4"
                  />
                  <path
                    d="M355.889 93.78C352.061 89.6301 350.149 83.6652 350.149 75.8853C350.149 68.2085 352.11 62.5821 356.04 59.0061C359.966 55.4301 366.219 53.2227 374.804 52.3937L396.824 50.3727V44.3048C396.824 39.7429 395.841 36.4465 393.876 34.4255C391.91 32.4045 389.016 31.394 385.193 31.394C376.51 31.394 366.478 32.017 355.112 33.2629L354.647 22.6822C365.603 20.0921 375.787 18.7922 385.198 18.7922C394.291 18.7922 400.911 20.8427 405.047 24.9386C409.183 29.0395 411.251 35.4949 411.251 44.3097V81.8061C411.456 83.881 412.155 85.4605 413.343 86.5495C414.531 87.6385 416.419 88.3939 419.005 88.806L418.54 100.01C414.096 100.01 410.322 99.5143 407.223 98.5332C404.123 97.5473 401.38 96.0168 399.004 93.9418C390.214 97.9887 381.429 100.01 372.643 100.01C365.3 100.01 359.717 97.9348 355.894 93.7849L355.889 93.78ZM393.573 84.9111L396.829 83.8222V60.9486L376.672 62.8175C372.638 63.2345 369.666 64.451 367.754 66.472C365.838 68.493 364.884 71.4755 364.884 75.4193C364.884 83.7191 368.346 87.8642 375.273 87.8642C381.58 87.8642 387.682 86.8782 393.573 84.9062V84.9111Z"
                    fill="#3641E4"
                  />
                  <path
                    d="M22.1957 84.0626L67.3155 68.3852L67.8973 68.8659L63.8835 98.2929H90.4059L53.1817 0H37.2291L28.4437 23.1924L28.5464 23.6682L65.228 59.4918L65.0666 60.2276L37.2927 69.8764L36.7305 69.6017L24.9482 34.6267L24.117 34.612L0 98.2929H26.1117L21.9219 84.612L22.2006 84.0626H22.1957Z"
                    fill="#3641E4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2_36">
                    <rect width="419" height="100" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="col-12 col-md-3">
              <ul className="menuFooter">
                <a href="#">
                  <li>Home</li>
                </a>
                <a href="#">
                  <li>Mi perfil</li>
                </a>
                <a href="#">
                  <li>Mis documentos</li>
                </a>
              </ul>
            </div>
            <div className="col-12 col-md-3">
              <ul className="menuFooter">
                <li>Contáctanos</li>
                <a href="tel:">
                  <li>01 8000 123 123</li>
                </a>
                <a href="tel:">
                  <li>+57 300 123 45 67</li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <nav className="footerNav">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-md-3">
              <p>© Copyright 2023 all rights reserved</p>
            </div>
            <div className="col-12 col-md-4">
              <p>
                <a href="/termnsandconditions">Términos y condiciones</a> |{" "}
                <a href="/dataprocessingpolicy">
                  Política de tratamiento de datos
                </a>
              </p>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default DataProcesing;
