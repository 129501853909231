import React, { FC, useEffect, useRef } from "react";
import "./build-block-form-component.scss"
import { useFormik } from "formik";
import { DatePickerFormModel, DropdownFormModel, SignatureFormModel, TextBoxFormModel, TextFieldFormModel, TextFormModel } from "../../redux-state/models/text-form-model";
import * as yup from 'yup';
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { SelectedFormEnum } from "../../enums/selected-form-enum";
import { updatePreviewChildren } from "../../redux-state/reducers/paragraph-build-reducer";
import { ParagraphBuildService } from "../../services/paragraph-build-service";
import { Button, FormControlLabel } from "@mui/material";

//este componente contiene todos los posibles formularios que pueden generarse como producto de la creación de una plantilla nueva 
//en la vista de creación de parrafo

//contenedor padre de los formularios
export const FormParentComponent = () => {

    const selectedForm = useAppSelector((state) => state.paragraphBuildReducer.selectedForm); 

    return (
        <>
            {   
                selectedForm !== SelectedFormEnum.idle &&
                <div className="form-parent">
                    {selectedForm === SelectedFormEnum.textForm && <TextForm />}
                    {selectedForm === SelectedFormEnum.textFieldForm && <TextFieldForm />}
                    {selectedForm === SelectedFormEnum.datePickerForm && <DatePickerForm />}
                    {selectedForm === SelectedFormEnum.textBoxForm && <TextBoxForm />}
                    {selectedForm === SelectedFormEnum.boldTextForm && <BoldTextForm />}
                    {selectedForm === SelectedFormEnum.signatureForm && <SignatureForm />}
                </div>
            }
        </>
    )
    
}

//formulario para agragra texto plano ----------------------------------------------------------------------------
export const TextForm = () => {

    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: TextFormModel = {
        text: ""
    }

    const validationSchema = yup.object({
        text: yup.string()
        .max(100000, "El texto debe ser maximo de 100000 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres")
        .required("El texto es requerido")
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  
            dispatch(updatePreviewChildren(ParagraphBuildService.getText(`${values.text} `)));
            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    return (

        <form onSubmit={form.handleSubmit} ref={formRef}>

            <h6 className="element-form__title">Agrega la información del texto</h6>

            <div>
                <TextField 
                    variant="outlined"
                    label="Por favor ingrese el texto"
                    style = {{background: "white"}}
                    id="text"
                    name="text"
                    multiline
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.text && form.touched.text && <p className="validation-error">{form.errors.text}</p>}
            </div>

            <div className="element-form__button-bar--no-grid">
                <button className="forward-button" type="submit" disabled={!form.isValid || !form.dirty }>Agregar Texto</button>
            </div>

        </form>
    )
}

//formulario para agragra texto en negritas ----------------------------------------------------------------------------
export const BoldTextForm = () => {

    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: TextFormModel = {
        text: ""
    }

    const validationSchema = yup.object({
        text: yup.string()
        .max(100000, "El texto debe ser maximo de 100000 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres")
        .required("El texto es requerido")
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  
            dispatch(updatePreviewChildren(ParagraphBuildService.getBoldText(`${values.text} `)));
            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    return (

        <form onSubmit={form.handleSubmit} ref={formRef}>

            <h6 className="element-form__title">Agrega el texto en negrita</h6>

            <div>
                <TextField 
                    variant="outlined"
                    label="Por favor ingrese el texto"
                    style = {{background: "white"}}
                    id="text"
                    name="text"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.text && form.touched.text && <p className="validation-error">{form.errors.text}</p>}
            </div>

            <div className="element-form__button-bar--no-grid">
                <button className="forward-button" type="submit" disabled={!form.isValid || !form.dirty }>Agregar Texto</button>
            </div>

        </form>
    )
}

//formulario para agragra un campo de texto de material UI----------------------------------------------------------------------------
export const TextFieldForm = () => {

    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: TextFieldFormModel = {
        label: "",
        tooltip: false,
        tooltipText: "",
        required: false,
        defaultValue: ""
    }

    const validationSchema = yup.object({
        label: yup.string()
        .max(30, "El texto del label debe ser maximo de 30 caracteres")
        .min(1, "El texto del label debe ser mínimo de 1 caracteres")
        .required("El texto del label es requerido"),
        tooltip: yup.boolean()
        .required(""),
        required: yup.boolean()
        .required(""),
        tooltipText: yup.string()
        .max(1000, "El texto debe ser maximo de 1000 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres"),
        defaultValue: yup.string()
        .max(100, "El texto debe ser maximo de 100 caracteres")
        .min(2, "El texto debe ser mínimo de 2 caracteres"),
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  
            dispatch(updatePreviewChildren(ParagraphBuildService.getTextFieldInput(values)));
            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    const handleReset = () => {
        form.resetForm();
    }

    return (

        <form className="element-form" onSubmit={form.handleSubmit} ref={formRef}>

            <h6 className="element-form__title">Agrega la información del input de texto</h6>

            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese texto del lable"
                    style = {{background: "white"}}
                    id="label"
                    name="label"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.label && form.touched.label && <p className="validation-error">{form.errors.label}</p>}
            </div>

            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese el valor por defecto"
                    style = {{background: "white"}}
                    id="defaultValue"
                    name="defaultValue"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.defaultValue && form.touched.defaultValue && <p className="validation-error">{form.errors.defaultValue}</p>}
            </div>

            <FormControlLabel control={<Checkbox id="tooltip" name="tooltip" checked={form.values.tooltip} onChange={form.handleChange}/>} label="¿Desea añadir autoayuda?" />

            <FormControlLabel control={<Checkbox id="required" name="required" checked={form.values.required} onChange={form.handleChange}/>} label="¿Este campo es obligatorio?" />

            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese texto de la autoayuda"
                    style = {{background: "white"}}
                    id="tooltipText"
                    name="tooltipText"
                    disabled={!form.values.tooltip}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.tooltipText && form.touched.tooltipText && <p className="validation-error">{form.errors.label}</p>}
            </div>
            

            <div className="element-form__button-bar">
                <button className="clean" type="reset" onClick={handleReset}>Limpiar formulario</button>
                <button className="element-form__forward-button" type="submit" disabled={!form.isValid || !form.dirty }>Agregar input de texto</button>
            </div>

        </form>
    )
}

//formulario para agragra un selector de fecha de material UI ----------------------------------------------------------------------------
export const DatePickerForm = () => {

    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: DatePickerFormModel = {
        label: "",
        tooltip: false,
        tooltipText: "",
        required: false
    }

    const validationSchema = yup.object({
        label: yup.string()
        .max(30, "El texto del label debe ser maximo de 30 caracteres")
        .min(1, "El texto del label debe ser mínimo de 1 caracteres")
        .required("El texto del label es requerido"),
        tooltip: yup.boolean()
        .required(""),
        tooltipText: yup.string()
        .max(1000, "El texto debe ser maximo de 1000 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres"),
        required: yup.boolean()
        .required("")
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  
            dispatch(updatePreviewChildren(ParagraphBuildService.getDateInput(values)));
            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    const handleReset = () => {
        form.resetForm();
    }

    return (

        <form onSubmit={form.handleSubmit} ref={formRef}>

            <h6 className="element-form__title">Agregar la información del selector de fecha</h6>

            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese texto del lable"
                    style = {{background: "white"}}
                    id="label"
                    name="label"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.label && form.touched.label && <p className="validation-error">{form.errors.label}</p>}
            </div>

            <FormControlLabel control={<Checkbox id="required" name="required" checked={form.values.required} onChange={form.handleChange}/>} label="¿Este campo es obligatorio?" />

            <FormControlLabel control={<Checkbox id="tooltip" name="tooltip" checked={form.values.tooltip} onChange={form.handleChange}/>} label="Desea añadir autoayuda?" />
  
            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese texto de la autoayuda"
                    style = {{background: "white"}}
                    id="tooltipText"
                    name="tooltipText"
                    disabled={!form.values.tooltip}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.tooltipText && form.touched.tooltipText && <p className="validation-error">{form.errors.label}</p>}
            </div>
            
            <div className="element-form__button-bar--no-grid-multiple">
                <button className="clean" type="reset" onClick={handleReset}>Limpiar formulario</button>
                <button className="forward-button" type="submit" disabled={!form.isValid || !form.dirty }>Agregar selector de fecha</button>
            </div>

        </form>
    ) 
}

//formulario para agragra una caja de texto de material UI ----------------------------------------------------------------------------
export const TextBoxForm = () => {

    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: TextBoxFormModel = {
        label: "",
        tooltip: false,
        tooltipText: "",
        required: false,
        defaultValue: ""
    }

    const validationSchema = yup.object({
        label: yup.string()
        .max(30, "El texto del label debe ser maximo de 30 caracteres")
        .min(1, "El texto del label debe ser mínimo de 1 caracteres")
        .required("El texto del label es requerido"),
        tooltip: yup.boolean()
        .required(""),
        tooltipText: yup.string()
        .max(1000, "El texto debe ser maximo de 1000 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres"),
        required: yup.boolean()
        .required(""),
        defaultValue: yup.string()
        .max(100, "El texto debe ser maximo de 100 caracteres")
        .min(2, "El texto debe ser mínimo de 2 caracteres"),
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  
            dispatch(updatePreviewChildren(ParagraphBuildService.getTextBoxInput(values)));
            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    const handleReset = () => {
        form.resetForm();
    }

    return (

        <form className="element-form" onSubmit={form.handleSubmit} ref={formRef}>

            <h6 className="element-form__title">Agregar información de la caja de texto</h6>

            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese texto del lable"
                    style = {{background: "white"}}
                    id="label"
                    name="label"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.label && form.touched.label && <p className="validation-error">{form.errors.label}</p>}
            </div>

            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese el valor por defecto"
                    style = {{background: "white"}}
                    id="defaultValue"
                    name="defaultValue"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.defaultValue && form.touched.defaultValue && <p className="validation-error">{form.errors.defaultValue}</p>}
            </div>

           <FormControlLabel control={<Checkbox id="tooltip" name="tooltip" checked={form.values.tooltip} onChange={form.handleChange}/>} label="Desea añadir autoayuda?" />

           <FormControlLabel control={<Checkbox id="required" name="required" checked={form.values.required} onChange={form.handleChange}/>} label="¿Este campo es obligatorio?" />

            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese texto de la autoayuda"
                    style = {{background: "white"}}
                    id="tooltipText"
                    name="tooltipText"
                    disabled={!form.values.tooltip}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.tooltipText && form.touched.tooltipText && <p className="validation-error">{form.errors.label}</p>}
            </div>
            
            <div className="element-form__button-bar">
                <button className="clean" type="reset" onClick={handleReset}>Limpiar formulario</button>
                <button className="element-form__forward-button" type="submit" disabled={!form.isValid || !form.dirty }>Agregar caja de texto</button>
            </div>
        </form>
    )
}

//formulario para agregar un dropdown de material UI----------------------------------------------------------------------------
interface DropdownFormProps {
    setActiveStep: any;
    setShowDropdownFactoryModal: any;
    dropdownData: DropdownFormModel; 
    setDropdownData: any;
}
export const DropdownForm:FC<DropdownFormProps> = ({setActiveStep, setShowDropdownFactoryModal, dropdownData, setDropdownData}) => {

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues = {
        label: dropdownData.label,
        tooltip: dropdownData.tooltip,
        tooltipText: dropdownData.tooltipText,
        required: dropdownData.required
    }

    const validationSchema = yup.object({
        label: yup.string()
        .max(30, "El texto del label debe ser maximo de 30 caracteres")
        .min(1, "El texto del label debe ser mínimo de 1 caracteres")
        .required("El texto del label es requerido"),
        tooltip: yup.boolean()
        .required(""),
        tooltipText: yup.string()
        .max(1000, "El texto debe ser maximo de 1000 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres"),
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  
            
            setDropdownData({
                ...dropdownData,
                label: values.label,
                tooltip: values.tooltip,
                tooltipText: values.tooltipText,
                required: values.required
            })
            setActiveStep(1);

            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    useEffect(() => {
        setDropdownData({
            ...dropdownData,
            label: form.values.label,
            tooltip: form.values.tooltip,
            tooltipText: form.values.tooltipText,
            required: form.values.required
        })
    }, [form.values])

    const handleCancelButtonClick = () => {
        setActiveStep(0);
        setDropdownData({
            label: "",
            tooltip: false,
            tooltipText: "",
            dropdownOptions: [],
            required: false
        });
        setShowDropdownFactoryModal(false);
    }

    return (

        <form className="dropdown-form" onSubmit={form.handleSubmit} ref={formRef}>

            <div>
                <TextField 
                    variant="outlined"
                    label="Nombre de la lista desplegable"
                    style = {{background: "white"}}
                    id="label"
                    name="label"
                    defaultValue={form.values.label}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.label && form.touched.label && <p className="validation-error">{form.errors.label}</p>}
            </div>

            <FormControlLabel control={<Checkbox id="required" name="required" checked={form.values.required} onChange={form.handleChange}/>} label="¿Este es un campo obligatorio?" />

            <FormControlLabel control={<Checkbox id="tooltip" name="tooltip" checked={form.values.tooltip} onChange={form.handleChange}/>} label="Desea añadir autoayuda?" />
  
            <div>
                <TextField 
                    variant="outlined"
                    label="Ingrese texto de la autoayuda"
                    style = {{background: "white"}}
                    id="tooltipText"
                    name="tooltipText"
                    defaultValue={form.values.tooltipText}
                    disabled={!form.values.tooltip}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.tooltipText && form.touched.tooltipText && <p className="validation-error">{form.errors.label}</p>}
            </div>

            <div className="dropdown-button-bar">
                <button className="back-button" type="button" onClick={handleCancelButtonClick}>Cancelar</button>
                <button className="forward-button" type="submit">Siguiente</button>
            </div>
            

        </form>
    )
}

//formulario para agregar una firma ----------------------------------------------------------------------------
export const SignatureForm = () => {

    const dispatch = useAppDispatch();

    const formRef = useRef<HTMLFormElement>(null);

    const initialValues: SignatureFormModel = {
        label: ""
    }

    const validationSchema = yup.object({
        label: yup.string()
        .max(50, "El texto debe ser maximo de 50 caracteres")
        .min(1, "El texto debe ser mínimo de 1 caracteres")
        .required("El texto es requerido")
    })


    const form = useFormik({
        initialValues: initialValues,
        onSubmit: (values, {setSubmitting, resetForm}) => {  
            dispatch(updatePreviewChildren(ParagraphBuildService.getSignature(`${values.label} `)));
            resetForm();
            setSubmitting(false);
            formRef.current?.reset();
        },
        validationSchema: validationSchema
    })

    return (

        <form onSubmit={form.handleSubmit} ref={formRef}>

            <h6 className="element-form__title">Nombre de quien firma </h6>

            <div>
                <TextField 
                    variant="outlined"
                    label="Por favor ingrese el texto"
                    style = {{background: "white"}}
                    id="label"
                    name="label"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                />
                {form.errors.label && form.touched.label && <p className="validation-error">{form.errors.label}</p>}
            </div>

            <div className="element-form__button-bar--no-grid">
                <button className="forward-button" type="submit" disabled={!form.isValid || !form.dirty }>Agregar firma</button>
            </div>

        </form>
    )
}