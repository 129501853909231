export const elementTypeConstants = {
  PRINT: 'print',
  TEXT: 'InputText',
  PASSWORD: 'password',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  TEXTAREA: 'TextArea',
  SIMPLE_SELECT: 'Select',
  DATEPICKER: 'DatePicker',
  SIGNATURE:'Signature'
}
