import { ValidateUserSessionService } from "../../services/ValidateUserSessionService";
import { SubCategoryModel } from "../redux-state/models/subcategory-model";

export class SubCategoryService {
 /**
   * Metodo para obtener Sub categorias
   * @param token {token de acceso del usuario}
   * @returns todas las sub categorias
   */

  static async getAllSubCategories(token: string): Promise<SubCategoryModel|null> {
    try 
    {
        const response = await fetch(`${process.env.REACT_APP_API_URL}Category/getAll`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //validate token with status 401
      ValidateUserSessionService.handleError(response.status);
      const catogories: SubCategoryModel = await response.json();
      return catogories;
    } catch (error) {
        return null
    }
  }


    /**
   * Metodo para obtener sub categorias por el Id de la categoria
   * @param categoryId {Id de la categoria}
   * @param token {token de acceso del usuario}
   * @returns sub categorias
   */

     static async getSubCategoryByCategoryId(categoryId: number, token: string): Promise<SubCategoryModel|null> {
      try 
      {
          const response = await fetch(`${process.env.REACT_APP_API_URL}SubCategory/getAllByCategoryId?categoryId=${categoryId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        //validate token with status 401
        ValidateUserSessionService.handleError(response.status);
        const subcategories: SubCategoryModel = await response.json();
        return subcategories;
      } catch (error) {
          return null
      }
    }
}