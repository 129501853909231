import React from "react";
import './page-error-view.scss';

export default function PageErrorView() {
	return (
		<section className="error">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2>Oops!</h2>
						<button>back to home</button>
					</div>
				</div>
			</div>
		</section>
	);
}