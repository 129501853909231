import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncStatusEnum } from "../../enums/async-status-enum";
import { CommonInitialStateModel } from "../models/common-initial-state-model";
import { CurrentDocumentPreviewModel } from "../models/current-document-preview-request-model";
import { CurrentDocumentPreviewResponseModel } from "../models/current-document-preview-response-model";
import { getDocumentPreviewThunk, getDocumentTemplatePreviewThunk } from "../thunks/get-document-template-preview";

export const commonInitialState: CommonInitialStateModel = {
  documentTemplatePreviewResponseData: {
    status: AsyncStatusEnum.idle,
    documentTemplatePreviewResponse: null,
    error: {
      error: false,
      message: "",
    },
  },
  currentDocumentPreview: null,
};

//Common reducer, para el estado del documento que se esta editando
export const commonReducer = createSlice({
  name: "common-reducer",
  initialState: commonInitialState,
  reducers: {
    updateCurrentDocumentPreview: (state, action: PayloadAction<CurrentDocumentPreviewModel>) => {state.currentDocumentPreview = action.payload;},
    updateCurrentDocumentPreviewFieldValue: ( state, action: PayloadAction<any>
    ) => {
      if(action.payload.subsectionIndex === null || action.payload.subsectionIndex === undefined){ 
        state.documentTemplatePreviewResponseData!.documentTemplatePreviewResponse!.data.section[action.payload.sectionIndex].clause[action.payload.clauseIndex].fieldList[action.payload.fieldIndex]!.fieldValue = action.payload.valueField;
      }else{ 
        state.documentTemplatePreviewResponseData!.documentTemplatePreviewResponse!.data!.section[action.payload.sectionIndex].section[action.payload.subsectionIndex].clause[action.payload.clauseIndex].fieldList[action.payload.fieldIndex]!.fieldValue = action.payload.valueField;
      } 
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentTemplatePreviewThunk.pending, (state) => { state.documentTemplatePreviewResponseData.status = AsyncStatusEnum.loading;})
      .addCase(getDocumentTemplatePreviewThunk.rejected, (state, action) => {
        state.documentTemplatePreviewResponseData.status = AsyncStatusEnum.rejected;
        state.documentTemplatePreviewResponseData.error = {
          error: true,
          message: "Error rejected",
        };
        state.documentTemplatePreviewResponseData.documentTemplatePreviewResponse = commonInitialState.documentTemplatePreviewResponseData.documentTemplatePreviewResponse;
      })
      .addCase(
        getDocumentTemplatePreviewThunk.fulfilled,
        (state, action: PayloadAction<CurrentDocumentPreviewResponseModel>) => {
          state.documentTemplatePreviewResponseData.status = AsyncStatusEnum.completed;
          state.documentTemplatePreviewResponseData.error = {
            error: false,
            message: "",
          };
          state.documentTemplatePreviewResponseData.documentTemplatePreviewResponse = action.payload;
        }
      )
      .addCase(getDocumentPreviewThunk.pending, (state) => { state.documentTemplatePreviewResponseData.status = AsyncStatusEnum.loading;})
      .addCase(getDocumentPreviewThunk.rejected, (state, action) => {
        state.documentTemplatePreviewResponseData.status = AsyncStatusEnum.rejected;
        state.documentTemplatePreviewResponseData.error = {
          error: true,
          message: "Error rejected",
        };
        state.documentTemplatePreviewResponseData.documentTemplatePreviewResponse = commonInitialState.documentTemplatePreviewResponseData.documentTemplatePreviewResponse;
      })
      .addCase(
        getDocumentPreviewThunk.fulfilled,
        (state, action: PayloadAction<CurrentDocumentPreviewResponseModel>) => {
          state.documentTemplatePreviewResponseData.status = AsyncStatusEnum.completed;
          state.documentTemplatePreviewResponseData.error = {
            error: false,
            message: "",
          };
          state.documentTemplatePreviewResponseData.documentTemplatePreviewResponse = action.payload;
        }
      );


            ;
      
  },
});

export const { updateCurrentDocumentPreview, updateCurrentDocumentPreviewFieldValue} = commonReducer.actions;
export default commonReducer.reducer;
