import React from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { updateShowAddTitleModal } from "../../redux-state/reducers/document-build-reducer";
import AddIcon from '@mui/icons-material/Add';
import "./clean-document-advice-component.scss";

const CleanDocumentAdviceComponent = () => {

    const dispatch = useAppDispatch();

    const handleAddTitleButtonClick = () => {
        dispatch(updateShowAddTitleModal(true));
    }

   return (
    <div className="clean-document-advice">
        <p>No hay títulos creados aún, haz click en el botón para agregar uno.</p>
        <button  className="clean-document-advice__add-button" onClick={handleAddTitleButtonClick}><AddIcon sx={{color: "white", fontSize: "40px"}}/></button>
    </div>

    
   ) 
}

export default CleanDocumentAdviceComponent;