import { Dialog, DialogTitle } from "@mui/material";
import React, { FC } from "react";
import "./edit-template-modal-component.scss";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useNavigate } from "react-router-dom";

interface EditTemplateModalProps {
    setShowEDitTemplateModal: any;
}

const EditTemplateModalComponent: FC<EditTemplateModalProps> = ({setShowEDitTemplateModal}) => {

    /* Variables de colores */
    const blue = "#004571";
    const purple = "#82389A";

    const navigate = useNavigate();

    const handleCancelButtonClick = () => {
        setShowEDitTemplateModal(false);
    }

    const handleContinueButtonClick = () => {
        navigate("/edit-document");
    }

    return (
     <Dialog open={true} maxWidth={"sm"} fullWidth={true}>
         <DialogTitle sx={{background: blue, color: "white"}}>Atención!</DialogTitle>

         <div className="edit-template-warning-modal">

            <div className="edit-template-warning-modal__message">
                <WarningAmberIcon sx={{fontSize: "70px", color: blue}}/>
                <p>Editar el formato de la plantilla actual causará que todos los campos ya diligenciados se reinicien. <br /> ¿Está seguro que desea continuar hacia la edición de plantilla?</p>
            </div>

            <div className="edit-template-warning-modal__button-bar">
                <button className="edit-template-warning-modal__back-button" onClick={handleCancelButtonClick}>Cancelar</button>
                <button className="edit-template-warning-modal__forward-button" onClick={handleContinueButtonClick}>Continuar</button>
            </div>

         </div>
     </Dialog>
    )
}

export default EditTemplateModalComponent;